import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, Divider, Stack, useTheme } from '@mui/material';
import GoCardLess from '../common/GoCardLess';
import CustomDialog from '../common/Dialog';
import { H3 } from '../common/Typography';
import SvgIconWrapper from '../common/SvgIcon';
import { setupUserPayment } from '../../redux/actions/appActions';

interface PaymentSetupDialogProps {
  open: boolean
  onClose: () => void
}

const PaymentSetupDialog: React.FC<PaymentSetupDialogProps> = (props): JSX.Element => {
  const {
    open = false,
    onClose = () => { }
  } = props;

  const dispatch = useDispatch();
  const { palette: { primary, text, outline } } = useTheme();
  const [accountPresent, setAccountPresent] = useState(true);

  const boxStyle = {
    py: 4,
    px: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '15px',
    borderWidth: 1,
    gap: 2,
    borderStyle: 'solid',
    borderColor: outline.main,
    cursor: 'pointer',
    '&:hover': {
      borderColor: 'primary.main'
    }
  };

  const handlePaymentSetup = (): void => {
    dispatch(setupUserPayment(accountPresent ? 'existing' : 'new'));
  };

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      showDividers
      fullWidth
      maxWidth='sm'
      content={(
        <Stack direction="column" spacing={3}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <H3 color={text.secondary}>Payment Setup</H3>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              gap: 3,
              justifyContent: 'space-evenly',
              alignItems: 'center'
            }}
          >
            <Box
              sx={{
                ...boxStyle,
                borderColor: accountPresent ? primary.main : outline.main
              }}
              onClick={() => { setAccountPresent(true); }}
            >
              <Box display="flex" alignItems="center">
                <SvgIconWrapper color='primary' name='Payment' />
                <Divider sx={{ width: 30 }} />
                <SvgIconWrapper color='primary' name='Payment' />
              </Box>
              <Box textAlign='center'>
                <H3>
                  Integrate with Existing&nbsp;
                  <GoCardLess fill="black" sx={{ width: 100 }} />
                </H3>
              </Box>
            </Box>
            <Box
              sx={{
                ...boxStyle,
                borderColor: !accountPresent ? primary.main : outline.main
              }}
              onClick={() => { setAccountPresent(false); }}
            >
              <Box>
                <SvgIconWrapper color='primary' name='Add' />
              </Box>
              <Box textAlign='center'>
                <H3>
                  Create New Integration&nbsp;
                  <GoCardLess fill="black" sx={{ width: 100 }} />
                </H3>
              </Box>
            </Box>
          </Box>
          <Box textAlign="end">
            <Button
              endIcon={<SvgIconWrapper name='RightArrow' />}
              variant="contained"
              size="large"
              onClick={handlePaymentSetup}
            >
              Go cardless Setup
            </Button>
          </Box>
        </Stack>
      )}
    />
  );
};

export default PaymentSetupDialog;
