import API_MIDDLEWARE from '../api/middleware';
import type { PackageOptionsType, PackageType } from '../../types/common';
import { CREATE_PACKAGE_URL, GET_PACKAGE_BY_ID_URL, GET_PACKAGE_LIST_URL, UPDATE_PACKAGE_URL } from '../api/config';
import { REFETCH_PACKAGES_LIST, SET_PACKAGE_LIST, SET_PACKAGE_OPTION_LIST } from './actionTypes';
import { beginAjaxCall, endAjaxCall } from './ajaxStatusActions';
import { setErrorMessage, setLoadingState, setSuccessDialogInfo } from './appActions';
import { APP_CUSTOM_ERROR } from '../../constants/MessageConstants';

export const setPackages = (payload: PackageType[]): any => {
  return { type: SET_PACKAGE_LIST, payload };
};

export const setPackageOptions = (payload: PackageOptionsType[]): any => {
  return { type: SET_PACKAGE_OPTION_LIST, payload };
};

export const refetchPackageList = (): any => {
  return { type: REFETCH_PACKAGES_LIST };
};

export const fetchAllPackages = (
  onSuccess = () => { },
  onError = () => { }
): any => {
  return (dispatch: any) => {
    dispatch(beginAjaxCall());
    dispatch(setLoadingState(true));
    API_MIDDLEWARE(
      GET_PACKAGE_LIST_URL,
      'GET',
      { 'Content-Type': 'application/json' }
    )
      .then((res) => {
        dispatch(setPackages(res?.results));
        dispatch(endAjaxCall());
        dispatch(setLoadingState(false));
        onSuccess();
      })
      .catch((err) => {
        onError();
        dispatch(setLoadingState(false));
        dispatch(setErrorMessage(err.errorMsg ?? APP_CUSTOM_ERROR));
        dispatch(endAjaxCall());
      });
  };
};

export const craetePackage = (
  payload: any,
  onSuccess = () => { },
  onError = () => { }
): any => {
  return (dispatch: any) => {
    dispatch(beginAjaxCall());
    API_MIDDLEWARE(
      CREATE_PACKAGE_URL,
      'POST',
      { 'Content-Type': 'application/json' },
      payload
    )
      .then((res) => {
        dispatch(refetchPackageList());
        dispatch(setSuccessDialogInfo({
          title: 'Successfully Created',
          description: 'Package is successfully created.'
        }));
        dispatch(endAjaxCall());
        onSuccess();
      })
      .catch((err) => {
        onError();
        dispatch(setErrorMessage(err.errorMsg ?? APP_CUSTOM_ERROR));
        dispatch(endAjaxCall());
      });
  };
};

export const updatePackage = (
  payload: any,
  onSuccess = () => { },
  onError = () => { }
): any => {
  return (dispatch: any) => {
    dispatch(beginAjaxCall());
    API_MIDDLEWARE(
      `${UPDATE_PACKAGE_URL}/${payload.id as string}`,
      'POST',
      { 'Content-Type': 'application/json' },
      payload
    )
      .then((res) => {
        dispatch(refetchPackageList());
        dispatch(setSuccessDialogInfo({
          title: 'Successfully Updated',
          description: 'Changes are successfully updated.'
        }));
        dispatch(endAjaxCall());
        onSuccess();
      })
      .catch((err) => {
        onError();
        dispatch(setErrorMessage(err.errorMsg ?? APP_CUSTOM_ERROR));
        dispatch(endAjaxCall());
      });
  };
};

export const getPackageById = (
  id: string,
  onSuccess = (result: PackageType) => { },
  onError = () => { }
): any => {
  return (dispatch: any) => {
    dispatch(beginAjaxCall());
    API_MIDDLEWARE(
      `${GET_PACKAGE_BY_ID_URL}/${id}`,
      'GET',
      { 'Content-Type': 'application/json' }
    )
      .then((res) => {
        dispatch(endAjaxCall());
        onSuccess(res?.result);
      })
      .catch((err) => {
        onError();
        dispatch(setErrorMessage(err.errorMsg ?? APP_CUSTOM_ERROR));
        dispatch(endAjaxCall());
      });
  };
};
