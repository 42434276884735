import { ROUTE_CALENDAR, ROUTE_CLIENT_LIST, ROUTE_DASHBOARD, ROUTE_PACKAGES, ROUTE_PAYMENT_HISTORY } from '../../constants/routes';

export const MENU_OPTIONS: any[] = [
  {
    icon: 'Calendar',
    size: 16,
    name: 'My Calendar',
    path: ROUTE_CALENDAR
  },
  {
    icon: 'Dashboard',
    size: 16,
    name: 'Dashboard',
    path: ROUTE_DASHBOARD
  },
  {
    icon: 'User',
    size: 16,
    name: 'Client List',
    path: ROUTE_CLIENT_LIST
  },
  {
    icon: 'Package',
    size: 18,
    name: 'Packages',
    path: ROUTE_PACKAGES
  },
  {
    icon: 'PaymentHistory',
    size: 20,
    name: 'Payment History',
    path: ROUTE_PAYMENT_HISTORY
  }
];
