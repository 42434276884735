import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Button, useMediaQuery } from '@mui/material';
import CustomDialog from '../common/Dialog';
import Form from '../common/Form';
import FormInputTextField from '../common/FormInputTextField';
import SelectField from '../common/SelectField';
import { SESSION_TYPE_OPTIONS, mobileWidth } from '../../constants/AppVarConstants';
import SvgIconWrapper from '../common/SvgIcon';
import { useDispatch } from 'react-redux';
import { setSuccessMessage } from '../../redux/actions/appActions';
import { RequestSessionSchema } from '../../validations/validationSchema';
import FormInputPhoneNumber from '../common/FormInputPhoneNumber';
import DateTimeField from '../common/DateTimeField';
import { requestSession } from '../../redux/actions/calendarAction';
import { useParams } from 'react-router-dom';
import { getCurrentTimeZome } from '../../commonFunctions/utils';

interface RequestSessionProps {
  open: boolean
  onClose: () => void
  fullWidth?: boolean
};

const RequestSession: React.FC<RequestSessionProps> = (props) => {
  const {
    open = false,
    onClose = () => { },
    fullWidth = false
  } = props;

  const params = useParams();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(`(${mobileWidth})`);

  const appendValues = {
    phoneNumber: '',
    isoCode: 'GB',
    dialCode: '+44'
  };

  const {
    control,
    setValue,
    reset,
    clearErrors,
    handleSubmit
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: [appendValues],
      dateTime: null,
      sessionType: '',
      note: ''
    },
    resolver: yupResolver(RequestSessionSchema),
    mode: 'onSubmit'
  });

  const handleClose = (): void => {
    onClose();
    reset();
    clearErrors();
  };

  const onSubmit = (data: any): void => {
    const payload = {
      firstName: data?.firstName,
      lastName: data?.lastName,
      email: data?.email,
      phone: data?.phone?.[0]?.phoneNumber ? `${data?.phone?.[0]?.dialCode as string}${data?.phone?.[0]?.phoneNumber as string}` : '',
      timezone: getCurrentTimeZome(),
      startDateTime: new Date(data?.dateTime?.$d).toISOString(),
      sessionType: data?.sessionType,
      ...(data?.note && {
        notes: data?.note
      })
    };
    dispatch(requestSession(params?.userId ?? '', payload, (res) => {
      handleClose();
      dispatch(setSuccessMessage(res?.message));
    }));
  };

  return (
    <CustomDialog
      open={open}
      onClose={handleClose}
      fullWidth={fullWidth}
      isMobileScreen={isMobile}
      title="Request a Session"
      description="If you do not receive a response from your trainer within 10 hours of your session, your trainer is unable to make this session."
      content={(
        <Form
          mainSX={{ columnSpacing: 3 }}
          fields={[
            {
              label: 'First Name',
              labelSX: { variant: 'h5', lineHeight: 1.8 },
              gridSX: { xs: 12, md: 6 },
              component: <FormInputTextField width="100%" name="firstName" control={control} placeholder='First Name' capitalizeFirstLetter />
            },
            {
              label: 'Last Name',
              labelSX: { variant: 'h5', lineHeight: 1.8 },
              gridSX: { xs: 12, md: 6 },
              component: <FormInputTextField width="100%" name="lastName" control={control} placeholder='Last Name' capitalizeFirstLetter />
            },
            {
              label: 'Email',
              labelSX: { variant: 'h5', lineHeight: 1.8 },
              gridSX: { xs: 12, md: 6 },
              component: <FormInputTextField width="100%" name="email" control={control} placeholder='Email' endAdornment={<SvgIconWrapper name='Mail' size={16} />} />
            },
            {
              label: 'Phone Number',
              labelSX: { variant: 'h5', lineHeight: 1.8 },
              gridSX: { xs: 12, md: 6 },
              component: <FormInputPhoneNumber
                width="100%"
                name="phone"
                control={control}
                setValue={setValue}
                countries={['gb']}
                maxLen={10}
                endAdornment={<SvgIconWrapper name='Phone' size={18} />}
              />
            },
            {
              label: 'Select Date & Time',
              labelSX: { variant: 'h5', lineHeight: 1.8 },
              gridSX: { xs: 12, md: 12 },
              component: <DateTimeField width="100%" name='dateTime' control={control} />
            },
            {
              label: 'Session Type',
              labelSX: { variant: 'h5', lineHeight: 1.8 },
              gridSX: { xs: 12, md: 12 },
              component: <SelectField width="100%" name="sessionType" control={control} options={SESSION_TYPE_OPTIONS} placeholder='Session Type' />
            },
            {
              label: 'Notes',
              sideLabel: '(Optional)',
              labelSX: { variant: 'h5', lineHeight: 1.8 },
              gridSX: { xs: 12, md: 12 },
              component: <FormInputTextField
                width="100%"
                name="note"
                control={control}
                placeholder='Write your notes  here...'
                textFieldSx={{
                  multiline: true,
                  rows: 4
                }}
              />
            }
          ]}
        />
      )}
      leftButton={(
        <Button
          startIcon={(
            <SvgIconWrapper
              name="Cancel"
              size={16}
            />
          )}
          size="large"
          variant="outlined"
          color="error"
          onClick={handleClose}
        >
          Cancel
        </Button>
      )}
      rightButton={(
        <Button
          startIcon={(
            <SvgIconWrapper name="Send" size={16} />
          )}
          variant="contained"
          size="large"
          onClick={() => {
            void handleSubmit(onSubmit)();
          }}
        >
          Send Request
        </Button>
      )}
    />
  );
};

export default RequestSession;
