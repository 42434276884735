// /* eslint-disable*/
import React, { useEffect, useState } from 'react';
import {
  FormControl,
  TextField,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  CircularProgress,
  ClickAwayListener,
  type TextFieldProps,
  Box
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import InfiniteScroll from 'react-infinite-scroll-component';
import { setErrorMessage } from '../../redux/actions/appActions';
import { useDispatch } from 'react-redux';
import { GET_ALL_CURRENCY_URL } from '../../redux/api/config';
import API_MIDDLEWARE from '../../redux/api/middleware';
import { useDebounce } from '../../hooks/useDebounce';

interface CurrencyFieldProps {
  field?: any
  textFieldSx?: TextFieldProps
  width?: string | number
  defaultValue?: string
  placeHolder?: string
  error?: boolean
  helperText?: string
  disabled?: boolean
  clearValue?: boolean
  getCurrency?: ((item: any) => void) | undefined
  clearErrors?: (() => void) | undefined
}

interface CurrencyType {
  name: string
  code: string
  symbol: string
  rate?: string | null
  rateAsOf?: string
  status?: string
}

const CurrencyField: React.FC<CurrencyFieldProps> = (props): JSX.Element => {
  const {
    field,
    textFieldSx,
    width,
    defaultValue = '',
    error = false,
    placeHolder,
    helperText,
    disabled,
    clearValue,
    getCurrency,
    clearErrors
  } = props;

  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  // const [focus, setFocus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(false);
  const [value, setValue] = useState(defaultValue);

  const [results, setResults] = useState<any>([]);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [hasMore, setHasMore] = useState(false);

  const debouncedValue = useDebounce(value, 500);

  const fetchCurrencyList = (flag: boolean): void => {
    if (!flag) {
      setLoading(true);
    }
    API_MIDDLEWARE(
      `${GET_ALL_CURRENCY_URL}?offset=${flag ? currentOffset + 8 : 0}&limit=8${debouncedValue ? `&query=${debouncedValue as string}` : ''}`,
      'GET',
      {
        'Content-Type': 'application/json'
      }
    )
      .then((res) => {
        const { hits, isHasMore, offset } = res.result;
        const data = [...(flag ? results : []), ...hits];
        setResults(data);
        setHasMore(isHasMore);
        setCurrentOffset(offset);
        setLoading(false);
      })
      .catch((err) => {
        dispatch(setErrorMessage(err?.errorMsg));
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchCurrencyList(false);
  }, [debouncedValue]);

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    } else {
      setValue('');
    }
  }, [clearValue]);

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setOpen(true);
    setSelected(false);
    setValue(e.target.value);
    getCurrency?.('');
  };

  const handleSelect = (currency: CurrencyType): void => {
    setOpen(false);
    setSelected(true);
    setValue(`${currency.code} ${currency.name}`);
    getCurrency?.(currency.name);
  };

  const handleClickAway = (): void => {
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <FormControl sx={{ width: { width } }}>
        <TextField
          {...field}
          {...textFieldSx}
          variant="outlined"
          onClick={() => { setOpen(true); }}
          fullWidth
          onChange={(e) => { handleAddressChange(e); }}
          onBlur={clearErrors}
          value={value}
          autoComplete="off"
          disabled={disabled}
          error={error}
          placeholder={placeHolder}
          helperText={helperText}
          sx={{
            '.MuiOutlinedInput-root': {
              backgroundColor: 'common.white',
              color: 'text.secondary'
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon fontSize='medium' />
              </InputAdornment>
            )
          }}
        />
        {open
          ? (
            <List
              id="scrollableDiv"
              sx={{
                width: '100%',
                position: 'absolute',
                marginTop: '4rem',
                background: 'white',
                borderRadius: '5px',
                zIndex: '1000',
                border: '1px solid',
                borderColor: (theme) => theme.palette.outline.main,
                ...(
                  results?.length &&
                  open && {
                    maxHeight: '200px'
                  }
                ),
                overflowY: 'scroll',
                '::-webkit-scrollbar': {
                  width: '5px'
                },
                '::-webkit-scrollbar-track': {
                  borderRadius: '5px'
                },
                '::-webkit-scrollbar-thumb': {
                  background: '#bebfcc',
                  borderRadius: '5px'
                }
              }}
              aria-label="currencyList"
            >
              {loading && !selected
                ? (<ListItem disablePadding divider>
                  <ListItemButton sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress color='primary' />
                  </ListItemButton>
                </ListItem>)
                : results.length
                  ? (<InfiniteScroll
                    style={{ overflow: 'hidden' }}
                    dataLength={results.length}
                    hasMore={hasMore}
                    next={() => { fetchCurrencyList(true); }}
                    loader={(<Box textAlign="center">
                      <CircularProgress color='primary' />
                    </Box>)}
                    endMessage={<p style={{ textAlign: 'center' }}>No More results.</p>}
                    scrollableTarget="scrollableDiv"
                  >
                    <Box>
                      {
                        results.map((item: CurrencyType) => {
                          return (
                            <ListItem
                              key={item.name}
                              disablePadding
                              onClick={() => {
                                handleSelect(item);
                              }}
                              divider
                            >
                              <ListItemButton>
                                <ListItemText
                                  primary={`${item?.code} ${item?.name}`}
                                />
                              </ListItemButton>
                            </ListItem>
                          );
                        })
                      }
                    </Box>
                  </InfiniteScroll>)
                  : (<ListItem disablePadding>
                    <ListItemButton disabled sx={{ display: 'flex', justifyContent: 'center' }}>
                      No Results.
                    </ListItemButton>
                  </ListItem>)
              }
            </List>)
          : ' '}
      </FormControl>
    </ClickAwayListener>
  );
};

export default CurrencyField;
