import React, { useEffect, useState } from 'react';
import { Avatar, Box, Button, ButtonGroup, Grid, IconButton, MenuItem, Select, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import DashboardFilters from './dashboard/DashboardFilters';
import DashboardCard from './dashboard/DashboardCard';
import CustomChart from './charts/CustomChart';
import BorderBox from './common/BorderBox';
import { BODY1, H1, H2, H3, H4, H6 } from './common/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { calculateIncome, fetchDashboardMetricsData, filterDataByMonth, getComplimentarySessionData } from '../redux/actions/dashboardActions';
import type { RootState } from '../types/reduxState';
import dayjs from 'dayjs';
import { mobileWidth } from '../constants/AppVarConstants';
import { fetchAllEvents } from '../redux/actions/calendarAction';
import moment from 'moment';
import { Info, InfoOutlined } from '@mui/icons-material';
import CustomDialog from './common/Dialog';
import SvgIconWrapper from './common/SvgIcon';
import { getDatesAtWeeklyInterval } from '../commonFunctions/utils';

const commonStyle: any = {
  display: 'flex',
  minHeight: 340,
  flexDirection: 'column',
  flex: '1 1 0',
  border: (theme: any) => `1px solid ${theme.palette.outline.main as string}`,
  borderRadius: '6px'
};

const commonCardStyle: any = {
  width: '100%',
  height: '100%',
  p: 2.5,
  display: 'flex',
  borderRadius: '6px',
  border: (theme: any) => `1px solid ${theme.palette.outline.main as string}`
};

const MetricInfo = [
  {
    title: 'Annual Revenue',
    desc: 'The value of sessions booked for each year.  For the current year, this value is projected based on the past and future booked sessions in the week.'
  },
  {
    title: 'Weekly Revenue',
    desc: 'The value of sessions booked for week.  For the current week, this value is projected based on the booked sessions.'
  }
];

const Home = (): JSX.Element => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(`(${mobileWidth})`);
  const { cardDetails, sessionsData, complimentarySessions, stats } = useSelector((state: RootState) => state.dashboardState);
  const { allSessionsData } = useSelector((state: RootState) => state.calendarState);
  const { palette: { text, outline } } = useTheme();

  const [client, setClient] = useState('ALL');
  const [sessionData, setSessionData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [chartLabel, setChartLabel] = useState('');
  const [annualIncome, setAnnualIncome] = useState(0);
  const [weeklyIncome, setWeeklyIncome] = useState(0);
  const [selectedAnnualYear, setSelectedAnnualYear] = useState('A');
  const [selectedWeeks, setSelectedWeeks] = useState('');
  const [weeksOptions, setWeeksOptions] = useState<any>(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (selectedMonth) {
      dispatch(filterDataByMonth(allSessionsData, selectedMonth));
      const label = dayjs(selectedMonth).format('MMM YYYY');
      setChartLabel(label);
    }
  }, [selectedMonth, allSessionsData]);

  const handleAnnualIncome = (currentDate: string, endDate: string): void => {
    const annualIncome = calculateIncome(sessionData, currentDate, endDate);
    setAnnualIncome(annualIncome);
  };

  const formWeeksOptions = (): void => {
    const options = [];
    const dayOfWeek = moment().day();
    const daysToSubtract = (dayOfWeek + 7 - 1) % 7;
    const endDate = moment().subtract(daysToSubtract, 'days').format('YYYY-MM-DD');
    const stDate = moment(endDate).subtract(4, 'week').format('YYYY-MM-DD');
    const dates = getDatesAtWeeklyInterval(stDate, endDate);
    dates.reverse();
    for (let i = 1; i < dates.length; i++) {
      const wkStart = moment(dates[i]).format('DD/MM');
      const wkEnd = moment(dates[i - 1]).subtract(1, 'day').format('DD/MM');
      options.push({
        key: `${wkStart} - ${wkEnd}`,
        value: `${moment(dates[i]).format('YYYY-MM-DD')}/${moment(dates[i - 1]).format('YYYY-MM-DD')}`
      });
      if (i === 1) {
        setSelectedWeeks(`${moment(dates[i]).format('YYYY-MM-DD')}/${moment(dates[i - 1]).format('YYYY-MM-DD')}`);
      }
    }
    setWeeksOptions(options);
  };

  useEffect(() => {
    const currentDate = `${moment().get('year')}-01-01`;
    const endDate = moment().format('YYYY-MM-DD');
    handleAnnualIncome(currentDate, endDate);
  }, [sessionData]);

  useEffect(() => {
    if (selectedWeeks) {
      const currentDate = selectedWeeks.split('/')?.[0];
      const endDate = selectedWeeks.split('/')?.[1];
      const wkIncome = calculateIncome(sessionData, currentDate, endDate);
      setWeeklyIncome(wkIncome);
    }
  }, [selectedWeeks, sessionData]);

  useEffect(() => {
    dispatch(fetchDashboardMetricsData());
    dispatch(getComplimentarySessionData());
    formWeeksOptions();
  }, []);

  useEffect(() => {
    dispatch(fetchAllEvents({ clientId: client === 'ALL' ? '' : client, typeId: 1, id: '' }, setSessionData));
  }, [client]);

  const buttons = [
    <Button
      size='large'
      sx={{
        borderRadius: 1,
        ...(selectedAnnualYear === 'A'
          ? { bgcolor: '#eaf6ff' }
          : { color: '#B1B1B1' })
      }}
      key="one"
      onClick={() => {
        setSelectedAnnualYear('A');
        const currentDate = `${moment().get('year')}-01-01`;
        const endDate = moment().format('YYYY-MM-DD');
        handleAnnualIncome(currentDate, endDate);
      }}
    >
      {moment().get('year')}
    </Button>,
    <Button
      size='large'
      sx={{
        borderRadius: 1,
        ...(selectedAnnualYear === 'B'
          ? { bgcolor: '#eaf6ff' }
          : { color: '#B1B1B1' })
      }}
      key="two"
      onClick={() => {
        const currentDate = `${moment().subtract(1, 'year').get('year')}-01-01`;
        const endDate = `${moment().subtract(1, 'year').get('year')}-12-31`;
        setSelectedAnnualYear('B');
        handleAnnualIncome(currentDate, endDate);
      }}
    >
      {moment().subtract(1, 'year').get('year')}
    </Button>
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: { xs: 0, md: 5 }
      }}
    >
      <BorderBox>
        <DashboardFilters
          client={client}
          setClient={setClient}
          selectedMonth={selectedMonth}
          setSelectedMonth={setSelectedMonth}
        />
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={6} md={3}>
              <DashboardCard label='Booked Sessions' value={cardDetails.sessions} selected />
            </Grid>
            <Grid item xs={6} md={3}>
              <DashboardCard label='Total Income' value={`£${cardDetails.totalIncome as string}`} />
            </Grid>
            <Grid item xs={6} md={3}>
              <DashboardCard label='Avg Hourly Rate' value={`£${cardDetails.hourlyRate as string}`} />
            </Grid>
            <Grid item xs={6} md={3}>
              <DashboardCard label='Avg Daily Earnings' value={`£${cardDetails.dailyEaring as string}`} />
            </Grid>
          </Grid>
        </Box>
        <Box sx={commonStyle}>
          <CustomChart
            chartType='ColumnChart'
            title='Booked Session'
            data={[[chartLabel, 'Sessions'], ...sessionsData]}
            allowHorzScroll={isMobile}
            options={{
              colors: ['#3098E9'],
              tooltip: { trigger: 'selection' },
              legend: {
                position: 'none'
              },
              vAxis: {
                title: 'No. of Bookings',
                titleTextStyle: {
                  bold: true,
                  italic: false,
                  fontSize: '14px'
                }
              },
              hAxis: {
                title: chartLabel,
                titleTextStyle: {
                  bold: true,
                  italic: false,
                  fontSize: '14px'
                }
              }
            }}
          />
        </Box>
      </BorderBox>
      <BorderBox>
        <Grid position="relative" container spacing={3} py={4}>
          <Box position="absolute" top={15} right={-5}>
            <IconButton onClick={() => { setOpen(true); }}>
              <Info color='primary'/>
            </IconButton>
          </Box>
          <Grid item xs={12} md={6}>
            <Box sx={{ ...commonCardStyle, gap: 2, justifyContent: 'space-between', flexWrap: 'wrap' }}>
              <Box display="flex" flexDirection="column" gap={2}>
                <H6 color={text.secondary}>Annual Revenue</H6>
                <Typography
                  sx={{
                    typography: { xs: 'h4', sm: 'h2' },
                    WebkitLineClamp: '2',
                    fontWeight: '700 !important',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical'
                  }}
                >
                  £{annualIncome.toFixed(2)}
                </Typography>
              </Box>
              <Box>
                <ButtonGroup sx={{ width: 100 }} orientation='vertical'>
                  {buttons}
                </ButtonGroup>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ ...commonCardStyle, justifyContent: 'space-between', flexWrap: 'wrap' }}>
              <Box display="flex" flexDirection="column" gap={2}>
                <H6 color={text.secondary}>Weekly Revenue</H6>
                <Typography
                  sx={{
                    typography: { xs: 'h4', sm: 'h2' },
                    WebkitLineClamp: '2',
                    fontWeight: '700 !important',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical'
                  }}
                >
                  £{weeklyIncome.toFixed(2)}
                </Typography>
              </Box>
              <Box>
                <Select
                  size="small"
                  value={selectedWeeks}
                  onChange={(e) => { setSelectedWeeks(e.target.value); }}
                  inputProps={{ 'aria-label': 'Select Client' }}
                  sx={{ width: 150 }}
                >
                  {weeksOptions?.map((item: { key: string, value: string }) => (
                    <MenuItem
                      key={item.key}
                      value={item.value}
                    >
                      {item.key}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={commonStyle}>
              <CustomChart
                title='Complimentary Sessions Booked'
                chartType='ColumnChart'
                data={[['', 'Sessions'], ...complimentarySessions]}
                options={{
                  colors: ['#FFA801'],
                  tooltip: { trigger: 'selection' },
                  legend: {
                    position: 'none'
                  },
                  vAxis: {
                    title: 'Bookings',
                    titleTextStyle: {
                      bold: true,
                      italic: false,
                      fontSize: '14px'
                    }
                  }
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={commonStyle}>
              <CustomChart
                title='Client Demographics - Gender'
                chartType='PieChart'
                data={[
                  ['Task', 'Total Count'],
                  ['Male', stats.genders.male],
                  ['Female', stats.genders.female]
                ]}
                options={{
                  colors: ['#FCE8E6', '#F96F77'],
                  tooltip: { trigger: 'selection' },
                  chartArea: { width: '95%', height: '95%' }
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                p: 2,
                ...commonStyle
              }}
            >
              <H2>Average Client Age</H2>
              <Box sx={{
                display: 'flex',
                flex: 1,
                alignItems: 'center',
                flexDirection: 'column',
                alignContent: 'center',
                justifyContent: 'center'
              }}>
                <Box width="100%" display="flex" justifyContent="center" alignItems="end">
                  <H1 fontSize={100} lineHeight={1}>{stats.averageAge}</H1>
                  <H3 color={text.secondary} fontWeight={400}>&nbsp;Years Old</H3>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </BorderBox>
      <CustomDialog
        open={open}
        onClose={() => { setOpen(false); }}
        showDividers
        fullWidth
        maxWidth='sm'
        content={(
          <Stack direction="column" spacing={3}>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <Avatar sx={{ bgcolor: '#eaf6ff' }}>
                <InfoOutlined color='primary'/>
              </Avatar>
              <H2>Metric Information </H2>
            </Box>
            <Box display="flex" flexDirection="column" gap={2}>
              {MetricInfo.map((item, id) => (
                <Box key={id} sx={{ p: 2, border: `1px solid ${outline.main}`, borderRadius: '6px' }}>
                  <H4 sx={{ pb: 1, fontWeight: 700, lineHeight: 'unset !important' }}>{item.title}</H4>
                  <BODY1 color={text.secondary} lineHeight={1.5}>{item.desc}</BODY1>
                </Box>
              ))}
            </Box>
            <Box textAlign="end">
              <Button
                startIcon={<SvgIconWrapper name='Check' size={16} />}
                variant="contained"
                size="large"
                onClick={() => { setOpen(false); }}
              >
                Ok
              </Button>
            </Box>
          </Stack>
        )}
      />
    </Box>
  );
};

export default Home;
