import React from 'react';
import { Box, useTheme } from '@mui/material';
import { BODY2 } from './Typography';
// import SvgIconWrapper from './SvgIcon';

interface TextLabelProps {
  label: string
  success?: boolean
  inactive?: boolean
};

const TextLabel: React.FC<TextLabelProps> = (props): JSX.Element => {
  const {
    label = '',
    success = false,
    inactive = false
  } = props;

  const { palette: { outline, text, teal, deepOrange } } = useTheme();

  const color = success ? teal.main : inactive ? deepOrange.main : text.secondary;
  const bgColor = success ? teal.light : inactive ? deepOrange.light : outline.main;

  return (
    <Box
      sx={{
        p: 1,
        pr: 2,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        borderRadius: '3px',
        color,
        background: bgColor
      }}
    >
      <Box
        sx={{
          content: "''",
          position: 'absolute',
          borderTop: '15px solid transparent',
          borderRight: `12px solid ${bgColor}`,
          borderBottom: '15px solid transparent',
          left: '-10px',
          borderRadius: '5px'
        }}
      />
      {/* {success && <SvgIconWrapper name='Check' size={10} color='success' sx={{ pr: 1 }} />} */}
      <BODY2 fontWeight={700}>{label}</BODY2>
    </Box>
  );
};

export default TextLabel;
