import React from 'react';
import { useSelector } from 'react-redux';
import { Backdrop, Box, CircularProgress, Typography } from '@mui/material';
import type { RootState } from '../../types/reduxState';

interface BackdropLoaderProps {
  message?: string
}

const BackdropLoader: React.FC<BackdropLoaderProps> = ({ message }): JSX.Element => {
  const { loader } = useSelector((state: RootState) => state.app);
  return (
    <Backdrop
      sx={{ color: 'success.main', zIndex: 20 }}
      open={loader}
    >
      <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
        <CircularProgress color="success" />
        <Typography variant='body1' color="inherit">{!message ? 'Loading...' : message}</Typography>
      </Box>
    </Backdrop>
  );
};

export default BackdropLoader;
