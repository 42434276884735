import React, { useEffect, useState } from 'react';
import { BottomNavigation, Paper, BottomNavigationAction, Box, Toolbar, useMediaQuery, Avatar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Header from './common/Appbar';
import SuccessDialog from './common/SuccessDialog';
import { useDispatch, useSelector } from 'react-redux';
import type { RootState } from '../types/reduxState';
import { resetSuccessDialogInfo } from '../redux/actions/appActions';
import { ADMIN, mobileWidth } from '../constants/AppVarConstants';
import { ROUTE_CALENDAR, ROUTE_CLIENT_LIST, ROUTE_DASHBOARD, ROUTE_PACKAGES, ROUTE_PROFILE } from '../constants/routes';
import SvgIconWrapper from './common/SvgIcon';
import VerifyPaymentDialog from './common/VerifyPaymentDialog';
import VerifyEmail from './common/VerifyEmail';
import VerifyGoCardlessDialog from './signup/VerifyGoCardlessDialog';
import PaymentSetupDialog from './signup/PaymentSetupDialog';
import VerifyVerificationStatusDialog from './common/VerifyVerificationStatusDialog';
interface LayoutProps {
  children: React.ReactNode
}

const Layout: React.FC<LayoutProps> = ({ children }): JSX.Element => {
  const { successDialogInfo, user: { user }, userRole } = useSelector((state: RootState) => state.app);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(`(${mobileWidth})`);

  const [value, setValue] = useState(location.pathname);
  const [paymentSetupState, setPaymentSetupState] = useState(false);

  useEffect(() => {
    setValue(location.pathname);
  }, [location]);

  const handleSucessDialog = (): void => {
    dispatch(resetSuccessDialogInfo());
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        background: (theme) => isMobile ? theme.palette.common.white : theme.palette.background.default
      }}
    >
      <Header isMobile={isMobile} />
      <Toolbar sx={{ height: document.getElementById('Appbar')?.clientHeight }} />
      <Box
        sx={{
          px: { xs: 0, sm: 2, md: 5 },
          pb: { xs: 10, md: 5 },
          pt: { xs: 1, sm: 2, md: 5 },
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'auto'
        }}
      >
        {children}
      </Box>
      {isMobile && userRole === ADMIN &&
        <Paper
          sx={{
            py: 1,
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            display: { xs: 'block', md: 'none' }
            // ...bottomNavigationSxProps?.paperSX
          }}
          elevation={5}
        >
          <BottomNavigation
            showLabels
            value={value}
            onChange={(_event, newValue) => {
              navigate(newValue);
              setValue(newValue);
            }}
          >
            <BottomNavigationAction value={ROUTE_CALENDAR} sx={{ color: 'black', fontWeight: 700, fontSize: 10 }} label="Calendar" icon={<SvgIconWrapper name='Calendar' size={16} sx={{ mb: 1 }} />} />
            <BottomNavigationAction value={ROUTE_DASHBOARD} sx={{ color: 'black', fontWeight: 700, fontSize: 10 }} label="Dashboard" icon={<SvgIconWrapper name='Dashboard' size={16} sx={{ mb: 1 }} />} />
            <BottomNavigationAction value={ROUTE_CLIENT_LIST} sx={{ color: 'black', fontWeight: 700, fontSize: 10 }} label="Clients" icon={<SvgIconWrapper name='User' size={16} sx={{ mb: 1 }} />} />
            <BottomNavigationAction value={ROUTE_PACKAGES} sx={{ color: 'black', fontWeight: 700, fontSize: 10 }} label="Packages" icon={<SvgIconWrapper name='Package' size={16} sx={{ mb: 1 }} />} />
            <BottomNavigationAction value={ROUTE_PROFILE} sx={{ color: 'black', fontWeight: 700, fontSize: 10 }} label="Profile" icon={<Avatar src={user?.imageUrl} sx={{ width: 20, height: 20, mb: 1 }} />} />
          </BottomNavigation>
        </Paper>
      }
      <SuccessDialog
        open={!!successDialogInfo.title}
        title={successDialogInfo.title}
        description={successDialogInfo.description}
        onClose={handleSucessDialog}
        onClick={handleSucessDialog}
      />
      <VerifyEmail />
      <VerifyPaymentDialog
        handlePaymentSetup={() => { setPaymentSetupState(true); }}
      />
      <PaymentSetupDialog
        open={paymentSetupState}
        onClose={() => { setPaymentSetupState(false); }}
      />
      <VerifyGoCardlessDialog />
        <VerifyVerificationStatusDialog />
    </Box>
  );
};

export default Layout;
