import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import SvgIconWrapper from '../common/SvgIcon';

const cardData = [
  {
    icon: 'TimeSavings',
    title: 'Time Cost & Savings',
    problem: 'Clunky, disjointed administrative tasks reduce efficiency.',
    solution: 'We help you to eliminate manual processes, with tools like client management and calendars that track payment statuses, increasing profitability and growth.'
  },
  {
    icon: 'Analytics',
    title: 'Real-Time Analytics',
    problem: 'A lack of awareness concerning the health of your business and projected earnings leads to lost opportunities and poor strategic planning.',
    solution: 'Our dashboard metrics help you to understand all aspects of your business by providing valuable information on revenue, cancellation trends, and client demographics.'
  }
];

interface CardProps {
  data: {
    icon: string
    title: string
    problem: string
    solution: string
  }
}
const Card = ({ data }: CardProps): JSX.Element => {
  const { icon, title, problem, solution } = data;
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      gap: { xs: 1, md: 2 },
      p: { xs: 1, md: 2 }
    }}>
      <Box>
        <SvgIconWrapper
          name={icon}
          size={50}
        />
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: { xs: 1, md: 2 }
      }}>
        <Typography color="black" sx={{ typography: { xs: 'h5', md: 'h4' }, fontFamily: 'Nunito, Sans-Serif !important' }}>
          {title}
        </Typography>
        <Typography color="text.secondary" variant="h5" fontFamily="Nunito, Sans-Serif">
          Problem:&nbsp;
          <Box component="span" sx={{ fontWeight: 400 }}>
            {problem}
          </Box>
        </Typography>
        <Typography color="text.secondary" variant="h5" fontFamily="Nunito, Sans-Serif">
          Solution:&nbsp;
          <Box component="span" sx={{ fontWeight: 400 }}>
            {solution}
          </Box>
        </Typography>
      </Box>
    </Box>
  );
};

const SectionTwoA = (): JSX.Element => {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: { xs: 'column', md: 'row' },
      width: '100%',
      flexGrow: 1,
      gap: { xs: 2, md: 4, lg: 6 },
      py: { xs: 2, md: 4 },
      scrollSnapAlign: 'start'
    }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: { xs: '100%', md: '40%' }
      }}>
        <Box component="img" src="/images/image-02a.png" sx={{ maxWidth: { xs: 300, sm: 450, md: '100%' } }} />
      </Box>
      <Box sx={{
        width: { xs: '100%', md: '60%' },
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
      }}>
        <Grid container spacing={2}>
          {cardData.map((item) => (
            <Grid item xs={12} key={item.title}>
              <Card
                data={item} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default SectionTwoA;
