import React from 'react';
import { Typography } from '@mui/material';
import type { TypographyProps } from '@mui/material/Typography';

interface Props extends TypographyProps {
  children?: React.ReactNode | string
};

export const H1: React.FC<Props> = ({ children, color, ...props }): JSX.Element => {
  return <Typography variant="h1" color={color} {...props}>{children}</Typography>;
};

export const H2: React.FC<Props> = ({ children, color, ...props }): JSX.Element => {
  return <Typography variant="h2" color={color} {...props}>{children}</Typography>;
};

export const H3: React.FC<Props> = ({ children, color, ...props }): JSX.Element => {
  return <Typography variant="h3" color={color} {...props}>{children}</Typography>;
};

export const H4: React.FC<Props> = ({ children, color, ...props }): JSX.Element => {
  return <Typography variant="h4" color={color} {...props}>{children}</Typography>;
};

export const H5: React.FC<Props> = ({ children, color, ...props }): JSX.Element => {
  return <Typography variant="h5" color={color} {...props}>{children}</Typography>;
};

export const H6: React.FC<Props> = ({ children, color, ...props }): JSX.Element => {
  return <Typography variant="h6" color={color} {...props}>{children}</Typography>;
};

export const CAPTION: React.FC<Props> = ({ children, color, ...props }): JSX.Element => {
  return <Typography variant="caption" color={color} {...props}>{children}</Typography>;
};

export const BUTTON: React.FC<Props> = ({ children, color, ...props }): JSX.Element => {
  return <Typography variant="button" color={color} {...props}>{children}</Typography>;
};

export const SUBTITLE1: React.FC<Props> = ({ children, color, ...props }): JSX.Element => {
  return <Typography variant="subtitle1" color={color} {...props}>{children}</Typography>;
};

export const SUBTITLE2: React.FC<Props> = ({ children, color, ...props }): JSX.Element => {
  return <Typography variant="subtitle2" color={color} {...props}>{children}</Typography>;
};

export const BODY1: React.FC<Props> = ({ children, color, ...props }): JSX.Element => {
  return <Typography variant="body1" color={color} {...props}>{children}</Typography>;
};

export const BODY2: React.FC<Props> = ({ children, color, ...props }): JSX.Element => {
  return <Typography variant="body2" color={color} {...props}>{children}</Typography>;
};
