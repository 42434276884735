import { API_VERSION } from '../actions/AppVarContants';

const BASE_URL = process.env.REACT_APP_BUILD_ENV === 'prod' ? 'https://api.freelancerflows.com/' : 'https://api.dev.freelanceflow.app/';

export const USER_LOGIN_URL = `${BASE_URL}auth/${API_VERSION}/login`;
export const USER_SIGNUP_URL = `${BASE_URL}auth/${API_VERSION}/register`;
export const SEND_RESET_PASSWORD_LINK_URL = `${BASE_URL}auth/password/${API_VERSION}/send`;
export const RESET_PASSWORD_URL = `${BASE_URL}auth/password/${API_VERSION}/reset`;
export const EMAIL_VERIFY_URL = `${BASE_URL}auth/email/${API_VERSION}/verify`;
export const REFRESH_TOKEN_URL = `${BASE_URL}auth/token/${API_VERSION}/refresh`;
export const RESEND_EMAIL_VERIFICATION_URL = `${BASE_URL}user/email/${API_VERSION}/send`;

export const USER_LOGOUT_URL = `${BASE_URL}user/${API_VERSION}/logout`;
export const USER_SECURITY_URL = `${BASE_URL}user/${API_VERSION}/password`;
export const USER_PROFILE_URL = `${BASE_URL}user/${API_VERSION}/profile`;
export const USER_DETAILS_URL = `${BASE_URL}user/${API_VERSION}/details`;

// Support
export const ADDRESS_AUTOCOMPLETE_URL = `${BASE_URL}address/${API_VERSION}/autocomplete`;
export const GET_ADDRESS_ID = `${BASE_URL}address/${API_VERSION}/save`;
export const GET_IMAGE_MEDIA_ID = `${BASE_URL}media/${API_VERSION}/image`;
export const REMOVE_PROFILE_PICTURE_URL = `${BASE_URL}user/${API_VERSION}/image`;
export const GET_ALL_CURRENCY_URL = `${BASE_URL}master/currency/${API_VERSION}/all`;
export const GET_TIMEZONE_LIST_URL = `${BASE_URL}master/timezone/${API_VERSION}/all`;

// Session API
export const GET_ALL_SESSION_URL = `${BASE_URL}session/${API_VERSION}/all`;
export const ADD_SESSION_URL = `${BASE_URL}session/${API_VERSION}/create`;
export const ADD_EXCEPTION_SESSION_URL = `${BASE_URL}session/exception/${API_VERSION}/create`;
export const UPDATE_EXCEPTION_SESSION_URL = `${BASE_URL}session/exception/${API_VERSION}/update`;
export const UPDATE_SESSION_URL = `${BASE_URL}session/${API_VERSION}/update`;
export const GET_SESSION_TYPE_URL = `${BASE_URL}session/${API_VERSION}/types`;

// Client APi
export const GET_ALL_CLIENTS = `${BASE_URL}client/${API_VERSION}/all`;
export const CREATE_NEW_CLIENT = `${BASE_URL}client/${API_VERSION}/create`;
export const UPDATE_CLIENT = `${BASE_URL}client/${API_VERSION}/update`;
export const EXPORT_CLIENT_LIST_URL = `${BASE_URL}client/${API_VERSION}/export`;
export const REQUEST_SESSION_URL = `${BASE_URL}client/request`;

// Payment APi
export const USER_PAYMENT_SETUP_URL = `${BASE_URL}payment/setup/${API_VERSION}`;
export const USER_PAYMENT_AUTHORIZE_URL = `${BASE_URL}payment/setup/${API_VERSION}/authorize`;
export const USER_PAYMENT_INFO_URL = `${BASE_URL}payment/account/${API_VERSION}/info`;
export const USER_PAYMENT_ACCOUNT_VERIFICATION_URL = `${BASE_URL}payment/account/${API_VERSION}/verify`;
export const SEND_PAYMENT_MANDATE_URL = `${BASE_URL}payment/mandate/${API_VERSION}/send`;
export const PAYMENT_HISTORY_URL = `${BASE_URL}payment/${API_VERSION}/history`;
export const PAYMENT_EXPORT_URL = `${BASE_URL}payment/${API_VERSION}/export`;
export const CHARGE_ONEOF_PAYMENT_URL = `${BASE_URL}payment/${API_VERSION}/charge`;

// Package APi
export const GET_PACKAGE_LIST_URL = `${BASE_URL}package/${API_VERSION}/all`;
export const CREATE_PACKAGE_URL = `${BASE_URL}package/${API_VERSION}/save`;
export const UPDATE_PACKAGE_URL = `${BASE_URL}package/${API_VERSION}`;
export const GET_PACKAGE_BY_ID_URL = `${BASE_URL}package/${API_VERSION}`;

// Subscription APi
export const GET_SUBSCRIPTIONS_URL = `${BASE_URL}subscription/${API_VERSION}/all`;
export const CREATE_SUBSCRIPTION_URL = `${BASE_URL}subscription/${API_VERSION}/create`;
export const UPDATE_SUBSCRIPTION_URL = `${BASE_URL}subscription/${API_VERSION}/update`;
export const CANCEL_SUBSCRIPTION_URL = `${BASE_URL}subscription/${API_VERSION}/cancel`;
export const SUBSCRIPTION_EXPORT_URL = `${BASE_URL}subscription/${API_VERSION}/export`;

// Dashboard APi
export const GET_DASHBOARD_METRICS_URL = `${BASE_URL}client/${API_VERSION}/metrics`;
