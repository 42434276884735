import React, { useEffect, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { Button, FormControl, useMediaQuery } from '@mui/material';
import CustomDialog from '../common/Dialog';
import Form from '../common/Form';
import FormInputTextField from '../common/FormInputTextField';
import SelectField from '../common/SelectField';
import { ACTIVE, GENDER_OPTIONS, mobileWidth } from '../../constants/AppVarConstants';
import SvgIconWrapper from '../common/SvgIcon';
import FormDateField from '../common/FormDateField';
import type { Client } from '../../types/common';
import { useDispatch } from 'react-redux';
import FormInputPhoneNumber from '../common/FormInputPhoneNumber';
import SearchLocation from '../common/SearchLocation';
import { addNewClient } from '../../redux/actions/clientActions';
import baseCountries from '../json/country_data';
import { ClientDialogSchema } from '../../validations/validationSchema';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

interface ClientDialogProps {
  open: boolean
  clientDetails?: Client | null
  onClose: () => void
  onEdit?: (data: any) => void
  fullWidth?: boolean
};

const ClientDialog: React.FC<ClientDialogProps> = (props) => {
  const {
    open = false,
    onClose = () => { },
    onEdit = () => { },
    clientDetails = null,
    fullWidth = false
  } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(`(${mobileWidth})`);
  const params = new URL(window.location.href).searchParams;
  const redirectURL = params.get('redirect');

  const phoneArr = baseCountries.filter((item) => item.isoCode === clientDetails?.isoAlpha2Code);
  const number = clientDetails?.phone?.split(`${phoneArr?.[0]?.dialCode}`).pop();
  const appendValues = {
    phoneNumber: clientDetails ? number ?? '' : '',
    isoCode: clientDetails ? clientDetails.isoAlpha2Code : 'GB',
    dialCode: clientDetails ? phoneArr?.[0]?.dialCode : '+44'
  };

  const {
    control,
    setValue,
    reset,
    clearErrors,
    formState: { errors, isValid },
    handleSubmit
  } = useForm({
    defaultValues: {
      firstName: clientDetails?.firstName ?? '',
      lastName: '',
      email: '',
      phone: [appendValues],
      gender: '',
      address: '',
      birthDate: null,
      startDate: null,
      note: ''
    },
    resolver: yupResolver(ClientDialogSchema),
    mode: 'onSubmit'
  });

  const clientData = useMemo(() => clientDetails, [clientDetails]);

  const setClientDetails = (): void => {
    if (clientDetails) {
      setValue('firstName', clientDetails.firstName);
      setValue('lastName', clientDetails.lastName);
      setValue('email', clientDetails.email);
      setValue('gender', clientDetails.gender);
      setValue('birthDate', new Date(clientDetails.birthdate));
      setValue('startDate', new Date(clientDetails.started));
      setValue('gender', clientDetails.gender);
      setValue('address', clientDetails?.address?.formattedAddress);
      setValue('phone', [appendValues]);
      setValue('note', clientDetails?.content?.content ?? '');
    } else {
      reset();
    }
  };

  useEffect(() => {
    setClientDetails();
  }, [clientData]);

  useEffect(() => {
    if (isValid) {
      clearErrors();
    }
  }, [isValid]);

  const onSubmit = async (data: any): Promise<void> => {
    onClose();
    const payload = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phone: data?.phone?.[0]?.phoneNumber ? `${data?.phone?.[0]?.dialCode as string}${data?.phone?.[0]?.phoneNumber as string}` : '',
      isoAlpha2Code: data?.phone?.[0]?.phoneNumber ? data?.phone?.[0]?.isoCode : '',
      gender: data.gender,
      status: ACTIVE,
      birthDate: moment(data.birthDate?.$d ?? clientDetails?.birthdate).format('YYYY-MM-DD'),
      started: moment(data.startDate?.$d ?? clientDetails?.started).format('YYYY-MM-DD'),
      placeId: data.placeId,
      ...(data?.note && {
        note: {
          content: data?.note,
          contentType: 'text/plain'
        }
      })
    };
    if (clientDetails) {
      onEdit(payload);
    } else {
      dispatch(addNewClient(payload, () => {
        if (redirectURL) {
          navigate(redirectURL);
        }
      }));
      reset();
    }
  };

  const handleClose = (): void => {
    onClose();
    setClientDetails();
    clearErrors();
  };

  const getPlaceId = (locationId: string, location: any): void => {
    setValue('placeId', locationId ?? '');
    setValue('address', location?.description ?? '');
  };

  return (
    <CustomDialog
      open={open}
      onClose={handleClose}
      fullWidth={fullWidth}
      isMobileScreen={isMobile}
      title={clientDetails ? 'Edit Details' : 'Add New Client'}
      description={clientDetails ? 'Update your client details' : 'Add your clients and start creating your  sessions'}
      content={(
        <Form
          mainSX={{ columnSpacing: 3 }}
          fields={[
            {
              label: 'First Name',
              labelSX: { variant: 'h5', lineHeight: 1.8 },
              gridSX: { xs: 12, md: 6 },
              component: <FormInputTextField width="100%" name="firstName" control={control} placeholder='First Name' capitalizeFirstLetter />
            },
            {
              label: 'Last Name',
              labelSX: { variant: 'h5', lineHeight: 1.8 },
              gridSX: { xs: 12, md: 6 },
              component: <FormInputTextField width="100%" name="lastName" control={control} placeholder='Last Name' capitalizeFirstLetter />
            },
            {
              label: 'Email Id',
              labelSX: { variant: 'h5', lineHeight: 1.8 },
              gridSX: { xs: 12, md: 12 },
              component: <FormInputTextField width="100%" name="email" control={control} placeholder='Email Id' endAdornment={<SvgIconWrapper name='Mail' size={16} />} />
            },
            {
              label: 'Gender',
              labelSX: { variant: 'h5', lineHeight: 1.8 },
              gridSX: { xs: 12, md: 6 },
              component: <SelectField width="100%" name="gender" control={control} options={GENDER_OPTIONS} placeholder='Select Gender' />
            },
            {
              label: 'Phone Number',
              labelSX: { variant: 'h5', lineHeight: 1.8 },
              gridSX: { xs: 12, md: 6 },
              component: <FormInputPhoneNumber
                width="100%"
                name="phone"
                control={control}
                setValue={setValue}
                countries={['gb']}
                maxLen={10}
                endAdornment={<SvgIconWrapper name='Phone' size={18} />}
              />
            },
            {
              label: 'Birth Date',
              labelSX: { variant: 'h5', lineHeight: 1.8 },
              gridSX: { xs: 12, md: 6 },
              component: <FormDateField width="100%" name="birthDate" control={control} maxDate={dayjs().subtract(16, 'year')} />
            },
            {
              label: 'Start Date',
              labelSX: { variant: 'h5', lineHeight: 1.8 },
              gridSX: { xs: 12, md: 6 },
              component: <FormDateField width="100%" name="startDate" control={control} />
            },
            {
              label: 'Address',
              labelSX: { variant: 'h5', lineHeight: 1.8 },
              gridSX: { xs: 12, md: 12 },
              component: (
                <FormControl fullWidth>
                  <Controller
                    name='address'
                    control={control}
                    render={({ field }) => (
                      <SearchLocation
                        width="100%"
                        field={field}
                        defaultValue={clientDetails?.address?.formattedAddress}
                        getPlaceId={getPlaceId}
                        validationError={!!errors?.address}
                        helperText={errors.address?.message ?? ' '}
                        placeHolder='Address'
                      />
                    )}
                  />
                </FormControl>
              )
            },
            {
              label: 'Notes',
              labelSX: { variant: 'h5', lineHeight: 1.8 },
              gridSX: { xs: 12, md: 12 },
              component: <FormInputTextField
                width="100%"
                name="note"
                control={control}
                placeholder='Write a Note...'
                textFieldSx={{
                  multiline: true,
                  rows: isMobile ? 4 : 2
                }}
              />
            }
          ]}
        />
      )}
      leftButton={(
        <Button
          startIcon={(
            <SvgIconWrapper name="Cancel" size={16} />
          )}
          variant="outlined"
          size="large"
          color='error'
          onClick={handleClose}
        >
          Cancel
        </Button>
      )}
      rightButton={(
        <Button
          startIcon={(
            <SvgIconWrapper
              name={clientDetails ? 'Check' : 'Add'}
              size={16}
            />
          )}
          variant="contained"
          size="large"
          onClick={() => {
            void handleSubmit(onSubmit)();
          }}
        >
          {clientDetails ? 'Update' : 'Add'}
        </Button>
      )}
    />
  );
};

export default ClientDialog;
