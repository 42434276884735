import React, { useEffect, useState } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import PackageCard from './PackageCard';
import { mobileWidth } from '../../constants/AppVarConstants';
import PackageDialog from './PackageDialog';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllPackages } from '../../redux/actions/packageActions';
import type { RootState } from '../../types/reduxState';
import { H3 } from '../common/Typography';
import type { PackageType } from '../../types/common';
import { isEmpty } from 'lodash';

const Packages = (): JSX.Element => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(`(${mobileWidth})`);
  const { packageList, reFetchPackages } = useSelector((state: RootState) => state.packageState);
  const { loader } = useSelector((state: RootState) => state.app);

  const [packageDialog, setPackageDialog] = useState(false);
  const [packageData, setPackageData] = useState<PackageType | null>(null);

  useEffect(() => {
    dispatch(fetchAllPackages());
  }, [reFetchPackages]);

  const handlePackageDialog = (): void => {
    setPackageDialog((prev) => !prev);
  };

  return (
    <>
      {
        isEmpty(packageList) && !loader
          ? <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flex: '1 1 auto',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <H3>Looks like you haven&apos;t created any packages yet.</H3>
          </Box>
          : <Box
            sx={{
              p: isMobile ? 1 : 0,
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: isMobile ? 'center' : 'start',
              gap: 4
            }}
          >
            {
              packageList.map((item) => (
                <PackageCard
                  key={item.id}
                  packageData={item}
                  handleEdit={(data) => {
                    setPackageData(data);
                    handlePackageDialog();
                  }}
                />
              ))
            }
          </Box>
      }
      <PackageDialog
        open={packageDialog}
        packageData={packageData}
        onClose={handlePackageDialog}
      />
    </>
  );
};

export default Packages;
