import React from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { mobileWidth } from '../../constants/AppVarConstants';
import { H4 } from '../common/Typography';

const Headers = (): JSX.Element => {
  const isMobile = useMediaQuery(`(${mobileWidth})`);

  return (
    <Box
      sx={{
        zIndex: 100,
        display: 'flex',
        position: 'sticky',
        top: 0,
        bgcolor: '#F5F7F9'
      }}
    >
      <Box py={3} flex={1.5} minWidth={200} bgcolor="#F5F7F9">
        <H4 pl={isMobile ? 1 : 4}>Client Name</H4>
      </Box>
      <Box py={3} flex={1.5} minWidth={160} textAlign="center" bgcolor="#F5F7F9">
        <H4>Package Name</H4>
      </Box>
      <Box py={3} flex={1} minWidth={180} textAlign="center" bgcolor="#F5F7F9">
        <H4>Subscription Status</H4>
      </Box>
      <Box py={3} flex={1} minWidth={160} textAlign="center" bgcolor="#F5F7F9">
        <H4>Interval</H4>
      </Box>
      <Box py={3} flex={1} minWidth={160} textAlign="center" bgcolor="#F5F7F9">
        <H4>Start date</H4>
      </Box>
      <Box py={3} flex={1} minWidth={160} textAlign="center" bgcolor="#F5F7F9">
        <H4>End date</H4>
      </Box>
      <Box
        sx={{
          py: 3,
          flex: 1,
          textAlign: 'center',
          background: '#F5F7F9',
          ...(isMobile && {
            position: 'sticky',
            borderLeft: ({ palette: { outline } }) => `1px solid ${outline.main}`,
            minWidth: 80,
            right: 0
          })
        }}
      >
        <H4 pr={isMobile ? 1 : 4}>Action</H4>
      </Box>
    </Box>
  );
};

export default Headers;
