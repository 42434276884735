import React from 'react';
import { Box, Stack } from '@mui/material';
import { BODY1, H3 } from './Typography';
import SvgIconWrapper from './SvgIcon';

interface MessageContentProps {
  title: string
  success?: boolean
  edit?: boolean
  warning?: boolean
  archive?: boolean
  deleteIcon?: boolean
  body?: string
}

const MessageContent: React.FC<MessageContentProps> = (props): JSX.Element => {
  const {
    success = false,
    edit = false,
    warning = false,
    deleteIcon = false,
    archive = false,
    title = '',
    body = ''
  } = props;

  return (
    <Stack direction="column" spacing={1}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2
        }}
      >
        {success && <SvgIconWrapper color='success' name='Success' size={45} />}
        {edit && <SvgIconWrapper name='EditFilled' size={45} />}
        {warning && <SvgIconWrapper name='WarningFilled' size={45} />}
        {deleteIcon && <SvgIconWrapper color='error' name='DeleteFilled' size={45} />}
        {archive && <SvgIconWrapper name='ArchiveFilled' size={45} />}
        <H3 fontWeight={700}>{title}</H3>
      </Box>
      <BODY1
        sx={{
          color: (theme) => theme.palette.text.secondary
        }}
      >
        {body}
      </BODY1>
    </Stack>
  );
};

export default MessageContent;
