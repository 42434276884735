import React from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { mobileWidth } from '../../constants/AppVarConstants';
interface BorderBoxProps {
  children: React.ReactNode
}

const BorderBox: React.FC<BorderBoxProps> = ({ children }): JSX.Element => {
  const isMobile = useMediaQuery(`(${mobileWidth})`);
  return (
    <Box
      sx={{
        py: 2,
        px: { xs: 2, md: 5 },
        display: 'flex',
        flex: '1 1 auto',
        gap: 4,
        flexDirection: 'column',
        background: (theme) => theme.palette.common.white,
        borderRadius: 6,
        border: (theme) => isMobile ? 'none' : `1px solid ${theme.palette.outline.main}`,
        overflow: 'hidden'
      }}
    >
      {children}
    </Box>
  );
};

export default BorderBox;
