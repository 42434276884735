import React from 'react';
import { Box, Button, Stack, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import type { RootState } from '../../types/reduxState';
import GoCardLess from '../common/GoCardLess';
import CustomDialog from '../common/Dialog';
import SvgIconWrapper from '../common/SvgIcon';
import { BODY1, H5 } from '../common/Typography';
import { FAILED } from '../../constants/AppVarConstants';

const VerifyGoCardlessDialog = (): JSX.Element => {
  const { palette: { error } } = useTheme();
  const { paymentInfo } = useSelector((state: RootState) => state.app);

  const handleRedirect = (): void => {
    location.replace(paymentInfo?.redirectUrl);
  };

  return (
    <CustomDialog
      open={!!paymentInfo.verification && paymentInfo.verification === FAILED}
      onClose={() => { }}
      showDividers
      fullWidth
      maxWidth='xs'
      content={(
        <Stack direction="column" spacing={2}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1
            }}
          >
            <SvgIconWrapper color='error' name='VerifyWarning' size={45} />
            <H5 color={error.main} fontWeight={400}>Verify your GoCardless account to get paid</H5>
          </Box>
          <BODY1 lineHeight={1.8}>
            You won&apos;t be able to recive payouts until you verify your&nbsp;
            <GoCardLess fill="black" sx={{ width: 100, fontSize: 14 }} />
            &nbsp;account.
          </BODY1>
          <Box>
            <Button
              variant="contained"
              size="large"
              onClick={handleRedirect}
              endIcon={<SvgIconWrapper name='RightArrow' size={16} />}
            >
              Verify Now
            </Button>
          </Box>
        </Stack>
      )}
    />
  );
};

export default VerifyGoCardlessDialog;
