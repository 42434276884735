import { APP_CUSTOM_ERROR } from '../../constants/MessageConstants';
import type { AddClientType, Client } from '../../types/common';
import { CHARGE_ONEOF_PAYMENT_URL, CREATE_NEW_CLIENT, EXPORT_CLIENT_LIST_URL, SEND_PAYMENT_MANDATE_URL, UPDATE_CLIENT } from '../api/config';
import API_MIDDLEWARE from '../api/middleware';
import { REFETCH_CLIENTS_LIST, SET_CLIENTS } from './actionTypes';
import { beginAjaxCall, endAjaxCall } from './ajaxStatusActions';
import { setErrorMessage, setSuccessDialogInfo, setSuccessMessage } from './appActions';

export const setAllClients = (payload: Client[]): any => {
  return { type: SET_CLIENTS, payload };
};

export const refetchClientsList = (): any => {
  return { type: REFETCH_CLIENTS_LIST };
};

export const addNewClient = (
  payload: AddClientType,
  onSuccess = () => { },
  onError = () => { }
): any => {
  return (dispatch: any) => {
    dispatch(beginAjaxCall());
    API_MIDDLEWARE(
      CREATE_NEW_CLIENT,
      'POST',
      { 'Content-Type': 'application/json' },
      payload
    )
      .then((res) => {
        dispatch(refetchClientsList());
        dispatch(setSuccessDialogInfo({
          title: 'Successfully Added',
          description: 'You have successfully added this client'
        }));
        dispatch(endAjaxCall());
        onSuccess();
      })
      .catch((err) => {
        onError();
        dispatch(setErrorMessage(err.errorMsg ?? APP_CUSTOM_ERROR));
        dispatch(endAjaxCall());
      });
  };
};

export const updateClient = (
  payload: AddClientType,
  onSuccess = () => { },
  onError = () => { }
): any => {
  return (dispatch: any) => {
    dispatch(beginAjaxCall());
    API_MIDDLEWARE(
      UPDATE_CLIENT,
      'PUT',
      { 'Content-Type': 'application/json' },
      payload
    )
      .then((res) => {
        dispatch(setSuccessDialogInfo({
          title: 'Successfully Updated',
          description: 'Changes are successfully updated.'
        }));
        dispatch(refetchClientsList());
        dispatch(endAjaxCall());
        onSuccess();
      })
      .catch((err) => {
        dispatch(setErrorMessage(err.errorMsg ?? APP_CUSTOM_ERROR));
        dispatch(endAjaxCall());
        onError();
      });
  };
};

export const archiveClient = (
  payload: any,
  onSuccess = () => { },
  onError = () => { }
): any => {
  return (dispatch: any) => {
    dispatch(beginAjaxCall());
    API_MIDDLEWARE(
      UPDATE_CLIENT,
      'PUT',
      { 'Content-Type': 'application/json' },
      payload
    )
      .then((res) => {
        dispatch(refetchClientsList());
        dispatch(endAjaxCall());
        onSuccess();
      })
      .catch((err) => {
        dispatch(setErrorMessage(err.errorMsg ?? APP_CUSTOM_ERROR));
        dispatch(endAjaxCall());
        onError();
      });
  };
};

export const exportClientList = (status: string): any => {
  return (dispatch: any) => {
    dispatch(beginAjaxCall());
    API_MIDDLEWARE(
      `${EXPORT_CLIENT_LIST_URL}?status=${status}`,
      'GET',
      {
        'Content-Type': 'application/json'
      }
    )
      .then((res) => {
        dispatch(setSuccessMessage(res?.status));
        window.open(res?.result, '_blank');
        dispatch(endAjaxCall());
      })
      .catch((err) => {
        dispatch(setErrorMessage(err.errorMsg ?? APP_CUSTOM_ERROR));
        dispatch(endAjaxCall());
      });
  };
};

export const sendMandateRequest = (payLoad: any): any => {
  return (dispatch: any) => {
    dispatch(beginAjaxCall());
    API_MIDDLEWARE(
      SEND_PAYMENT_MANDATE_URL,
      'POST',
      {
        'Content-Type': 'application/json'
      },
      payLoad
    )
      .then((res) => {
        dispatch(refetchClientsList());
        dispatch(setSuccessDialogInfo({
          title: 'Successfully Sent',
          description: 'You have successfully sent your request'
        }));
        dispatch(endAjaxCall());
      })
      .catch((err) => {
        dispatch(setErrorMessage(err.errorMsg ?? APP_CUSTOM_ERROR));
        dispatch(endAjaxCall());
      });
  };
};

export const chargeOneOfPayment = (payLoad: any): any => {
  return (dispatch: any) => {
    dispatch(beginAjaxCall());
    API_MIDDLEWARE(
      CHARGE_ONEOF_PAYMENT_URL,
      'POST',
      {
        'Content-Type': 'application/json'
      },
      payLoad
    )
      .then((res) => {
        dispatch(setSuccessDialogInfo({
          title: 'Successfully Charged',
          description: 'You have successfully charged payment'
        }));
        dispatch(endAjaxCall());
      })
      .catch((err) => {
        if (err?.errorMsg?.amount?.length) {
          dispatch(setErrorMessage(err?.errorMsg?.amount?.[0] ?? APP_CUSTOM_ERROR));
        } else if (err?.errorMsg?.chargeDate?.length) {
          dispatch(setErrorMessage(err?.errorMsg?.chargeDate?.[0] ?? APP_CUSTOM_ERROR));
        } else {
          dispatch(setErrorMessage(err?.errorMsg ?? APP_CUSTOM_ERROR));
        }
        dispatch(endAjaxCall());
      });
  };
};
