import { APP_CUSTOM_ERROR } from '../../constants/MessageConstants';
import { CANCEL_SUBSCRIPTION_URL, CREATE_SUBSCRIPTION_URL, SUBSCRIPTION_EXPORT_URL, UPDATE_SUBSCRIPTION_URL } from '../api/config';
import API_MIDDLEWARE from '../api/middleware';
import {
  REFETCH_SUBSCRIPTIONS,
  SET_CLIENT_OPTIONS,
  SET_SUBSCRIPTIONS_DATA
} from './actionTypes';
import { beginAjaxCall, endAjaxCall } from './ajaxStatusActions';
import { setErrorMessage, setSuccessDialogInfo, setSuccessMessage } from './appActions';

export const setSubscriptionData = (payload: any): any => {
  return { type: SET_SUBSCRIPTIONS_DATA, payload };
};

export const setClientsOptions = (payload: any): any => {
  return { type: SET_CLIENT_OPTIONS, payload };
};

export const reFetchSubscription = (): any => {
  return { type: REFETCH_SUBSCRIPTIONS };
};

export const cancelSubscription = (
  sub: string,
  onSuccess = () => { },
  onError = () => { }
): any => {
  return (dispatch: any) => {
    dispatch(beginAjaxCall());
    API_MIDDLEWARE(
      `${CANCEL_SUBSCRIPTION_URL}?sub=${sub}`,
      'POST',
      { 'Content-Type': 'application/json' }
    )
      .then((res) => {
        onSuccess();
        dispatch(reFetchSubscription());
        dispatch(endAjaxCall());
      })
      .catch((err) => {
        onError();
        dispatch(setErrorMessage(err.errorMsg ?? APP_CUSTOM_ERROR));
        dispatch(endAjaxCall());
      });
  };
};

export const createSubscription = (
  payload: any,
  onSuccess = () => { },
  onError = () => { }
): any => {
  return (dispatch: any) => {
    dispatch(beginAjaxCall());
    API_MIDDLEWARE(
      CREATE_SUBSCRIPTION_URL,
      'POST',
      { 'Content-Type': 'application/json' },
      payload
    )
      .then((res) => {
        onSuccess();
        dispatch(reFetchSubscription());
        dispatch(setSuccessDialogInfo({
          title: 'Successfully Created',
          description: 'Subscription created successfully. Your client will be charged.'
        }));
        dispatch(endAjaxCall());
      })
      .catch((err) => {
        onError();
        dispatch(setErrorMessage(err.errorMsg ?? APP_CUSTOM_ERROR));
        dispatch(endAjaxCall());
      });
  };
};

export const updateSubscription = (
  payload: any,
  onSuccess = () => { },
  onError = () => { }
): any => {
  return (dispatch: any) => {
    dispatch(beginAjaxCall());
    API_MIDDLEWARE(
      UPDATE_SUBSCRIPTION_URL,
      'POST',
      { 'Content-Type': 'application/json' },
      payload
    )
      .then((res) => {
        onSuccess();
        dispatch(reFetchSubscription());
        dispatch(endAjaxCall());
      })
      .catch((err) => {
        onError();
        dispatch(setErrorMessage(err.errorMsg ?? APP_CUSTOM_ERROR));
        dispatch(endAjaxCall());
      });
  };
};

export const exportSubscriptionList = (): any => {
  return (dispatch: any) => {
    dispatch(beginAjaxCall());
    API_MIDDLEWARE(
      SUBSCRIPTION_EXPORT_URL,
      'GET',
      {
        'Content-Type': 'application/json'
      }
    )
      .then((res) => {
        dispatch(setSuccessMessage(res?.status));
        window.open(res?.result, '_blank');
        dispatch(endAjaxCall());
      })
      .catch((err) => {
        dispatch(setErrorMessage(err.errorMsg ?? APP_CUSTOM_ERROR));
        dispatch(endAjaxCall());
      });
  };
};
