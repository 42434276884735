import React from 'react';
import Drawer from '../common/Drawer';
import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  useMediaQuery
} from '@mui/material';
import { H2, H3, SUBTITLE1 } from '../common/Typography';
import SvgIconWrapper from '../common/SvgIcon';
import type { EventType } from '../../types/common';
import { checkForPastDate, formatDate, formatTimeRange } from '../../commonFunctions/utils';
import { ADMIN, mobileWidth } from '../../constants/AppVarConstants';
import { useSelector } from 'react-redux';
import type { RootState } from '../../types/reduxState';

interface EventDetailsProps {
  open: boolean
  onClose: () => void
  eventData: EventType | null
  onCancel?: () => void
  onEdit?: () => void
  onCancelRequest?: () => void
  setConsentPopup?: React.Dispatch<React.SetStateAction<boolean>>
}

const EventDetails: React.FC<EventDetailsProps> = (props): JSX.Element => {
  const {
    open = false,
    eventData = null,
    onClose = () => { },
    onCancel = () => { },
    onCancelRequest = () => { },
    setConsentPopup = () => { },
    onEdit = () => { }
  } = props;

  const isMobile = useMediaQuery(`(${mobileWidth})`);
  const { user: { user }, userRole } = useSelector((state: RootState) => state.app);

  const handleActions = (func: () => void): void => {
    onClose();
    func();
  };

  return (
    <Drawer
      open={open}
      anchor='right'
      onOpen={() => { }}
      onClose={onClose}
    >
      {isMobile && <Box
        sx={{
          boxShadow: (theme) => theme.shadows?.[10]
        }}
      >
        <Box
          sx={{
            p: 3,
            display: 'flex',
            alignItems: 'center',
            gap: 2
          }}
        >
          <IconButton onClick={onClose}>
            <SvgIconWrapper size={16} color='primary' name='Backward' />
          </IconButton>
          <H2>Session Details</H2>
        </Box>
      </Box>}
      <Box
        sx={{
          py: 4,
          width: { xs: '100vw', md: '25vw' }
        }}
      >
        <Stack direction="column" spacing={4}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              px: 4
            }}
          >
            <H2>{formatDate(eventData?.start)}</H2>
            <H3
              sx={{
                color: (theme) => theme.palette.text.secondary,
                fontWeight: 400
              }}
            >
              {formatTimeRange(eventData?.start, eventData?.end, user?.zoneId)}
            </H3>
            <Box
              sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
            >
              <Box
                sx={{
                  width: '12px',
                  height: '12px',
                  backgroundColor: eventData?.color,
                  borderRadius: '10px'
                }}
              />
              <SUBTITLE1 color={eventData?.color} sx={{ fontWeight: 700 }}>{eventData?.paymentStatus}</SUBTITLE1>
            </Box>
          </Box>
          <Divider />
          <Box display="flex" alignItems="center" gap={2} px={4}>
            <SvgIconWrapper
              name='User'
              sx={{
                color: (theme) => theme.palette.text.secondary
              }}
            />
            <H3>{eventData?.client}</H3>
          </Box>
          <Divider />
          <Box display="flex" alignItems="center" gap={2} px={4}>
            <SvgIconWrapper
              name='Repeat'
              sx={{
                color: (theme) => theme.palette.text.secondary
              }}
            />
            <H3>{eventData?.repeatType}</H3>
          </Box>
          <Divider />
          <Box display="flex" alignItems="center" gap={2} px={4}>
            <SvgIconWrapper
              name='Tag'
              sx={{
                color: (theme) => theme.palette.text.secondary
              }}
            />
            <H3>{eventData?.sessionType}</H3>
          </Box>
          <Divider />
          <Box
            sx={{
              px: 4,
              display: 'flex',
              justifyContent: 'space-between',
              gap: 2,
              flexWrap: 'wrap'
            }}
          >
            {userRole === ADMIN
              ? (<>
                <Button
                  startIcon={(
                    <SvgIconWrapper
                      name="Cancel"
                      size={16}
                    />
                  )}
                  color="error"
                  size="large"
                  variant="outlined"
                  disabled={checkForPastDate(eventData?.start ?? new Date(), true)}
                  onClick={() => {
                    handleActions(onCancel);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  startIcon={(
                    <SvgIconWrapper
                      name='Edit'
                      size={16}
                    />
                  )}
                  size="large"
                  variant="contained"
                  disabled={checkForPastDate(eventData?.start ?? new Date(), true)}
                  onClick={() => {
                    if (eventData?.recurrence) {
                      onClose();
                      setConsentPopup(true);
                    } else {
                      handleActions(onEdit);
                    }
                  }}
                >
                  Edit
                </Button>
              </>)
              : (<Button
                startIcon={(
                  <SvgIconWrapper
                    name="CancelOutline"
                    size={18}
                  />
                )}
                color="error"
                size="large"
                variant="outlined"
                disabled={checkForPastDate(eventData?.start ?? new Date())}
                onClick={() => {
                  handleActions(onCancelRequest);
                }}
              >
                Request to Cancel
              </Button>)
            }
          </Box>
        </Stack>
      </Box>
    </Drawer>
  );
};

export default EventDetails;
