import React from 'react';
import { Box, Divider, List, ListItemButton } from '@mui/material';
import SvgIconWrapper from '../common/SvgIcon';
import { H5 } from '../common/Typography';
import { useNavigate } from 'react-router-dom';
import { ROUTE_LOGIN, ROUTE_SETTING } from '../../constants/routes';
import { useDispatch, useSelector } from 'react-redux';
import { setSuccessMessage, userLogout } from '../../redux/actions/appActions';
import { copyToClipboard } from '../../commonFunctions/utils';
import type { RootState } from '../../types/reduxState';

const ProfileMenu = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user: { user } } = useSelector((state: RootState) => state.app);

  const handleSettingsRoute = (): void => {
    navigate(ROUTE_SETTING);
  };

  const handleShareLink = (): void => {
    const baseUrl = window.location.origin;
    const shareUrl = `${baseUrl}/client/calendar/${user?.id as string}`;
    copyToClipboard(shareUrl, () => {
      dispatch(setSuccessMessage('Link copied successfully.'));
    });
  };

  const handleUserLogout = (data: any): void => {
    dispatch(userLogout(() => {
      navigate(ROUTE_LOGIN);
    }));
  };

  return (
    <Box>
      <List>
        <ListItemButton sx={{ display: 'flex', gap: 2, py: 2, px: 4 }} onClick={handleSettingsRoute}>
          <SvgIconWrapper name='Setting' color='primary' />
          <H5>Settings</H5>
        </ListItemButton>
        <Divider />
        <ListItemButton sx={{ display: 'flex', gap: 2, py: 2, px: 4 }} onClick={handleShareLink}>
          <SvgIconWrapper name='Share' color='primary' />
          <H5>Share Link</H5>
        </ListItemButton>
        <Divider />
        <ListItemButton sx={{ display: 'flex', gap: 2, py: 2, px: 4 }} onClick={handleUserLogout}>
          <SvgIconWrapper name='Logout' color='primary' />
          <H5>Logout</H5>
        </ListItemButton>
        <Divider />
      </List>
    </Box>
  );
};

export default ProfileMenu;
