import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import ajaxCallsInProgress from './reducers/ajaxStatusReducer';
import appReducer from './reducers/appReducer';
import calendarReducer from './reducers/calendarReducer';
import clientReducer from './reducers/clientReducer';
import packageReducer from './reducers/packageReducer';
import subscriptionReducer from './reducers/subscriptionReducer';
import dashboardReducer from './reducers/dashboardReducer';

const reducer = combineReducers({
  ajaxCallsInProgress,
  calendarState: calendarReducer,
  clientState: clientReducer,
  packageState: packageReducer,
  subscriptionState: subscriptionReducer,
  dashboardState: dashboardReducer,
  app: appReducer
});

const initialState = {};

const middleware = [thunk];

const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)));

export default store;
