import React from 'react';
import { Box, Grid, useTheme } from '@mui/material';
import { H1, H3 } from '../common/Typography';

interface LayoutProps {
  title: string
  description: string
  children: React.ReactNode
}

const Layout: React.FC<LayoutProps> = ({ title, description, children }): JSX.Element => {
  const theme = useTheme();
  const { palette: { text } } = theme;
  return (
    <Grid
      container
      sx={{
        p: { xs: 1, md: 4 },
        height: '100vh'
      }}
    >
      <Grid item xs={0} md={6}
        sx={{
          p: { xs: 1, md: 6 },
          display: { xs: 'none', md: 'flex' },
          flexDirection: 'column',
          justifyContent: 'space-between',
          background: '#EAF6FF',
          borderRadius: '25px'
        }}
      >
        <Box>
          <Box component="img" src='/logo.svg' sx={{ width: { xs: 150, sm: 220 } }} />
        </Box>
        <Box component="img" src="/images/image-06.png" sx={{ maxWidth: { xs: 300, sm: 450, md: '95%' } }} />
        {/* <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2
          }}
        >
          <H1
            sx={{
              fontSize: '50px',
              lineHeight: '70px'
            }}
          >
            Let us help you run your business
          </H1>
          <H2 sx={{ color: text.secondary }}>
            Our registration process is quick and easy, taking no more than 10 minutes to complete
          </H2>
        </Box>
        <Box>
          <TestimonialSilder />
        </Box> */}
      </Grid>
      <Grid item xs={12} md={6}
        sx={{
          px: { xs: 1, md: 6 },
          py: { xs: 2, md: 1 },
          display: 'flex',
          flex: '1 1 auto',
          flexDirection: 'column'
        }}
      >
        <Box pb={1}>
          <H1 lineHeight={1.2}>{title}</H1>
          <H3 color={text.secondary} fontWeight={400}>{description}</H3>
        </Box>
        {children}
      </Grid>
    </Grid>
  );
};

export default Layout;
