import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { navigationConfig } from './navigationConfig';
import Layout from '../components/Layout';
import { ROUTE_EMAIL_VERIFY, ROUTE_MAIN, ROUTE_RESET_PASSWORD, ROUTE_LOGIN, ROUTE_REGISTER, ROUTE_FORGOT_PASSWORD, ROUTE_PAYMENT_SETUP, ROUTE_PAYMENT_SETUP_ONBOARDING, ROUTE_PAYMENT_SETUP_VERIFICATION } from '../constants/routes';
import PaymentSetup from '../components/signup/Payment';
import PaymentOnboarding from '../components/signup/PaymentnOnborading';
import GoCardlessVerification from '../components/signup/GoCardlessVerification';
import Main from '../components/landingPage/Main';
const Registration = React.lazy(async () => await import('../components/signup/Registration'));
const Login = React.lazy(async () => await import('../components/signup/Login'));
const ForgotPassword = React.lazy(async () => await import('../components/signup/ForgotPassword'));
const ResetPassword = React.lazy(async () => await import('../components/signup/ResetPassword'));
const EmailVerify = React.lazy(async () => await import('../components/signup/EmailVerify'));
const NotFound = React.lazy(async () => await import('../components/NotFound'));

const Routes = ({ userRole }: { userRole: string }): JSX.Element => {
  const routes: React.ReactNode = useRoutes([
    {
      path: ROUTE_MAIN,
      element: <Main />
    },
    {
      path: ROUTE_REGISTER,
      element: <Registration />
    },
    {
      path: ROUTE_PAYMENT_SETUP,
      element: <PaymentSetup />
    },
    {
      path: ROUTE_LOGIN,
      element: <Login />
    },
    {
      path: ROUTE_FORGOT_PASSWORD,
      element: <ForgotPassword />
    },
    {
      path: ROUTE_RESET_PASSWORD,
      element: <ResetPassword />
    },
    {
      path: ROUTE_EMAIL_VERIFY,
      element: <EmailVerify />
    },
    {
      path: ROUTE_PAYMENT_SETUP_ONBOARDING,
      element: <PaymentOnboarding />
    },
    {
      path: ROUTE_PAYMENT_SETUP_VERIFICATION,
      element: <GoCardlessVerification />
    },
    ...navigationConfig.map((item) => {
      const { path, roles, component: Component } = item;
      return {
        path,
        element: roles.includes(userRole) ? <Layout><Component /></Layout> : <Navigate to="/404" />
      };
    }),
    {
      path: '/404',
      element: <NotFound />
    },
    {
      path: '*',
      element: <Navigate to="/404" />
    }
  ]);

  return routes as JSX.Element;
};

export default Routes;
