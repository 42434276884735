import React from 'react';
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select
} from '@mui/material';
import SelectFieldv2 from '../common/SelectFieldv2';
import { useDispatch, useSelector } from 'react-redux';
import type { RootState } from '../../types/reduxState';
import { resetCalendarFilters, setCalFilterClientData, setCalFilterSessionType } from '../../redux/actions/calendarAction';

const Filters = (): JSX.Element => {
  const dispatch = useDispatch();
  const { filters, sessionTypes, openFilter } = useSelector((state: RootState) => state.calendarState);

  const resetFilter = (): void => {
    dispatch(resetCalendarFilters());
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{
        px: { xs: 1, md: 2 },
        py: { xs: 1, md: 1 },
        display: 'flex',
        alignItems: 'center',
        borderBottom: (theme) => `1px solid ${theme.palette.outline.main}`
      }}
    >
      <Grid item xs={12} md={4}>
        <Box>
          <SelectFieldv2
            width="100%"
            placeHolder="Select Client"
            defaultValue={filters.clientName}
            makeApiCall={openFilter}
            getClientName={(data) => {
              if (data.key) {
                dispatch(setCalFilterClientData({
                  clientId: data.key,
                  clientName: data.value
                }));
              }
            }}
            clearLocation={!filters.clientName}
            textFieldSx={{ size: 'small' }}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={3}>
        <FormControl fullWidth>
          <Select
            size="small"
            value={filters.sessionId}
            onChange={(e) => { dispatch(setCalFilterSessionType(e.target.value)); }}
            displayEmpty
            inputProps={{ 'aria-label': 'sessionType' }}
            sx={{ color: ({ palette: { text } }) => text.secondary }}
          >
            <MenuItem value="" disabled>
              Session Type
            </MenuItem>
            {sessionTypes?.map((item) => (
              <MenuItem key={item.value} value={item.value}>{item.key}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={5} sx={{ textAlign: 'right' }}>
        <Box display="flex" justifyContent="end">
          <Button
            size="large"
            disabled={!filters.clientName && !filters.sessionId}
            onClick={resetFilter}
            color='error'
          >
            Clear All
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Filters;
