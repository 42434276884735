import React from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ROUTE_REGISTER } from '../../constants/routes';

const SectionSeven = (): JSX.Element => {
  const navigate = useNavigate();
  return (
    <Box sx={{
      bgcolor: 'white.main',
      py: { xs: 2, md: 4 }
    }}>
      <Container sx={{
        display: 'flex',
        justifyContent: 'center'
      }}>
        <Box
          sx={{
            background: 'linear-gradient(180deg, #0984E3 0%, #0068BA 100%)',
            width: '100%',
            borderRadius: 4,
            mb: 4,
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            gap: { xs: 2, md: 4 },
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <Typography color="white.main" sx={{ typography: { xs: 'h2', md: 'h1' }, py: { xs: 2, md: 0 }, fontFamily: 'Nunito, Sans-Serif !important', lineHeight: 'unset !important', textAlign: 'center' }}>
            Get Started Today
          </Typography>
          <Typography color="white.main" sx={{ typography: { xs: 'h4', md: 'h4' }, py: { xs: 2, md: 0 }, fontFamily: 'Nunito, Sans-Serif !important', lineHeight: 'unset !important', textAlign: 'center' }}>
            Sign up to become one of our early adopters!
          </Typography>
          <Box>
            <Button onClick={() => { navigate(ROUTE_REGISTER); }} variant='contained' color="white" sx={{ borderRadius: 1, minWidth: 80, py: { xs: 1, md: 1.5 } }}>Sign Up</Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default SectionSeven;
