import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Box, Button, useMediaQuery } from '@mui/material';
import CustomDialog from '../common/Dialog';
import Form from '../common/Form';
import FormInputTextField from '../common/FormInputTextField';
import { BODY1, BODY2, SUBTITLE1 } from '../common/Typography';
import SvgIconWrapper from '../common/SvgIcon';
import { mobileWidth } from '../../constants/AppVarConstants';
import { RequestCancelSessionSchema } from '../../validations/validationSchema';
import FormInputPhoneNumber from '../common/FormInputPhoneNumber';
import type { EventType } from '../../types/common';

interface RequestCancelSessionProps {
  open: boolean
  eventData?: EventType | null
  onClose: () => void
  fullWidth?: boolean
  handleRightClick?: (data: any) => void
  handleLeftClick?: () => void
};

const RequestCancelSession: React.FC<RequestCancelSessionProps> = (props) => {
  const {
    open = false,
    eventData = null,
    onClose = () => { },
    handleRightClick = () => { },
    fullWidth = false
  } = props;

  const isMobile = useMediaQuery(`(${mobileWidth})`);

  const appendValues = {
    phoneNumber: '',
    isoCode: 'GB',
    dialCode: '+44'
  };

  const {
    control,
    setValue,
    reset,
    clearErrors,
    handleSubmit
  } = useForm({
    defaultValues: {
      email: '',
      phone: [appendValues],
      note: ''
    },
    resolver: yupResolver(RequestCancelSessionSchema),
    mode: 'onSubmit'
  });

  const handleClose = (): void => {
    onClose();
    clearErrors();
  };

  const onSubmit = (data: any): void => {
    const payload = {
      email: data?.email,
      phone: data?.phone?.[0]?.phoneNumber ? `${data?.phone?.[0]?.dialCode as string}${data?.phone?.[0]?.phoneNumber as string}` : '',
      sessionId: eventData?.sessionId,
      clientId: eventData?.clientId,
      sessionDateTime: eventData?.start,
      reason: data?.note
    };
    handleRightClick(payload);
    handleClose();
  };

  return (
    <CustomDialog
      open={open}
      onClose={() => {
        handleClose();
        reset();
      }}
      isMobileScreen={isMobile}
      fullWidth={fullWidth}
      title="Request to Cancel Session "
      content={(
        <Form
          mainSX={{ rowSpacing: 2, columnSpacing: 3 }}
          fields={[
            {
              gridSX: { xs: 12, md: 12 },
              component: (
                <SUBTITLE1
                  sx={{
                    color: (theme) => theme.palette.text.secondary
                  }}
                >
                  Please confirm that you are&nbsp;
                  <b>
                    <i>{eventData?.client}</i>
                  </b>
                  ?&nbsp;If this is not your session, you cannot request cancellation.
                </SUBTITLE1>
              )
            },
            {
              label: 'Confirm your Identity',
              labelSX: { variant: 'h5', lineHeight: 1.8 },
              gridSX: { xs: 12, md: 12 },
              component: (
                <BODY2
                  sx={{
                    py: 1,
                    color: (theme) => theme.palette.text.secondary
                  }}
                >
                  This session belongs to you
                </BODY2>
              )
            },
            {
              label: 'Email',
              labelSX: { variant: 'h5', lineHeight: 1.8 },
              gridSX: { xs: 12, md: 6 },
              component: <FormInputTextField width="100%" name="email" control={control} placeholder='Email' endAdornment={<SvgIconWrapper name='Mail' size={16} />} />
            },
            {
              label: 'Phone Number',
              labelSX: { variant: 'h5', lineHeight: 1.8 },
              gridSX: { xs: 12, md: 6 },
              component: <FormInputPhoneNumber
                width="100%"
                name="phone"
                control={control}
                setValue={setValue}
                countries={['gb']}
                maxLen={10}
                endAdornment={<SvgIconWrapper name='Phone' size={18} />}
              />
            },
            {
              label: 'Reason for Cancellation',
              labelSX: { variant: 'h5', lineHeight: 1.8 },
              gridSX: { xs: 12, md: 12 },
              component: <FormInputTextField
                width="100%"
                name="note"
                control={control}
                placeholder='Write your notes  here...'
                textFieldSx={{
                  multiline: true,
                  rows: 4
                }}
              />
            },
            {
              gridSX: { xs: 12, md: 12 },
              component: (
                <Box display="flex" gap={1} pb={2}>
                  <SvgIconWrapper
                    name='Alert'
                    size={26}
                    sx={{
                      color: (theme) => theme.palette.deepOrange.main
                    }}
                  />
                  <BODY1
                    sx={{
                      color: (theme) => theme.palette.text.secondary
                    }}
                  >
                    Clients must provide a minimum of 24 hours&lsquo; notice to cancel or reschedule a training session. If a client cancels or reschedules a session with less than 24 hours&lsquo; notice, the session will be considered a &quot;late cancel&quot; and will be charged at the full rate.
                  </BODY1>
                </Box>
              )
            }
          ]}
        />
      )}
      leftButton={(
        <Button
          startIcon={(
            <SvgIconWrapper name='LeftArrow' size={14} />
          )}
          variant="outlined"
          size="large"
          onClick={() => {
            handleClose();
            reset();
          }}
        >
          Go Back
        </Button>
      )}
      rightButton={(
        <Button
          startIcon={(
            <SvgIconWrapper name='Send' size={16} />
          )}
          variant="contained"
          size="large"
          onClick={() => {
            void handleSubmit(onSubmit)();
          }}
        >
          Send Request
        </Button>
      )}
    />
  );
};

export default RequestCancelSession;
