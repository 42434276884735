import {
  IMAGE_MAX_SIZE,
  MAX_FULL_NAME_LENGTH,
  MAX_NAME_LENGTH,
  MAX_NOTES_LENGTH,
  MAX_PASSWORD_LENGTH,
  MIN_PASSWORD_LENGTH
} from './common';

export const FULLNAME_REQUIRED = 'Full name is required.';
export const FIRSTNAME_REQUIRED = 'First name is required.';
export const LASTNAME_REQUIRED = 'Last name is required.';
export const NAME_REQUIRED = 'Name is required.';
export const FULLNAME_NO_SPECIAL = 'No numbers or special characters are allowed.';
export const BIRTHDATE_REQUIRED = 'Birth date is required.';
export const GENDER_REQUIRED = 'Gender is required.';
export const PHONE_NUMBER_REQUIRED = 'Phone Number is required.';
export const ADDRESS_REQUIRED = 'Address is required.';

export const NAME_MAX_LENGTH = `Only ${MAX_NAME_LENGTH} characters are allowed`;
export const FULL_NAME_MAX_LENGTH = `Only ${MAX_FULL_NAME_LENGTH} characters are allowed`;
export const PHONE_MIN_LENTH = 'Please enter a valid phone number.';
export const NOTES_MAX_LENGTH = `Only ${MAX_NOTES_LENGTH} characters are allowed`;
export const NAME_NO_WHITESPACE = 'Only whitespaces are not allowed';
export const TYPE_NUMBER = 'Only numbers are allowed.';
export const TYPE_DATE = 'Date format should be DD/MM/YYYY.';
export const EMAIL_REQUIRED = 'Email is required.';
export const EMAIL_INVALID = 'Invalid email address.';
export const EMAIL_CONFRIM = 'Emails do not match.';

export const PASSWORD_REQUIRED = 'Password is required.';
export const PASSWORD_MIN_LENGTH = `Password must be at least ${MIN_PASSWORD_LENGTH} characters long.`;
export const PASSWORD_MAX_LENGTH = `Password must not exceed ${MAX_PASSWORD_LENGTH} characters.`;
export const PASSWORD_ONE_NUMBER = 'Password must contain at least one number';
export const PASSWORD_LETTERS_NUMBERS = 'Password must contain letters and numbers';
export const PASSWORD_NO_SPECIAL = 'Your password cannot contain special characters @!$%&*.';
export const PASSWORD_MATCH = 'Password did not match.';
export const PASSWORD_NO_WHITESPACE = 'Whitespace is not allowed.';
export const PASSWORD_STRENGTH = `A minimum ${MIN_PASSWORD_LENGTH} characters password containing a combination of uppercase or lowercase letters and numbers are required.`;

export const IMAGE_SIZE = `Image is too large to handle, maximum allowed size is ${(IMAGE_MAX_SIZE / 1000000).toFixed(2)} MB.`;
export const IMAGE_TYPE = 'Only JPG or PNG images are allowed.';

export const APP_CUSTOM_ERROR = 'Oops! Something went wrong, try again later.';

export const PASSWORD_LINK_SENT = 'Password reset link has been sent to your registered email address.';
export const PASSWORD_UPDATED = 'Password has been changed successfully.';
export const EMAIL_LINK_SENT = 'Email verification link has been sent successfully.';
