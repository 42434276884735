import { Box, Container, Typography } from '@mui/material';
import React from 'react';
import SvgIconWrapper from '../common/SvgIcon';

const cardData = [
  {
    icon: 'Schedule',
    title: 'Schedule, Reschedule & Edit Sessions',
    description: 'Create and manage recurring sessions with clients'
  },
  {
    icon: 'Wallet',
    title: 'Payment Information',
    description: 'Color-coded calendar displays the payment status of each session at a glance'
  },
  {
    icon: 'Request',
    title: 'Client Requests',
    description: 'Clients can view your calendar and have the ability to request sessions or cancellations'
  }
];

interface CardProps {
  icon: string
  title: string
  description: string
}
export const Card = (props: CardProps): JSX.Element => {
  const { icon, title, description } = props;
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      gap: { xs: 1, md: 2 },
      p: { xs: 1, md: 2 }
    }}>
      <Box sx={{ mt: 1 }}>
        <SvgIconWrapper
          name={icon}
          size={36}
        />
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: { xs: 1, md: 1 }
      }}>
        <Typography color="black" sx={{ typography: { xs: 'h5', md: 'h4' }, fontFamily: 'Nunito, Sans-Serif !important' }}>
          {title}
        </Typography>
        <Typography color="text.secondary" variant="h5" sx={{ fontWeight: 400 }} fontFamily="Nunito, Sans-Serif">
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

const SectionThree = (): JSX.Element => {
  return (
    <Box sx={{
      // minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#F7FBFE',
      py: { xs: 4, md: 6 },
      // background: 'linear-gradient(#CDE7FC, #FFFFFF)',
      position: 'relative',
      scrollSnapAlign: 'start'
    }}>
      <Box sx={{
        position: 'absolute',
        bgcolor: 'white.main',
        borderRadius: '50%',
        width: '100%',
        height: 60,
        top: -30
      }}
      />
      <Container sx={{
        // minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Box sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          flexGrow: 1,
          gap: { xs: 3, md: 6, lg: 9 },
          py: { xs: 2, md: 4 }
        }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            flexGrow: 1,
            gap: 2,
            maxWidth: { xs: '100%', md: '50%' }
          }}>
            <Box component="img" src="/images/image-03.png" sx={{ maxWidth: { xs: 300, sm: 450, md: '100%' } }} />
          </Box>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            flexGrow: 1,
            gap: { xs: 0.5, md: 2 },
            maxWidth: { xs: '100%', md: '50%' }
          }}>
            <Typography sx={{ typography: { xs: 'h2', md: 'h1' }, py: { xs: 2, md: 0, lineHeight: 'unset !important' }, fontFamily: 'Nunito, Sans-Serif !important', textAlign: 'left' }}>
              Organized and
              <Box component="span" color="primary.main">
                {`${' Efficient '}`}
              </Box>
              Scheduling System
            </Typography>
            <Typography sx={{ typography: { xs: 'h6', md: 'h4' }, fontFamily: 'Nunito, Sans-Serif !important' }} color="text.secondary" >
              Effortlessly manage your session bookings and see a clear overview of your availability. Enable clients to request to book sessions based on real-time availability.
            </Typography>
            {cardData.map((item) => (
              <Card
                key={item.title}
                icon={item.icon}
                title={item.title}
                description={item.description}
              />
            ))}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default SectionThree;
