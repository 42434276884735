import React from 'react';
import {
  Box,
  Button,
  Grid
} from '@mui/material';
import SearchField from '../common/SearchField';
import SvgIconWrapper from '../common/SvgIcon';
import { useDispatch } from 'react-redux';
import { exportPaymentHistory } from '../../redux/actions/appActions';

interface CustomToolbarProps {
  noHistory?: boolean
  searchValue: string
  setSearchValue: React.Dispatch<React.SetStateAction<string>>
}

const CustomToolbar: React.FC<CustomToolbarProps> = ({ noHistory = false, searchValue, setSearchValue }): JSX.Element => {
  const dispatch = useDispatch();

  const handleSearchValue = (value: string): void => {
    setSearchValue(value);
  };

  return (
    <Grid
      container
      sx={{
        px: { xs: 2, md: 4 },
        py: 2,
        borderBottom: (theme) => `1px solid ${theme.palette.outline.main}`,
        rowGap: { xs: 3, md: 0 }
      }}
    >
      <Grid
        item
        xs={12}
        md={7}
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2
        }}
      >
        <SearchField
          fullWidth
          value={searchValue}
          handleChange={handleSearchValue}
          placeholder='Search by client name...'
        />
      </Grid>
      <Grid item xs={12} md={5}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: { xs: 'space-between', md: 'end' },
            alignItems: 'center',
            gap: 1
          }}
        >
          <Button
            startIcon={(
              <SvgIconWrapper
                name="Download"
                size={18}
                color={noHistory ? 'disabled' : 'primary'}
              />
            )}
            variant="outlined"
            size="large"
            onClick={() => {
              dispatch(exportPaymentHistory(searchValue));
            }}
            disabled={noHistory}
          >
            Export CSV
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default CustomToolbar;
