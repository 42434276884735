import React from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ROUTE_LOGIN, ROUTE_REGISTER } from '../../constants/routes';

const SectionOne = (): JSX.Element => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        background: 'linear-gradient(#CDE7FC, #FFFFFF)',
        py: { xs: 2, md: 4 },
        scrollSnapAlign: 'start'
      }}
    >
      <Container
        sx={{
          // minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            mt: { xs: 2, md: 4 },
            minHeight: 64
          }}
        >
          <Box>
            <Box
              component="img"
              src="/logo.svg"
              sx={{ width: { xs: 150, sm: 220 } }}
            />
          </Box>
          <Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                gap: { xs: 1, md: 2 }
              }}
            >
              <Button
                onClick={() => {
                  navigate(ROUTE_LOGIN);
                }}
                variant="contained"
                color="white"
                sx={{ borderRadius: 1, minWidth: 80, py: { xs: 1, md: 1.5 } }}
              >
                Login
              </Button>
              <Button
                onClick={() => {
                  navigate(ROUTE_REGISTER);
                }}
                variant="contained"
                color="primary"
                sx={{ borderRadius: 1, minWidth: 80, py: { xs: 1, md: 1.5 } }}
              >
                Sign Up
              </Button>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column-reverse', md: 'row' },
            flexGrow: 1,
            gap: { xs: 2, md: 4, lg: 6 },
            py: { xs: 2, md: 4 }
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: { xs: 'flex-start', md: 'center' },
              flexGrow: 1,
              gap: { xs: 0.5, md: 2 },
              maxWidth: { xs: '100%', md: '50%' }
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Nunito, Sans-Serif',
                fontSize: { xs: 36, md: 45 },
                fontWeight: 700
              }}
            >
              Your
              <Box component="span" color="primary.main">
                &nbsp;Training,
              </Box>
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Nunito, Sans-Serif',
                fontSize: { xs: 36, md: 45 },
                fontWeight: 700
              }}
            >
              Your
              <Box component="span" color="primary.main">
                &nbsp;Terms,
              </Box>
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Nunito, Sans-Serif',
                fontSize: { xs: 36, md: 45 },
                fontWeight: 700
              }}
            >
              Our
              <Box component="span" color="primary.main">
                &nbsp;Technology,
              </Box>
            </Typography>
            <Typography
              sx={{
                typography: { xs: 'h6', md: 'h4' },
                fontFamily: 'Nunito, Sans-Serif !important'
              }}
              color="text.secondary"
            >
              Revolutionise your personal training with easy payments,
              scheduling and descriptive analytics
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: { xs: 'flex-end', md: 'center' },
              alignItems: 'center',
              flexGrow: 1,
              gap: 2,
              maxWidth: { xs: '100%', md: '50%' }
            }}
          >
            <Box
              component="img"
              src="/images/total_income.png"
              sx={{ maxWidth: { xs: 300, sm: 450, md: '100%' } }}
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default SectionOne;
