import React from 'react';
import { Box, Button, Stack } from '@mui/material';
import { BODY1, H3 } from './Typography';
import SvgIconWrapper from './SvgIcon';
import CustomDialog from './Dialog';
import { useDispatch, useSelector } from 'react-redux';
import type { RootState } from '../../types/reduxState';
import GoCardLess from '../common/GoCardLess';
import { isEmpty } from 'lodash';
import { resendEmailVerificationLink, userLogout } from '../../redux/actions/appActions';
import { ROUTE_LOGIN } from '../../constants/routes';
import { useNavigate } from 'react-router-dom';

interface VerifyPaymentDialogProps {
  handlePaymentSetup: () => void
}

const VerifyPaymentDialog: React.FC<VerifyPaymentDialogProps> = (props): JSX.Element => {
  const { handlePaymentSetup } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { app } = useSelector((state: RootState) => state);
  const { user } = app.user;

  const getEmailVerificationLink = (): void => {
    dispatch(resendEmailVerificationLink());
  };

  const handleUserLogout = (): void => {
    dispatch(userLogout(() => {
      navigate(ROUTE_LOGIN);
    }));
  };

  return (
    <CustomDialog
      open={user?.isValidEmail && isEmpty(app?.paymentInfo)}
      onClose={() => { }}
      showDividers
      fullWidth
      maxWidth='xs'
      content={(
        <Stack direction="column" spacing={2}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2
            }}
          >
            <SvgIconWrapper name='LockFilled' size={45} />
            <H3 fontWeight={700}>Payment setup is required</H3>
          </Box>
          <BODY1 lineHeight={1.8}>
            Please click on the link below to start setting your payment with&nbsp;
            <GoCardLess fill="black" sx={{ width: 100, fontSize: 14 }} />
          </BODY1>
          <Box display="flex" justifyContent="space-between">
            <Button
              endIcon={<SvgIconWrapper name='Logout' size={16} />}
              variant="outlined"
              size="large"
              color='error'
              onClick={handleUserLogout}
            >
              Logout
            </Button>
            <Button
              endIcon={<SvgIconWrapper name='RightArrow' size={16} />}
              variant="contained"
              size="large"
              onClick={handlePaymentSetup}
            >
              Continue to payment setup
            </Button>
          </Box>
          {!user?.isValidEmail && <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignItems: 'center',
              gap: 1
            }}
          >
            <BODY1>
              Please verify your Email Id
            </BODY1>
            <Button size='large' onClick={getEmailVerificationLink}>
              Verify Now
            </Button>
          </Box>}
        </Stack>
      )}
    />
  );
};

export default VerifyPaymentDialog;
