import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import SvgIconWrapper from '../common/SvgIcon';
import SectionTwoA from './sectionTwoA';

const cardData = [
  {
    icon: 'PaymentCard',
    title: 'Streamlined Payments',
    problem: 'Freelancers struggle to create retention with clients due to a lack of simplified billing options, inconvenient bank transfers and one-off cash payments.',
    solution: 'Focus on delivering exceptional services while we handle seamless and automated recurring payments.'
  },
  {
    icon: 'AppointmentReminder',
    title: 'Appointment Reminders',
    problem: 'Clients often cancel late or miss sessions due to misunderstandings. Trainers lose valuable time and money due to scheduling mishaps.',
    solution: 'Never miss a session with automated reminders, ensuring that you and your clients stay on track with scheduled sessions.'
  }
];

interface CardProps {
  data: {
    icon: string
    title: string
    problem: string
    solution: string
  }
}
const Card = ({ data }: CardProps): JSX.Element => {
  const { icon, title, problem, solution } = data;
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      gap: { xs: 1, md: 2 },
      p: { xs: 1, md: 2 }
    }}>
      <Box>
        <SvgIconWrapper
          name={icon}
          size={50}
        />
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: { xs: 1, md: 2 }
      }}>
        <Typography color="black" sx={{ typography: { xs: 'h5', md: 'h4' }, fontFamily: 'Nunito, Sans-Serif !important' }}>
          {title}
        </Typography>
        <Typography color="text.secondary" variant="h5" fontFamily="Nunito, Sans-Serif">
          Problem:&nbsp;
          <Box component="span" sx={{ fontWeight: 400 }}>
            {problem}
          </Box>
        </Typography>
        <Typography color="text.secondary" variant="h5" fontFamily="Nunito, Sans-Serif">
          Solution:&nbsp;
          <Box component="span" sx={{ fontWeight: 400 }}>
            {solution}
          </Box>
        </Typography>
      </Box>
    </Box>
  );
};

const SectionTwo = (): JSX.Element => {
  return (
    <Box sx={{
      // minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      bgcolor: 'white.main',
      py: { xs: 2, md: 4 },
      scrollSnapAlign: 'start'
    }}>
      <Container sx={{
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          py: { xs: 0, md: 0 }
        }}>
          <Typography sx={{ typography: { xs: 'h2', md: 'h1' }, py: { xs: 2, md: 0 }, fontFamily: 'Nunito, Sans-Serif !important', textAlign: 'center' }}>
            Freelancing Finds its
            <Box component="span" color="primary.main">
              &nbsp;Flow
            </Box>
          </Typography>
          <Typography variant='h5' fontWeight={600} color="text.secondary" textAlign="center" fontFamily="Nunito, Sans-Serif">
          In partnership with a <Box component="span" color="primary.main" sx={{ fontFamily: 'Nunito, Sans-Serif !important', fontWeight: 700 }}>14-year</Box> experienced personal trainer, we&apos;ve created a one-stop app for freelance fitness pros using GoCardless&apos;s seamless payment system. Our mission is to empower freelance personal trainers with streamlined efficiency, enabling them to save time, money, and focus on delivering exceptional services experiences for their clients.
          </Typography>
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: { xs: 'column-reverse', md: 'row' },
          width: '100%',
          flexGrow: 1,
          gap: { xs: 2, md: 4, lg: 6 },
          py: { xs: 2, md: 4 }
        }}>
          <Box sx={{
            width: { xs: '100%', md: '60%' },
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}>
            <Grid container spacing={2}>
              {cardData.map((item) => (
                <Grid item xs={12} key={item.title}>
                  <Card
                    data={item} />
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            maxWidth: { xs: '100%', md: '40%' }
          }}>
            <Box component="img" src="/images/image-02.png" sx={{ maxWidth: { xs: 300, sm: 450, md: '100%' } }} />
          </Box>
        </Box>
        <SectionTwoA />
      </Container>
    </Box>
  );
};

export default SectionTwo;
