import React from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { mobileWidth } from '../../constants/AppVarConstants';
import { H4 } from '../common/Typography';

const Headers = (): JSX.Element => {
  const isMobile = useMediaQuery(`(${mobileWidth})`);

  return (
    <Box
      sx={{
        zIndex: 100,
        display: 'flex',
        position: 'sticky',
        top: 0,
        bgcolor: '#F5F7F9'
      }}
    >
      <Box py={3} flex={1.5} minWidth={140} bgcolor="#F5F7F9">
        <H4 pl={isMobile ? 1 : 4}>Client</H4>
      </Box>
      <Box py={3} flex={1} minWidth={140} textAlign="center" bgcolor="#F5F7F9">
        <H4>Package</H4>
      </Box>
      <Box py={3} flex={1} minWidth={120} textAlign="center" bgcolor="#F5F7F9">
        <H4>Amount</H4>
      </Box>
      <Box py={3} flex={1} minWidth={120} textAlign="center" bgcolor="#F5F7F9">
        <H4>Date</H4>
      </Box>
      <Box py={3} flex={1.5} minWidth={200} textAlign="center" bgcolor="#F5F7F9">
        <H4>Status</H4>
      </Box>
      <Box py={3} flex={1} minWidth={180} textAlign="center" bgcolor="#F5F7F9">
        <H4>Payment ID</H4>
      </Box>
    </Box>
  );
};

export default Headers;
