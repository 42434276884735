import { Button } from '@mui/material';
import React from 'react';
import SvgIconWrapper from './SvgIcon';
import CustomDialog from './Dialog';
import MessageContent from './MessageContent';

interface ConfirmationDialogProps {
  open: boolean
  title?: string
  description?: string
  edit?: boolean
  deleteIcon?: boolean
  archive?: boolean
  warning?: boolean
  onClose?: () => void
  leftButtonLabel?: string
  leftIcon?: React.ReactNode
  onLeftClick?: () => void
  customField?: React.ReactElement
  rightIcon?: React.ReactNode
  rightButtonLabel?: string
  maxWidth?: 'xl' | 'xs' | 'sm' | 'lg' | 'md'
  onClick: () => void
};

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = (props): JSX.Element => {
  const {
    open = false,
    title = '',
    description = '',
    edit = false,
    warning = false,
    archive = false,
    deleteIcon = false,
    leftButtonLabel = 'Go Back',
    leftIcon = null,
    customField = null,
    rightButtonLabel = 'Confirm',
    rightIcon = null,
    maxWidth = 'xs',
    onClose = () => { },
    onLeftClick = () => { },
    onClick = () => { }
  } = props;

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      showDividers
      fullWidth
      maxWidth={maxWidth}
      content={(
        <MessageContent
          title={title}
          body={description}
          edit={edit}
          deleteIcon={deleteIcon}
          archive={archive}
          warning={warning}
        />
      )}
      leftButton={customField ?? (
        <Button
          startIcon={leftIcon ?? <SvgIconWrapper name="LeftArrow" size={16} />}
          variant="outlined"
          color='primary'
          size="large"
          onClick={onLeftClick}
        >
          {leftButtonLabel}
        </Button>
      )}
      rightButton={(
        <Button
          startIcon={rightIcon ?? <SvgIconWrapper name="Confirm" size={16} />}
          variant="contained"
          size="large"
          onClick={onClick}
        >
          {rightButtonLabel}
        </Button>
      )}
    />
  );
};

export default ConfirmationDialog;
