import initialState from './initialState';
import * as types from '../actions/actionTypes';
import type { DashboardState } from '../../types/reduxState';

interface appActions {
  type: string
  payload: any
}

export default function dashboardReducer (currentState: DashboardState, action: appActions): any {
  const state = currentState || initialState.dashboardState;
  switch (action.type) {
    case types.SET_SESSIONS_GRAPH_DATA:
      return {
        ...state,
        sessionsData: action.payload
      };

    case types.SET_COMPLIMENTARY_SESSION_DATA:
      return {
        ...state,
        complimentarySessions: action.payload
      };

    case types.SET_CLIENTS_STATISTICS_DATA:
      return {
        ...state,
        stats: action.payload
      };

    case types.SET_SESSION_CARDS_DETAILS:
      return {
        ...state,
        cardDetails: action.payload
      };

    case types.SET_DASHBOARD_LIST_CLIENTS_DATA:
      return {
        ...state,
        clientsData: action.payload
      };

    case types.REFETCH_DASHBOARD_DATA:
      return {
        ...state,
        reFetchPackages: Number(state.reFetchStatData) + 1
      };

    default:
      return state;
  }
}
