import React from 'react';
import {
  Box,
  Button,
  Grid,
  Stack,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { H3, H5, SUBTITLE1, SUBTITLE2 } from '../common/Typography';
import TextLabel from '../common/TextLabel';
import SvgIconWrapper from '../common/SvgIcon';
import type { Client } from '../../types/common';
import { ACTIVE, MANDATE_STATUS, mobileWidth } from '../../constants/AppVarConstants';
import { calculateAge } from '../../commonFunctions/utils';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';

interface CardProps {
  onClick?: (client: Client) => void
  clientData: Client
  handleSendRequest?: () => void
};

const Card: React.FC<CardProps> = (props): JSX.Element => {
  const {
    onClick = (client: Client) => { },
    clientData,
    handleSendRequest = (client?: Client) => { }
  } = props;

  const { palette: { text } } = useTheme();
  const isMobile = useMediaQuery(`(${mobileWidth})`);

  return (
    <Box
      sx={{
        px: { xs: 2, md: 4 },
        py: 2
      }}
    >
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={7} md={3.5}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexDirection={isMobile ? 'column' : 'row'}
          gap={3}
        >
          <Stack direction="column" width="100%">
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <H3
                onClick={() => { onClick(clientData); }}
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    textDecoration: isMobile ? 'none' : 'underline'
                  }
                }}
              >
                {clientData.fullName}
              </H3>
            </Box>
            <SUBTITLE1
              sx={{
                fontWeight: 400,
                color: (theme) => theme.palette.text.secondary
              }}
            >
              {clientData.email}
            </SUBTITLE1>
          </Stack>
        </Grid>
        <Grid item xs={5} md={2.5}>
          <Box display="flex" gap={1}>
            {!isMobile && <SvgIconWrapper
              name='Phone'
              sx={{
                color: (theme) => theme.palette.text.secondary
              }}
            />}
            <H5 fontWeight={400} color={text.secondary}>{clientData.phone}</H5>
          </Box>
        </Grid>
        {!isMobile && <Grid item xs={0} md={2}>
          <Box textAlign="center">
            <H5 fontWeight={400} color={text.secondary}>
              Age {calculateAge(clientData?.birthdate)}
            </H5>
          </Box>
        </Grid>}
        <Grid item xs={12} md={4} alignItems="center">
          <Box width="100%" display="flex" justifyContent={isMobile ? 'end' : 'center'} alignItems="center">
            {(clientData.status === ACTIVE)
              ? (!isEmpty(clientData?.billingRequest) ? clientData?.billingRequest?.fulfilled && (clientData?.mandate?.status === ACTIVE) : (clientData?.mandate?.status === ACTIVE))
                  ? <TextLabel label='Payment Setup Complete' success />
                  : ['SUBMITTED'].includes(clientData?.mandate?.status)
                      ? <TextLabel label='Payment Setup Complete' success />
                      : ['PENDING_SUBMISSION', 'PENDING_CUSTOMER_APPROVAL', 'PENDING'].includes(clientData?.mandate?.status)
                          ? <TextLabel label={MANDATE_STATUS[clientData?.mandate?.status]} />
                          : <Box textAlign="center">
                          <Button
                            startIcon={(
                              <SvgIconWrapper name='Send' size={16} />
                            )}
                            variant='outlined'
                            size='large'
                            onClick={() => {
                              handleSendRequest(clientData);
                            }}
                          >
                            {clientData.billingRequest ? 'Resend Payment Setup' : 'Request Payment Setup'}
                          </Button>
                          {clientData?.billingRequest && <SUBTITLE2 pt={1} color={text.secondary}>
                            {`${MANDATE_STATUS[clientData?.mandate?.status] ?? 'Last request sent'} on ${dayjs(clientData?.billingRequest?.created).format('DD/MM/YYYY')}`}
                          </SUBTITLE2>}
                        </Box>
              : <TextLabel inactive label='Inactive' />
            }
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Card;
