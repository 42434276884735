import React from 'react';
import { Box, Button, Stack } from '@mui/material';
import { BODY1, H3 } from './Typography';
import SvgIconWrapper from './SvgIcon';
import CustomDialog from './Dialog';
import { useDispatch, useSelector } from 'react-redux';
import { userLogout } from '../../redux/actions/appActions';
import { ROUTE_LOGIN } from '../../constants/routes';
import { useNavigate } from 'react-router-dom';
import { IN_PROGRESS } from '../../constants/AppVarConstants';
import type { RootState } from '../../types/reduxState';

const VerifyVerificationStatusDialog = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { paymentInfo } = useSelector((state: RootState) => state.app);

  const handleUserLogout = (): void => {
    dispatch(userLogout(() => {
      navigate(ROUTE_LOGIN);
    }));
  };

  return (
    <CustomDialog
        open={!!paymentInfo.verification && paymentInfo.verification === IN_PROGRESS}
      onClose={() => { }}
      showDividers
      fullWidth
      maxWidth='xs'
      content={(
        <Stack direction="column" spacing={2}>
            <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2
                }}
            >
                <Box component="img" src="/Pending-Icon.svg" sx={ { width: { xs: 45, sm: 45 } }}/>
                <H3 fontSize={20} fontWeight={700}>Verification Pending</H3>
            </Box>
            <BODY1 lineHeight={1.8}>
            Return to access your account after the GoCardless
              verification process is complete. This process is typically
              completed within 3-5 business days.
          </BODY1>
          <Box display="flex" justifyContent="space-between">
            <Button
              endIcon={<SvgIconWrapper name='Logout' size={16} />}
              variant="contained"
              size="large"
              onClick={handleUserLogout}>
              Logout
            </Button>
          </Box>
        </Stack>
      )}
    />
  );
};

export default VerifyVerificationStatusDialog;
