import React from 'react';
import { Avatar, Box, Button, Divider, useMediaQuery, useTheme } from '@mui/material';
import { H1, H3, SUBTITLE1 } from '../common/Typography';
import SvgIconWrapper from '../common/SvgIcon';
import TextLabel from '../common/TextLabel';
import { ACTIVE, PACKAGE_INTERVAL_OPTIONS, mobileWidth } from '../../constants/AppVarConstants';
import type { PackageType } from '../../types/common';
import { getCurrencySymbol } from '../../commonFunctions/utils';

interface PackageCardProps {
  packageData: PackageType
  handleEdit: (data: PackageType) => void
}

const PackageCard: React.FC<PackageCardProps> = (props): JSX.Element => {
  const {
    packageData,
    handleEdit = () => { }
  } = props;

  const { name, price, currency, sessions, status, interval } = packageData;

  const isMobile = useMediaQuery(`(${mobileWidth})`);
  const { palette: { primary, outline, text } } = useTheme();

  // const calculateEachSessionCost = (totalPrice: number, sessions: number): number => {
  //   return totalPrice / sessions;
  // };

  const getIntervalLabel = (): string => {
    const labelData = PACKAGE_INTERVAL_OPTIONS.filter((item) => item.value === interval);
    return labelData[0].key;
  };

  return (
    <Box
      sx={{
        pt: 2,
        minWidth: isMobile ? 350 : 430,
        borderWidth: 1,
        position: 'relative',
        borderStyle: 'solid',
        borderRadius: '5px',
        borderColor: outline.main,
        background: 'white'
      }}
    >
      <Box
        sx={{
          width: 70,
          position: 'absolute',
          right: 15
        }}
      >
        {status === ACTIVE
          ? <TextLabel success label='Active' />
          : <TextLabel label='Inactive' />
        }
      </Box>
      <Box py={1} px={2}>
        <H3 fontSize={22} color={primary.main}>
          {name}
        </H3>
        <Box py={1} display="flex" alignItems="end" gap={1}>
          <H1 lineHeight={1.3}>{getCurrencySymbol(currency)}{price.toFixed(2)}</H1>
          <H3 color={text.secondary}>/{getIntervalLabel().slice(0, -2)}</H3>
        </Box>
      </Box>
      <Divider />
      <Box p={2} display="flex" gap={3}>
        <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} gap={0.5} alignItems="center">
          <Avatar sx={{ width: 26, height: 26, background: '#F0F1F9' }}>
            <SvgIconWrapper sx={{ color: '#6771C5' }} name='Calendar' size={12} />
          </Avatar>
          <SUBTITLE1 color={text.secondary}>Charged {getIntervalLabel()}</SUBTITLE1>
        </Box>
        <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} gap={0.5} alignItems="center">
          <Avatar sx={{ width: 26, height: 26, background: '#F0F1F9' }}>
            <SvgIconWrapper sx={{ color: '#6771C5' }} name='Tag' size={12} />
          </Avatar>
          <SUBTITLE1 color={text.secondary}>{sessions} Sessions</SUBTITLE1>
        </Box>
      </Box>
      <Divider />
      <Box p={2}>
        <Button
          size='large'
          color='primary'
          variant='outlined'
          startIcon={<SvgIconWrapper name='Edit' size={16} />}
          sx={{
            py: 1,
            borderColor: primary.main
          }}
          onClick={() => {
            handleEdit(packageData);
          }}
        >
          Edit Package
        </Button>
      </Box>
    </Box>
  );
};

export default PackageCard;
