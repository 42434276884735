import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Stack, useMediaQuery } from '@mui/material';
import { H2, SUBTITLE1 } from '../common/Typography';
import ImageUpload from '../common/ImageUpload';
import FormInputTextField from '../common/FormInputTextField';
import SelectField from '../common/SelectField';
import { DURATION_OPTIONS, mobileWidth } from '../../constants/AppVarConstants';
import { Controller, useForm } from 'react-hook-form';
import Form from '../common/Form';
import SvgIconWrapper from '../common/SvgIcon';
import { useDispatch, useSelector } from 'react-redux';
import type { RootState } from '../../types/reduxState';
import CurrencyField from '../common/CurrencyField';
import { SettingsSchema } from '../../validations/validationSchema';
import { getMediaId, removeProfilePicture, setErrorMessage, updateProfile } from '../../redux/actions/appActions';
import { APP_CUSTOM_ERROR } from '../../constants/MessageConstants';
import TimezoneField from '../common/TimezoneField';

const Settings = (): JSX.Element => {
  // const { palette: { text } } = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(`(${mobileWidth})`);
  const { user } = useSelector((state: RootState) => state.app.user);

  const [image, setImage] = useState<File | null>(null);

  const {
    watch,
    control,
    setValue,
    clearErrors,
    formState: { errors, isValid },
    handleSubmit
  } = useForm({
    defaultValues: {
      firstName: user?.firstName ?? '',
      lastName: user?.lastName ?? '',
      email: user?.email ?? '',
      defaultTime: '',
      timezone: 'Europe/London',
      currencyCode: ''
    },
    resolver: yupResolver(SettingsSchema),
    mode: 'onSubmit'
  });

  useEffect(() => {
    if (user) {
      setValue('firstName', user?.firstName);
      setValue('lastName', user?.lastName);
      setValue('email', user?.email);
      setValue('defaultTime', user?.duration ? String(user?.duration) : '');
      setValue('currencyCode', user?.currencyCode);
      setValue('timezone', user?.zoneId ?? 'Europe/London');
    }
  }, [user]);

  const timeZone = watch('timezone');
  const currencyCode = watch('currencyCode');

  useEffect(() => {
    if (isValid || (timeZone && currencyCode)) {
      clearErrors();
    }
  }, [isValid, timeZone, currencyCode]);

  const onSubmit = async (data: any): Promise<void> => {
    const payload = {
      firstName: data?.firstName,
      lastName: data?.lastName,
      email: data?.email,
      timezone: data?.timezone,
      currency: data?.currencyCode,
      duration: Number(data?.defaultTime)
    };
    dispatch(updateProfile(payload));
  };

  const handleImgUpload = async (file: File): Promise<void> => {
    let mediaId = '';
    if (file) {
      try {
        const formData = new FormData();
        formData.append('image', file);
        const data = await getMediaId(formData);
        mediaId = data?.result?.id;
      } catch (error: any) {
        dispatch(setErrorMessage(error.errorMsg ?? APP_CUSTOM_ERROR));
      }
    }
    const payload = {
      firstName: watch('firstName'),
      lastName: watch('lastName'),
      email: watch('email'),
      timezone: timeZone,
      currency: currencyCode,
      duration: Number(watch('defaultTime')),
      mediaId
    };
    dispatch(updateProfile(payload));
  };

  const handleImgRemove = (): void => {
    dispatch(removeProfilePicture(() => {
      setImage(null);
    }));
  };

  // const getEmailVerificationLink = (): void => {
  //   dispatch(resendEmailVerificationLink());
  // };

  return (
    <Box
      sx={{
        display: 'flex',
        flex: '1 1 auto',
        p: isMobile ? 3 : 5,
        flexDirection: 'column',
        justifyContent: 'space-between',
        background: '#fff',
        ...(!isMobile && {
          borderRadius: '25px',
          border: (theme) => `1px solid ${theme.palette.outline.main}`
        })
      }}
    >
      <Box>
        <Box
          pb={2}
          display="flex"
          justifyContent="space-between"
          flexDirection={isMobile ? 'column' : 'row'}
          gap={isMobile ? 2 : 0}
        >
          <Stack spacing={2}>
            {!isMobile && <H2>Settings</H2>}
            <SUBTITLE1
              sx={{
                color: (theme) => theme.palette.text.secondary
              }}
            >
              Change your business settings and update
            </SUBTITLE1>
          </Stack>
          <ImageUpload
            cropedImg={image}
            defaultSrc={user?.imageUrl ?? ''}
            setcropedImg={setImage}
            handleImgUpload={(file) => {
              void handleImgUpload(file);
            }}
            handleImgRemove={handleImgRemove}
          />
        </Box>
        <Form
          mainSX={{ spacing: isMobile ? 1 : 4 }}
          fields={[
            {
              label: 'First Name',
              labelSX: { variant: 'h5', lineHeight: 1.8 },
              gridSX: { xs: 12, md: 6 },
              component: <FormInputTextField width="100%" name="firstName" control={control} placeholder='First Name' capitalizeFirstLetter />
            },
            {
              label: 'Last Name',
              labelSX: { variant: 'h5', lineHeight: 1.8 },
              gridSX: { xs: 12, md: 6 },
              component: <FormInputTextField width="100%" name="lastName" control={control} placeholder='Last Name' capitalizeFirstLetter />
            },
            {
              label: 'Email Id',
              labelSX: { variant: 'h5', lineHeight: 1.8 },
              gridSX: { xs: 12, md: 6 },
              component: (
                <Box textAlign='right' position='relative'>
                  <FormInputTextField
                    width="100%"
                    name="email"
                    control={control}
                    placeholder='Email'
                    endAdornment={<SvgIconWrapper name='Mail' size={16} />}
                    disabled
                  />
                  {/* <Button
                    sx={{
                      position: 'absolute',
                      top: -25,
                      right: -5
                    }}
                    onClick={getEmailVerificationLink}
                  >
                    Verify Now
                  </Button> */}
                </Box>
              )
            },
            {
              label: 'Set default times to each session duration',
              labelSX: { variant: 'h5', lineHeight: 1.8 },
              gridSX: { xs: 12, md: 6 },
              component: <SelectField width="100%" name="defaultTime" control={control} options={DURATION_OPTIONS} placeholder='Select default hours' />
            },
            {
              label: 'Timezone',
              labelSX: { variant: 'h5', lineHeight: 1.8 },
              gridSX: { xs: 12, md: 6 },
              component: (
                <Controller
                  name='timezone'
                  control={control}
                  render={({ field }) => (
                    <TimezoneField
                      width="100%"
                      field={field}
                      defaultValue={user?.zoneId ?? 'Europe/London'}
                      error={!!errors.timezone}
                      helperText={errors.timezone?.message ?? ' '}
                      placeHolder='Select timezone'
                      getTimezone={(value) => {
                        setValue('timezone', value);
                      }}
                    />
                  )}
                />
              )
            },
            {
              label: 'Currency',
              labelSX: { variant: 'h5', lineHeight: 1.8 },
              gridSX: { xs: 12, md: 6 },
              component: (
                <Controller
                  name='currencyCode'
                  control={control}
                  render={({ field }) => (
                    <CurrencyField
                      width="100%"
                      field={field}
                      defaultValue={user?.currencyCode ? `${user?.symbol as string} ${user?.currencyCode as string}` : ''}
                      error={!!errors.currencyCode}
                      helperText={errors.currencyCode?.message ?? ' '}
                      placeHolder='Select a Currency'
                      getCurrency={(value) => {
                        setValue('currencyCode', value);
                      }}
                    />
                  )}
                />
              )
            }
            // {
            //   label: 'Payment details',
            //   labelSX: { variant: 'h3', lineHeight: 1.5 },
            //   gridSX: { xs: 12, md: 6 },
            //   component: (
            //     <Box>
            //       <SUBTITLE1 color={text.secondary} lineHeight={2.5}>
            //         Manage your payment details on the GoCardless website.
            //       </SUBTITLE1>
            //       <Button
            //         startIcon={(
            //           <SvgIconWrapper name='Payment' />
            //         )}
            //         size='large'
            //         variant='contained'
            //       >
            //         Manage Payment
            //       </Button>
            //     </Box>
            //   )
            // }
          ]}
        />
      </Box>
      <Box textAlign="right">
        <Button
          startIcon={(
            <SvgIconWrapper name="Check" size={16} />
          )}
          variant="contained"
          size="large"
          onClick={() => {
            void handleSubmit(onSubmit)();
          }}
        >
          Update
        </Button>
      </Box>
    </Box>
  );
};

export default Settings;
