import React, { useState } from 'react';
import CustomDialog from '../common/Dialog';
import { Button, FormControl, MenuItem, Select, useMediaQuery } from '@mui/material';
import SvgIconWrapper from '../common/SvgIcon';
import Form from '../common/Form';
import { useDispatch, useSelector } from 'react-redux';
import type { RootState } from '../../types/reduxState';
import { resetCalendarFilters, setCalFilterClientData, setCalFilterSessionType, setCalFilterState } from '../../redux/actions/calendarAction';
import SelectFieldv2 from '../common/SelectFieldv2';
import { mobileWidth } from '../../constants/AppVarConstants';

interface FilterModalProps {
  applyFilter?: () => void
  fullWidth?: boolean
}

const FilterModal: React.FC<FilterModalProps> = (props): JSX.Element => {
  const {
    fullWidth = false
  } = props;

  const dispatch = useDispatch();
  const isMobile = useMediaQuery(`(${mobileWidth})`);
  const { filters, openFilter, sessionTypes } = useSelector((state: RootState) => state.calendarState);

  const [clinetData, setClinetData] = useState({ clientId: filters.clientId, clientName: filters.clientName });
  const [sessionId, setsessionId] = useState(filters.sessionId);

  const resetFilter = (): void => {
    setClinetData({
      clientId: '',
      clientName: ''
    });
    setsessionId('');
  };

  const handleSave = (): void => {
    dispatch(setCalFilterClientData({
      clientId: clinetData.clientId,
      clientName: clinetData.clientName
    }));
    dispatch(setCalFilterSessionType(sessionId));
    dispatch(setCalFilterState(false));
  };

  return (
    <CustomDialog
      open={openFilter && isMobile}
      onClose={() => {
        dispatch(resetCalendarFilters());
        dispatch(setCalFilterState(false));
      }}
      isMobileScreen
      fullWidth={fullWidth}
      title='Filter'
      content={(
        <Form
          mainSX={{ spacing: 3 }}
          fields={[
            {
              gridSX: { xs: 12, md: 12 },
              component: (
                <FormControl fullWidth>
                  <SelectFieldv2
                    width="100%"
                    placeHolder="Select Client"
                    defaultValue={clinetData.clientName}
                    makeApiCall={openFilter && isMobile}
                    getClientName={(data) => {
                      if (data.key) {
                        setClinetData({
                          clientId: data.key,
                          clientName: data.value
                        });
                      }
                    }}
                    clearLocation={!clinetData.clientName}
                  />
                </FormControl>
              )
            },
            {
              gridSX: { xs: 12, md: 12 },
              component: (
                <FormControl fullWidth>
                  <Select
                    value={sessionId}
                    onChange={(e) => {
                      setsessionId(e.target.value);
                    }}
                    displayEmpty
                    inputProps={{ 'aria-label': 'sessionType' }}
                    sx={{ color: ({ palette: { text } }) => text.secondary }}
                  >
                    <MenuItem value="" disabled>
                      Session Type
                    </MenuItem>
                    {sessionTypes?.map((item) => (
                      <MenuItem key={item.value} value={item.value}>{item.key}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )
            }
          ]}
        />
      )}
      leftButton={(
        <Button
          startIcon={(
            <SvgIconWrapper
              name="Cancel"
              size={16}
            />
          )}
          size="large"
          variant="outlined"
          color="error"
          disabled={!clinetData.clientName && !sessionId}
          onClick={resetFilter}
        >
          Clear All
        </Button>
      )}
      rightButton={(
        <Button
          startIcon={(
            <SvgIconWrapper
              name='Add'
              size={16}
            />
          )}
          variant="contained"
          size="large"
          disabled={!clinetData.clientName && !sessionId}
          onClick={handleSave}
        >
          Save
        </Button>
      )}
    />
  );
};

export default FilterModal;
