import React from 'react';
import {
  FormControl,
  type FormControlProps,
  InputAdornment,
  TextField,
  type TextFieldProps
} from '@mui/material';
import SvgIconWrapper from './SvgIcon';

interface SearchFieldProps extends FormControlProps {
  value: string
  placeholder?: string
  textFieldSx?: TextFieldProps
  handleChange?: (value: string) => void
}

const SearchField: React.FC<SearchFieldProps> = (props): JSX.Element => {
  const {
    value = '',
    placeholder = '',
    textFieldSx = {},
    handleChange = () => { },
    ...rest
  } = props;

  return (
    <FormControl {...rest}>
      <TextField
        {...textFieldSx}
        size='small'
        sx={{
          '.MuiOutlinedInput-root': {
            borderRadius: 999,
            bgcolor: '#EFF1F3'
          },
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: (theme) => theme.palette.outline.main
          }
        }}
        id="Search"
        variant="outlined"
        className=""
        onChange={(e) => { handleChange(e.target.value); }}
        placeholder={placeholder}
        value={value}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SvgIconWrapper name='SearchOutlined' size={16} />
            </InputAdornment>
          )
        }}
      />
    </FormControl>
  );
};

export default SearchField;
