import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTE_CALENDAR, ROUTE_FORGOT_PASSWORD, ROUTE_LOGIN, ROUTE_MAIN, ROUTE_PAYMENT_SETUP, ROUTE_REGISTER, ROUTE_RESET_PASSWORD } from '../constants/routes';
import { ACCESS_TOKEN, USER } from '../constants/AppVarConstants';
import { fetchUserProfile, getUserPaymentInfo, setUserRole } from '../redux/actions/appActions';
import { Box, CircularProgress, Typography } from '@mui/material';

const publicRoutes = [
  ROUTE_REGISTER,
  ROUTE_LOGIN,
  ROUTE_FORGOT_PASSWORD,
  ROUTE_RESET_PASSWORD,
  ROUTE_PAYMENT_SETUP
  // ROUTE_MAIN
];

const AuthWrapper = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userRole = useSelector((state: any) => state.app.userRole);
  const accessToken = localStorage.getItem(ACCESS_TOKEN);
  const location = useLocation();

  const isPublicRoute = publicRoutes.includes(location.pathname);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if ((location.pathname.includes(ROUTE_REGISTER) || location.pathname.includes(ROUTE_LOGIN)) && accessToken) {
      navigate(ROUTE_CALENDAR);
      setIsLoading(false);
      return;
    }

    if (isPublicRoute || location.pathname.includes('/email/verify') || location.pathname.includes('/password/reset')) {
      // If it's a public route, no need to fetch the user role
      // dispatch(setUserRole(null));
      setIsLoading(false);
      return;
    }

    if (location.pathname.includes('/client/calendar')) {
      // If it's a public route, no need to fetch the user role
      setIsLoading(false);
      dispatch(setUserRole(USER));
      return;
    }

    // Check if there is a token in localStorage
    if (accessToken) {
      dispatch(fetchUserProfile());
      dispatch(getUserPaymentInfo());
      if (!isPublicRoute && location.pathname !== ROUTE_MAIN) {
        navigate(location.pathname + location.search);
      } else {
        navigate(ROUTE_CALENDAR);
      }
      setIsLoading(false);
    } else {
      // If there is no token, set the user role to a default value or null
      // dispatch(setUserRole(null));
      setIsLoading(false);
      navigate(ROUTE_MAIN);
    }
  }, [dispatch, accessToken, isPublicRoute]);

  if (isPublicRoute || userRole === null) {
    // Render the public routes or show a message indicating that the user needs to log in
    return <>{children}</>;
  }

  if (isLoading) {
    return (
      <Box height="100vh" display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap={2}>
        <CircularProgress color="primary" />
        <Typography variant='body1' color="inherit">Loading...</Typography>
      </Box>
    );
  }

  return <>{children}</>;
};

export default AuthWrapper;
