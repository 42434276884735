import { Box } from '@mui/material';
import React from 'react';
import SectionOne from './SectionOne';
import SectionTwo from './SectionTwo';
import SectionThree from './SectionThree';
import SectionFour from './SectionFour';
import SectionFive from './SectionFive';
import SectionSix from './SectionSix';
import SectionSeven from './SectionSeven';

const Main = (): JSX.Element => {
  return (
    <Box sx={{
      minHeight: '100vh',
      scrollSnapType: 'y mandatory'
    }}>
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <SectionFour />
      <SectionFive />
      <SectionSix />
      <SectionSeven />
    </Box>
  );
};

export default Main;
