import React, { Suspense } from 'react';
import { ThemeProvider } from '@mui/material';
import Routes from './AppRoutes';
import CustomSnackbar from '../components/common/Snackbar';
import BackdropLoader from '../components/common/BackdropLoader';
import theme from '../theme';
import { useSelector } from 'react-redux';
import AuthWrapper from './AuthWrapper';

const App = (): JSX.Element => {
  const userRole = useSelector((state: any) => state.app.userRole);

  return (
    <ThemeProvider theme={theme}>
      <Suspense fallback={<BackdropLoader />}>
        <AuthWrapper>
          <Routes userRole={userRole} />
        </AuthWrapper>
        <BackdropLoader />
        <CustomSnackbar />
      </Suspense>
    </ThemeProvider>
  );
};

export default App;
