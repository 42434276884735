// /* eslint-disable*/
import React, { useEffect, useState } from 'react';
import {
  FormControl,
  TextField,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  CircularProgress,
  ClickAwayListener,
  type TextFieldProps,
  Box,
  Button
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import InfiniteScroll from 'react-infinite-scroll-component';
import { setErrorMessage } from '../../redux/actions/appActions';
import { useDispatch } from 'react-redux';
import { GET_ALL_CLIENTS } from '../../redux/api/config';
import API_MIDDLEWARE from '../../redux/api/middleware';
import { useDebounce } from '../../hooks/useDebounce';
import SvgIconWrapper from './SvgIcon';
import { SUBTITLE1 } from './Typography';
import { CLIENTS_LIST_DATA, CLIENT_SEARCH_VALUE } from '../../constants/AppVarConstants';

interface SelectFieldv2Props {
  field?: any
  textFieldSx?: TextFieldProps
  width?: string | number
  defaultValue?: string
  placeHolder?: string
  error?: boolean
  helperText?: string
  disabled?: boolean
  makeApiCall?: boolean
  activeMandatClinets?: boolean
  clearLocation?: boolean
  getClientName?: ((item: { key: string, value: string, mandateId?: string, nextPossibleChargeDate?: any }) => void) | undefined
  clearErrors?: (() => void) | undefined
  showAddClient?: boolean
  handleAddClient?: () => void
}

const SelectFieldv2: React.FC<SelectFieldv2Props> = (props): JSX.Element => {
  const {
    field,
    textFieldSx,
    width,
    defaultValue = '',
    makeApiCall = false,
    activeMandatClinets = false,
    error = false,
    placeHolder,
    helperText,
    disabled,
    clearLocation,
    getClientName,
    showAddClient = false,
    handleAddClient = () => { },
    clearErrors
  } = props;

  const dispatch = useDispatch();
  const preLoadData = localStorage.getItem(CLIENTS_LIST_DATA);
  const preSearchVal = localStorage.getItem(CLIENT_SEARCH_VALUE);

  const [open, setOpen] = useState(false);
  // const [focus, setFocus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(false);
  const [value, setValue] = useState(defaultValue);

  const [results, setResults] = useState<any>([]);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [hasMoreClients, setHasMoreClients] = useState(false);

  const debouncedValue = useDebounce(value, 500);

  const fetchClientList = (flag: boolean): void => {
    if (!flag) {
      setLoading(true);
    }
    const payload = {
      limit: 5,
      offset: flag ? currentOffset + 5 : 0,
      query: debouncedValue || '*',
      ...(activeMandatClinets && {
        forSubscription: true
      }),
      status: 'ACTIVE'
    };
    API_MIDDLEWARE(
      GET_ALL_CLIENTS,
      'POST',
      {
        'Content-Type': 'application/json'
      },
      payload
    )
      .then((res) => {
        const { hits, isHasMore, offset } = res.result;
        const keyPair = hits?.map((client: any) => {
          return {
            allDetails: client,
            value: client.fullName,
            key: client.clientId,
            ...(client?.mandate && {
              mandateId: client?.mandate?.id,
              nextPossibleChargeDate: client?.mandate?.nextPossibleChargeDate
            })
          };
        });
        const data = [...(flag ? results : []), ...keyPair];
        localStorage.setItem(CLIENTS_LIST_DATA, JSON.stringify(data));
        localStorage.setItem(CLIENT_SEARCH_VALUE, value);
        setResults(data);
        setHasMoreClients(isHasMore);
        setCurrentOffset(offset);
        setLoading(false);
      })
      .catch((err) => {
        dispatch(setErrorMessage(err?.errorMsg));
        setLoading(false);
      });
  };

  useEffect(() => {
    if (makeApiCall && (!preLoadData || value !== preSearchVal)) {
      fetchClientList(false);
    } else {
      setResults(preLoadData ? JSON.parse(preLoadData) : []);
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    } else {
      setValue('');
    }
  }, [clearLocation]);

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setOpen(true);
    setSelected(false);
    setValue(e.target.value);
    getClientName?.({ key: '', value: e.target.value, mandateId: '' });
  };

  const handleSelect = (client: { key: string, value: string, mandateId?: string }): void => {
    setOpen(false);
    setSelected(true);
    setValue(client.value);
    getClientName?.(client);
  };

  const handleClickAway = (): void => {
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <FormControl sx={{ width: { width } }}>
        <TextField
          {...field}
          {...textFieldSx}
          variant="outlined"
          onClick={() => { setOpen(true); }}
          fullWidth
          onChange={(e) => { handleAddressChange(e); }}
          onBlur={clearErrors}
          value={value}
          autoComplete="off"
          disabled={disabled}
          error={error}
          placeholder={placeHolder}
          helperText={helperText}
          sx={{
            '.MuiOutlinedInput-root': {
              backgroundColor: 'common.white',
              color: 'text.secondary'
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon fontSize='medium' />
              </InputAdornment>
            )
          }}
        />
        {open
          ? (
            <List
              id="scrollableSelectDiv"
              sx={{
                width: '100%',
                position: 'absolute',
                marginTop: '3.5rem',
                background: 'white',
                borderRadius: '5px',
                zIndex: '1000',
                border: '1px solid',
                borderColor: (theme) => theme.palette.outline.main,
                ...(
                  results?.length &&
                  open && {
                    maxHeight: '200px'
                  }
                ),
                overflowY: 'scroll',
                '::-webkit-scrollbar': {
                  width: '5px'
                },
                '::-webkit-scrollbar-track': {
                  borderRadius: '5px'
                },
                '::-webkit-scrollbar-thumb': {
                  background: '#bebfcc',
                  borderRadius: '5px'
                }
              }}
              aria-label="clients"
            >
              {loading && !selected
                ? (<ListItem disablePadding divider>
                  <ListItemButton sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress color='primary' />
                  </ListItemButton>
                </ListItem>)
                : results.length
                  ? (<InfiniteScroll
                    style={{ overflow: 'hidden' }}
                    dataLength={results.length}
                    hasMore={hasMoreClients}
                    next={() => { fetchClientList(true); }}
                    loader={(<Box textAlign="center">
                      <CircularProgress color='primary' />
                    </Box>)}
                    endMessage={!showAddClient
                      ? <Box display='flex' justifyContent="center" py={1}>
                        <SUBTITLE1>No More Clients.</SUBTITLE1>
                      </Box>
                      : <Box p={1} display="flex" justifyContent="center">
                        <Button
                          size='large'
                          variant='contained'
                          startIcon={<SvgIconWrapper name='Add' size={16} />}
                          onClick={handleAddClient}
                        >
                          Add Client
                        </Button>
                      </Box>}
                    scrollableTarget="scrollableSelectDiv"
                  >
                    <Box>
                      {
                        results.map((item: any) => {
                          return (
                            <ListItem
                              key={item.key}
                              disablePadding
                              onClick={() => {
                                handleSelect(item);
                              }}
                              divider
                            >
                              <ListItemButton>
                                <ListItemText
                                  primary={item.value}
                                />
                              </ListItemButton>
                            </ListItem>
                          );
                        })
                      }
                    </Box>
                  </InfiniteScroll>)
                  : (<ListItem disablePadding>
                    {!showAddClient
                      ? <ListItemButton disabled sx={{ display: 'flex', justifyContent: 'center' }}>
                        No More Client.
                      </ListItemButton>
                      : <Box width="100%" display="flex" justifyContent="center">
                        <Button
                          size='large'
                          variant='contained'
                          startIcon={<SvgIconWrapper name='Add' size={16} />}
                          onClick={handleAddClient}
                        >
                          Add Client
                        </Button>
                      </Box>}
                  </ListItem>)
              }
            </List>)
          : ' '}
      </FormControl>
    </ClickAwayListener >
  );
};

export default SelectFieldv2;
