import React, { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  useMediaQuery
} from '@mui/material';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import SvgIconWrapper from '../common/SvgIcon';
import CustomDialog from '../common/Dialog';
import Form from '../common/Form';
import { H3, SUBTITLE1 } from '../common/Typography';
import SelectField from '../common/SelectField';
import { mobileWidth } from '../../constants/AppVarConstants';
import FormInputTextField from '../common/FormInputTextField';
import type { Client } from '../../types/common';
import { useDispatch, useSelector } from 'react-redux';
import type { RootState } from '../../types/reduxState';
import { sendMandateRequest } from '../../redux/actions/clientActions';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PACKAGES } from '../../constants/routes';
import FormDateField from '../common/FormDateField';
import dayjs from 'dayjs';
import moment from 'moment/moment';

export const PaymentMandateSchema = Yup.object().shape({
  message: Yup.string(),
  packageId: Yup.string(),
  startDate: Yup.date()
});

interface PaymentUrlDialogProps {
  open: boolean
  clientDetails: Client | null
  fullWidth?: boolean
  onClose?: () => void
};

const PaymentUrlDialog: React.FC<PaymentUrlDialogProps> = (props): JSX.Element => {
  const [isVisible, setIsVisible] = useState(false);

  const {
    open = false,
    clientDetails = null,
    fullWidth = false,
    onClose = () => { }
  } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(`(${mobileWidth})`);
  // const [subscription, setSubscription] = useState(false);
  const { packageOptions } = useSelector((state: RootState) => state.packageState);

  const {
    control,
    reset,
    handleSubmit
  } = useForm({
    defaultValues: {
      message: '',
      packageId: '',
      startDate: dayjs().add(7, 'day').toDate()
    },
    resolver: yupResolver(PaymentMandateSchema),
    mode: 'onSubmit'
  });

  const handleClose = (): void => {
    setIsVisible(false);
    reset();
    onClose();
  };

  const onSubmit = (data: any): void => {
    const payload = {
      clientId: clientDetails?.clientId,
      packageId: data.packageId,
      content: data.message ?? '',
      isSubscription: Boolean(data.packageId),
      startDate: data.packageId ? moment(data.startDate).format('YYYY-MM-DD') : null
    };
    dispatch(sendMandateRequest(payload));
    handleClose();
  };

  return (
    <CustomDialog
      open={open}
      onClose={handleClose}
      fullWidth={fullWidth}
      isMobileScreen={isMobile}
      title='Mandate Payment'
      description='Create a subscription to automatically charge your clients. If you do not select a package, this mandate will give you permission to bill them later when payments are due.'
      content={(
        <Form
          mainSX={{ gap: 3 }}
          fields={[
            {
              gridSX: { xs: 12, md: 12 },
              component: (
                <Box display="flex" flexDirection="column" gap={2}>
                  <H3>Invite by Email</H3>
                </Box>
              )
            },
            {
              label: 'Email',
              labelSX: { variant: 'h5' },
              gridSX: { xs: 12, md: 12 },
              component: (
                <TextField fullWidth value={clientDetails?.email} disabled />
              )
            },
            {
              label: 'Custom Message',
              sideLabel: '(optional)',
              labelSX: { variant: 'h5' },
              gridSX: { xs: 12, md: 12 },
              component: (
                <Box>
                  <FormInputTextField
                    width="100%"
                    name='message'
                    control={control}
                    placeholder='Type your message here'
                    textFieldSx={{
                      multiline: true,
                      rows: 5
                    }}
                  />
                  <SUBTITLE1 sx={{ color: (theme) => theme.palette.text.secondary }}>
                    If you don&apos;t add a custom message, we&apos;ll default to GoCardless messaging
                  </SUBTITLE1>
                </Box>
              )
            },
            {
              label: 'Create a Subscription',
              sideLabel: '(optional)',
              labelSX: { variant: 'h5' },
              gridSX: { xs: 12, md: 12 },
              component: (
                <Box display="flex" flexDirection="column" gap={2} pb={3}>
                  <SelectField
                    width="100%"
                    name='packageId'
                    customFunction={ (data) => { setIsVisible(true); } }
                    control={control}
                    options={packageOptions}
                    placeholder='Select Package'
                    endButton={(
                      <Box textAlign="center" py={1}>
                        <Button
                          startIcon={<SvgIconWrapper name='Add' size={16} />}
                          variant='contained'
                          size='medium'
                          onClick={() => {
                            handleClose();
                            navigate(ROUTE_PACKAGES);
                          }}
                        >
                          Add Package
                        </Button>
                      </Box>
                    )}
                  />
                  {/* <Stack direction="row" gap={1} alignItems="center">
                    <Switch
                      checked={subscription}
                      onChange={(e) => {
                        setSubscription(e.target.checked);
                      }}
                    />
                    <H5 fontWeight={700}>Add to Subscription</H5>
                  </Stack> */}
                </Box>
              )
            }, {
              label: isVisible ? 'Direct Debit Start Date' : '',
              labelSX: { variant: 'h5' },
              gridSX: { xs: 12, md: 12 },
              component: (
                  <Box display={ isVisible ? 'flex' : 'none' } flexDirection="column" gap={2} pb={3}>
                  <FormDateField width="100%" name="startDate" control={control} disablePast minDate={dayjs().add(7, 'day')} />
                  </Box>
              )
            }
          ]}
        />
      )}
      leftButton={(
        <Button
          startIcon={(
            <SvgIconWrapper name="Cancel" size={16} />
          )}
          variant="outlined"
          size="large"
          color='error'
          onClick={handleClose}
        >
          Cancel
        </Button>
      )}
      rightButton={(
        <Button
          startIcon={(
            <SvgIconWrapper name="Send" size={16} />
          )}
          variant='contained'
          size="large"
          onClick={() => {
            void handleSubmit(onSubmit)();
          }}
        >
          Send Request
        </Button>
      )}
    />
  );
};

export default PaymentUrlDialog;
