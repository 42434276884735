import React, { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { Box, Button, Checkbox, Divider, FormControlLabel, Grid, useMediaQuery } from '@mui/material';
import CustomDialog from '../common/Dialog';
import { BODY2, H3, H5, SUBTITLE1 } from '../common/Typography';
import FormInputTextField from '../common/FormInputTextField';
import SvgIconWrapper from '../common/SvgIcon';
import type { EventType } from '../../types/common';
import { formatDate, formatTimeRange } from '../../commonFunctions/utils';
import { mobileWidth } from '../../constants/AppVarConstants';
import { CancelSessionSchema } from '../../validations/validationSchema';
import { useSelector } from 'react-redux';
import type { RootState } from '../../types/reduxState';

interface CancelSessionProps {
  open: boolean
  eventData: EventType | null
  onClose: () => void
  resetState?: number
  handleCancelSession?: (data: { reason: string, cancelAll: boolean }) => void
  fullWidth?: boolean
};

const CancelSession: React.FC<CancelSessionProps> = (props) => {
  const {
    open = false,
    onClose = () => { },
    handleCancelSession = () => { },
    resetState,
    eventData = null,
    fullWidth = false
  } = props;

  const isMobile = useMediaQuery(`(${mobileWidth})`);
  const { user: { user } } = useSelector((state: RootState) => state.app);

  const {
    control,
    reset,
    handleSubmit
  } = useForm({
    defaultValues: {
      reason: '',
      cancelAll: false
    },
    resolver: yupResolver(CancelSessionSchema),
    mode: 'onSubmit'
  });

  const handleClose = (): void => {
    onClose();
    reset();
  };

  useEffect(() => {
    reset();
  }, [resetState]);

  const onSubmit = (data: any): void => {
    handleCancelSession(data);
    onClose();
  };

  return (
    <CustomDialog
      open={open}
      onClose={handleClose}
      fullWidth={fullWidth}
      // maxWidth='md'
      isMobileScreen={isMobile}
      title="Cancel Session"
      description="Cancel any session before the scheduled time"
      content={(
        <Box>
          <Box pb={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    minHeight: 50,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2
                  }}
                >
                  <SvgIconWrapper
                    name='User'
                    sx={{
                      color: (theme) => theme.palette.text.secondary
                    }}
                  />
                  <H3>{eventData?.client}</H3>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    minHeight: 50,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2
                  }}
                >
                  <SvgIconWrapper
                    name='Repeat'
                    sx={{
                      color: (theme) => theme.palette.text.secondary
                    }}
                  />
                  <H3>{eventData?.repeatType}</H3>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    minHeight: 50,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2
                  }}
                >
                  <SvgIconWrapper
                    name='Calendar'
                    sx={{
                      color: (theme) => theme.palette.text.secondary
                    }}
                  />
                  <Box>
                    <H3>{formatDate(eventData?.start)}</H3>
                    <SUBTITLE1 sx={{ color: (theme) => theme.palette.text.secondary }}>
                      {formatTimeRange(eventData?.start, eventData?.end, user?.zoneId)}
                    </SUBTITLE1>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    minHeight: 50,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2
                  }}
                >
                  <SvgIconWrapper
                    name='Tag'
                    sx={{
                      color: (theme) => theme.palette.text.secondary
                    }}
                  />
                  <H3>{eventData?.sessionType}</H3>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <Box pt={2}>
            <Box display="flex" alignItems="center" gap={3}>
              <H5>Reason for Cancellation</H5>
              <FormControlLabel
                control={(
                  <Controller
                    control={control}
                    name="cancelAll"
                    render={({ field: { value, onChange } }) => (
                      <Checkbox
                        disabled={!eventData?.recurrence}
                        checked={value}
                        sx={{
                          color: ({ palette: { outline } }) => outline.main
                        }}
                        onChange={(e): void => { onChange(e.target.checked); }}
                      />
                    )}
                  />
                )}
                label={(
                  <BODY2
                    sx={{
                      color: (theme) => theme.palette.text.secondary
                    }}
                  >
                    Cancel all future scheduled sessions with this client
                  </BODY2>
                )}
              />
            </Box>
          </Box>
          <FormInputTextField
            width="100%"
            control={control}
            name="reason"
            placeholder="Write your notes here..."
            textFieldSx={{
              multiline: true,
              rows: 4
            }}
          />
        </Box>
      )}
      leftButton={(
        <Button
          startIcon={(
            <SvgIconWrapper
              name="Cancel"
              size={16}
            />
          )}
          size="large"
          variant="outlined"
          color="error"
          onClick={handleClose}
        >
          Cancel
        </Button>
      )}
      rightButton={(
        <Button
          startIcon={(
            <SvgIconWrapper
              name="Confirm"
              size={16}
            />
          )}
          variant="contained"
          size="large"
          onClick={() => {
            void handleSubmit(onSubmit)();
          }}
        >
          Confirm
        </Button>
      )}
    />
  );
};

export default CancelSession;
