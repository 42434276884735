import { type RootState } from '../../types/reduxState';

const initialState: RootState = {
  app: {
    errorMessage: '',
    successMessage: '',
    successDialogInfo: {
      title: '',
      description: ''
    },
    user: {},
    paymentInfo: {
      redirectUrl: '',
      verification: ''
    },
    registrationData: {
      step1: {
        firstName: '',
        lastName: '',
        password: '',
        confirmPassword: '',
        phone: [{
          isoCode: 'GB',
          phoneNumber: '',
          dialCode: '+44'
        }],
        email: ''
      }
    },
    loader: false,
    isAuthenticated: false,
    userRole: 'admin'
  },
  calendarState: {
    allSessionsData: [],
    events: [],
    reFetchEvents: 1,
    sessionTypes: [],
    openFilter: false,
    filters: {
      clientId: '',
      clientName: '',
      sessionId: ''
    }
  },
  clientState: {
    reFetchClients: 1,
    clients: []
  },
  packageState: {
    packageList: [],
    packageOptions: [],
    reFetchPackages: 1
  },
  subscriptionState: {
    subscriptions: [],
    clientOptions: [],
    reFetchSubscription: 1
  },
  dashboardState: {
    cardDetails: {
      sessions: 0,
      totalIncome: 0,
      hourlyRate: 0,
      dailyEaring: 0
    },
    stats: {
      genders: {
        male: 0,
        female: 0
      },
      averageAge: 0,
      averageRetention: 0
    },
    sessionsData: [],
    clientsData: [],
    complimentarySessions: [[]],
    reFetchStatData: 1
  },
  ajaxCallsInProgress: 0
};

export default initialState;
