import type { WeekDaysType } from '../components/common/CustomRecurrence';

// Roles
export const ADMIN = 'admin';
export const USER = 'user';

export const ACTIVE = 'ACTIVE';
export const INACTIVE = 'INACTIVE';
export const DELETED = 'DELETED';
export const PAUSE = 'PAUSE';
export const RESUME = 'RESUME';
export const FINISHED = 'FINISHED';
export const UNTIL = 'UNTIL';
export const CYCLE = 'CYCLE';

export const ACCESS_TOKEN = 'access_token';
export const TEMPORARY_TOKEN = 'temp_token';
export const REFRESH_TOKEN = 'refresh_token';

export const CLIENTS_LIST_DATA = 'clients_list';
export const CLIENT_SEARCH_VALUE = 'search_value';

// Recurrence constants
export const DAY = 'DAY';
export const WEEK = 'WEEK';
export const MONTH = 'MONTH';
export const YEAR = 'YEAR';
export const END_NEVER = 'NEVER';
export const END_ON = 'ON';
export const END_AFTER = 'AFTER';

export const RESCHEDULED = 'RESCHEDULED';
export const CANCELLED = 'CANCELLED';

// Payment Status
export const COMPLETED = 'COMPLETED';
export const INPROGRESS = 'INPROGRESS';
export const IN_PROGRESS = 'IN_PROGRESS';
export const CONFIRMED = 'CONFIRMED';
export const FAILED = 'FAILED';
export const PAID_OUT = 'PAID_OUT';
export const CUSTOMER_APPROVAL_DENIED = 'CUSTOMER_APPROVAL_DENIED';

export const mobileWidth = 'max-width:768px';

export const GENDER_OPTIONS: any[] = [
  { key: 'Male', value: 'MALE' },
  { key: 'Female', value: 'FEMALE' }
];

export const FIND_OPTIONS: any[] = [
  { key: 'Through a friend', value: 'Through a friend' },
  { key: 'Through a colleague', value: 'Through a colleague' },
  { key: 'Search engine', value: 'Search engine' },
  { key: 'Advertisement', value: 'Advertisement' }
];

export const EXPERIENCE_OPTIONS: any[] = [
  { key: '1-5', value: '1-5' },
  { key: '5-10', value: '5-10' },
  { key: '10-15', value: '10-15' },
  { key: '15-20', value: '15-20' },
  { key: '20+', value: '20+' }
];

export const CALENDAR_VIEW_OPTIONS: any[] = [
  { key: 'MONTH', value: 'month' },
  { key: 'WEEKLY', value: 'week' },
  { key: 'DAY', value: 'day' }
];

export const LABELS_OPTIONS: any[] = [
  { color: '#6771C5', label: 'Comp/Consultation' },
  { color: '#F96F77', label: 'Incomplete' },
  { color: '#EAB440', label: 'Pending' },
  { color: '#05C09F', label: 'Paid' }
];

export const SESSION_TYPE_OPTIONS: any[] = [
  { value: 'Consultation', key: 'Consultation (new clients only) ' },
  { value: 'Training Session', key: 'Training Session (existing clients only)' },
  { value: 'Makeup Session', key: 'Makeup Session (existing clients only)' }
];

export const DURATION_OPTIONS: any[] = [
  { value: '30', key: '30 mins' },
  { value: '45', key: '45 mins' },
  { value: '60', key: '1hr' },
  { value: '90', key: '1:30' },
  { value: '120', key: '2hrs' },
  { value: '150', key: '2:30' },
  { value: '180', key: '3hrs' }
];

export const RECURRENCE_TYPE_OPTIONS: any[] = [
  { key: 'Does Not Repeat', value: 'NO_REPEAT' },
  { key: 'Custom', value: 'CUSTOM' }
];

export const RECURRENCE_OPTIONS: any[] = [
  { key: 'Day', value: DAY },
  { key: 'Week', value: WEEK },
  { key: 'Month', value: MONTH },
  { key: 'Year', value: YEAR }
];

export const PACKAGE_INTERVAL_OPTIONS: any[] = [
  { key: 'Weekly', value: 'WEEKLY' },
  { key: 'Monthly', value: 'MONTHLY' },
  { key: 'Yearly', value: 'YEARLY' }
];

export const REPEAT_TYPE_OPTIONS: any[] = [
  { key: 'No Repeat', value: 'No Repeat' },
  { key: 'Repeat', value: 'Repeat' }
];

export const SUBSCRIPTION_STATUS_OPTIONS: any[] = [
  { key: 'Active', value: ACTIVE },
  { key: 'Inactive', value: INACTIVE }
];

export const WEEK_DAY_OPETIOS: WeekDaysType[] = [
  { accessKey: 'SU', value: 'Sunday', label: 'S', checked: false },
  { accessKey: 'MO', value: 'Monday', label: 'M', checked: false },
  { accessKey: 'TU', value: 'Tuesday', label: 'T', checked: false },
  { accessKey: 'WE', value: 'Wednesday', label: 'W', checked: false },
  { accessKey: 'TH', value: 'Thursday', label: 'T', checked: false },
  { accessKey: 'FR', value: 'Friday', label: 'F', checked: false },
  { accessKey: 'SA', value: 'Saturday', label: 'S', checked: false }
];

export const CURRENCY_OPETIOS = [
  { code: 'USD', symbol: '$' },
  { code: 'EUR', symbol: '€' },
  { code: 'GBP', symbol: '£' },
  { code: 'AUD', symbol: 'A$' },
  { code: 'CAD', symbol: 'C$' },
  { code: 'CHF', symbol: 'CHF' },
  { code: 'SEK', symbol: 'SEK' },
  { code: 'NOK', symbol: 'NOK' },
  { code: 'DKK', symbol: 'DKK' },
  { code: 'SGD', symbol: 'S$' },
  { code: 'NZD', symbol: 'NZ$' },
  { code: 'HKD', symbol: 'HK$' },
  { code: 'ZAR', symbol: 'R' },
  { code: 'JPY', symbol: '¥' },
  { code: 'INR', symbol: '₹' },
  { code: 'BRL', symbol: 'R$' },
  { code: 'MXN', symbol: 'Mex$' },
  { code: 'ARS', symbol: 'ARS$' },
  { code: 'TRY', symbol: '₺' },
  { code: 'AED', symbol: 'د.إ' }
];

export const MANDATE_STATUS: Record<string, string> = {
  PENDING_CUSTOMER_APPROVAL: 'Pending Customer Approval',
  PENDING_SUBMISSION: 'Pending Submission',
  PENDING: 'Pending Submission',
  SUBMITTED: 'Submitted',
  ACTIVE: 'Payment Setup complete',
  FAILED: 'Failed',
  CANCELLED: 'Cancelled',
  EXPIRED: 'Expired',
  CONSUMED: 'Consumed',
  BLOCKED: 'Blocked',
  SUSPENDED_BY_PAYER: 'Suspended by Payer',
  UNKNOWN: 'Last request sent'
};
