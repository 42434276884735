import React, { useState } from 'react';
import { Box, Button, FormControlLabel, Radio, RadioGroup, TextField, useMediaQuery, useTheme } from '@mui/material';
import CustomDialog from '../common/Dialog';
import Form from '../common/Form';
import { CYCLE, PAUSE, UNTIL, mobileWidth } from '../../constants/AppVarConstants';
import SvgIconWrapper from '../common/SvgIcon';
import type { SubscriptionType } from '../../types/common';
import { H3, H5, SUBTITLE2 } from '../common/Typography';
import { useDispatch } from 'react-redux';
import { updateSubscription } from '../../redux/actions/subscriptionActions';
import { setSuccessDialogInfo } from '../../redux/actions/appActions';
import { capitalizeWord } from '../../commonFunctions/utils';

interface SubscriptionPauseDialogProps {
  open: boolean
  makeApiCall?: boolean
  subscriptionInfo?: SubscriptionType | null
  onClose: () => void
  onEdit?: () => void
  fullWidth?: boolean
}

const SubscriptionPauseDialog: React.FC<SubscriptionPauseDialogProps> = (props): JSX.Element => {
  const {
    open = false,
    subscriptionInfo = null,
    onClose = () => { },
    fullWidth = false
  } = props;

  const { palette: { error, text } } = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(`(${mobileWidth})`);

  const [value, setValue] = useState(UNTIL);
  const [noOfCycles, setNoOfCycles] = useState(1);

  const handleClose = (): void => {
    onClose();
    setValue(UNTIL);
    setNoOfCycles(1);
  };

  const onSubmit = (): void => {
    const payload = {
      subscriptionId: subscriptionInfo?.id,
      state: PAUSE,
      ...(value === CYCLE && {
        cycles: noOfCycles
      })
    };
    dispatch(updateSubscription(payload, () => {
      dispatch(setSuccessDialogInfo({
        title: 'Successfully Updated',
        description: 'Subscription status is successfully updated'
      }));
    }));
    handleClose();
  };

  return (
    <CustomDialog
      open={open}
      onClose={handleClose}
      isMobileScreen={isMobile}
      fullWidth={fullWidth}
      title="Pause Subscription"
      description="Enter the details regarding this pause."
      content={(
        <Form
          mainSX={{ columnSpacing: 3 }}
          fields={[
            {
              label: '',
              labelSX: { variant: 'h5', lineHeight: 1.8 },
              gridSX: { xs: 12, md: 12 },
              component: (
                <Box display="flex" flexDirection="column" gap={3} pb={2}>
                  <H3>How would you like to pause this subscription?</H3>
                  <Box>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={value}
                      onChange={(event) => { setValue(event.target.value); }}
                    >
                      <FormControlLabel value={UNTIL} control={<Radio />} label="Pause until I resume it" />
                      <FormControlLabel value={CYCLE} control={<Radio />} label="Pause for a number of subscription cycles" />
                      {value === CYCLE && <Box display="flex" flexDirection="column" gap={1} py={2}>
                        <Box display="flex" alignItems="center" gap={1}>
                          <H5>Pause for</H5>
                          <TextField
                            value={noOfCycles}
                            onChange={(event) => {
                              const num = Number(event.target.value);
                              if (num > 0) {
                                setNoOfCycles(num);
                              }
                            }}
                            size='small'
                            type='number'
                            sx={{
                              width: 70,
                              '.MuiOutlinedInput-root': { bgcolor: 'common.white', color: 'text.secondary' }
                            }}
                          />
                          <H5>Cycles</H5>
                        </Box>
                        <SUBTITLE2 color={text.secondary} fontWeight={400} fontSize={12}>
                          {`This subscription cycles ${capitalizeWord(subscriptionInfo?.interval ?? '')}`}
                        </SUBTITLE2>
                      </Box>}
                    </RadioGroup>
                  </Box>
                  <Box display="flex" gap={2}>
                    <SvgIconWrapper name='Alert' size={16} color='error' />
                    <SUBTITLE2 color={error.main} fontWeight={400} fontSize={12}>
                      Please note, payments that have already been created from this subscription will still be collected until you also cancel them.
                    </SUBTITLE2>
                  </Box>
                </Box>
              )
            }
          ]}
        />
      )}
      leftButton={(
        <Button
          startIcon={(
            <SvgIconWrapper
              name="Cancel"
              size={16}
            />
          )}
          size="large"
          variant="outlined"
          color="error"
          onClick={handleClose}
        >
          Cancel
        </Button>
      )}
      rightButton={(
        <Button
          startIcon={(
            <SvgIconWrapper
              name="Check"
              size={16}
            />
          )}
          variant="contained"
          size="large"
          onClick={onSubmit}
        >
          Pause Subscription
        </Button>
      )}
    />
  );
};

export default SubscriptionPauseDialog;
