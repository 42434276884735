export const BEGIN_AJAX_CALL = 'BEGIN_AJAX_CALL';
export const END_AJAX_CALL = 'END_AJAX_CALL';
export const AJAX_CALL_ERROR = 'AJAX_CALL_ERROR';
export const AJAX_CALL_CLEAR = 'AJAX_CALL_CLEAR';

export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
export const SET_SUCCESS_MESSAGE = 'SET_SUCCESS_MESSAGE';
export const SET_LOADING_STATE = 'SET_LOADING_STATE';

export const SET_SUCCESS_DIALOG_INFO = 'SET_SUCCESS_DIALOG_INFO';
export const RESET_SUCCESS_DIALOG_INFO = 'RESET_SUCCESS_DIALOG_INFO';

export const SET_USER_INFO = 'SET_USER_INFO';
export const SET_USER_ROLE = 'SET_USER_ROLE';
export const SET_USER_PAYMENT_INFO = 'SET_USER_PAYMENT_INFO';

// Calender Action Types
export const SET_ALL_SESSIONS_DATA = 'SET_ALL_SESSIONS_DATA';
export const SET_CALENDAR_EVENTS = 'SET_CALENDAR_EVENTS';
export const SET_SESSION_TYPES = 'SET_SESSION_TYPES';
export const REFETCH_ALL_SESSION = 'REFETCH_ALL_SESSION';
export const SET_CAL_FILTER_CLIENT_DATA = 'SET_CAL_FILTER_CLIENT_DATA';
export const SET_CAL_FILTER_SESSIONTYPE = 'SET_CAL_FILTER_SESSIONTYPE';
export const RESET_CAL_FILTER_DATA = 'RESET_CAL_FILTER_DATA';
export const TOGGLE_CAL_FILTER_STATE = 'TOGGLE_CAL_FILTER_STATE';

// Clients Action Types
export const SET_CLIENTS = 'SET_CLIENTS';
export const REFETCH_CLIENTS_LIST = 'REFETCH_CLIENTS_LIST';

export const SET_REGISTRATION_STEP_ONE = 'SET_REGISTRATION_STEP_ONE';
export const SET_REGISTRATION_STEP_TWO = 'SET_REGISTRATION_STEP_TWO';

export const RESET_REGISTRATION_STEP_ONE = 'RESET_REGISTRATION_STEP_ONE';

// Package Action Types
export const SET_PACKAGE_LIST = 'SET_PACKAGE_LIST';
export const SET_PACKAGE_OPTION_LIST = 'SET_PACKAGE_OPTION_LIST';
export const REFETCH_PACKAGES_LIST = 'REFETCH_PACKAGES_LIST';

// Subscription Action Types
export const SET_SUBSCRIPTIONS_DATA = 'SET_SUBSCRIPTIONS_DATA';
export const SET_CLIENT_OPTIONS = 'SET_CLIENT_OPTIONS';
export const REFETCH_SUBSCRIPTIONS = 'REFETCH_SUBSCRIPTIONS';

// Dashboard Action Types
export const SET_SESSION_CARDS_DETAILS = 'SET_SESSION_CARDS_DETAILS';
export const SET_CLIENTS_STATISTICS_DATA = 'SET_CLIENTS_STATISTICS_DATA';
export const SET_DASHBOARD_LIST_CLIENTS_DATA = 'SET_DASHBOARD_LIST_CLIENTS_DATA';
export const SET_SESSIONS_GRAPH_DATA = 'SET_SESSIONS_GRAPH_DATA';
export const SET_COMPLIMENTARY_SESSION_DATA = 'SET_COMPLIMENTARY_SESSION_DATA';
export const REFETCH_DASHBOARD_DATA = 'REFETCH_DASHBOARD_DATA';
