import initialState from './initialState';
import * as types from '../actions/actionTypes';
import type { AppState } from '../../types/reduxState';

interface appActions {
  type: string
  payload: any
}

export default function appReducer (currentState: AppState, action: appActions): any {
  const state = currentState || initialState.app;
  switch (action.type) {
    case types.SET_SUCCESS_MESSAGE:
      return {
        ...state,
        successMessage: action.payload
      };

    case types.SET_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload
      };

    case types.SET_LOADING_STATE:
      return {
        ...state,
        loader: action.payload
      };

    case types.SET_SUCCESS_DIALOG_INFO:
      return {
        ...state,
        successDialogInfo: action.payload
      };

    case types.SET_USER_INFO:
      return {
        ...state,
        user: action.payload
      };

    case types.SET_REGISTRATION_STEP_ONE:
      return {
        ...state,
        registrationData: {
          ...state.registrationData,
          step1: action.payload
        }
      };

    case types.RESET_REGISTRATION_STEP_ONE:
      return {
        ...state,
        registrationData: {
          step1: {
            firstName: '',
            lastName: '',
            password: '',
            confirmPassword: '',
            phone: [{
              isoCode: '',
              phoneNumber: '',
              dialCode: ''
            }],
            email: ''
          }
        }
      };

    case types.RESET_SUCCESS_DIALOG_INFO:
      return {
        ...state,
        successDialogInfo: {
          title: '',
          description: ''
        }
      };

    case types.SET_USER_PAYMENT_INFO:
      return {
        ...state,
        paymentInfo: action.payload
      };

    case types.SET_USER_ROLE:
      return {
        ...state,
        userRole: action.payload
      };

    default:
      return state;
  }
}
