import API_MIDDLEWARE from '../api/middleware';
import { GET_ALL_SESSION_URL, GET_DASHBOARD_METRICS_URL } from '../api/config';
import { REFETCH_DASHBOARD_DATA, SET_CLIENTS_STATISTICS_DATA, SET_COMPLIMENTARY_SESSION_DATA, SET_DASHBOARD_LIST_CLIENTS_DATA, SET_SESSIONS_GRAPH_DATA, SET_SESSION_CARDS_DETAILS } from './actionTypes';
import { beginAjaxCall, endAjaxCall } from './ajaxStatusActions';
import { setErrorMessage, setLoadingState } from './appActions';
import { APP_CUSTOM_ERROR } from '../../constants/MessageConstants';
import { getDashBoardMonthOptions, getDatesAtWeeklyInterval, getLastDateOfMonth, getMinuteDifference, getTotalDatesInMonth, isDateInCurrentMonth, isDateInRange } from '../../commonFunctions/utils';
import { isEmpty } from 'lodash';
import { CANCELLED, FAILED, FINISHED, PAID_OUT, RESCHEDULED } from '../../constants/AppVarConstants';
import { RRule, RRuleSet } from 'rrule';
import type { ExceptionSession } from '../../types/common';
import dayjs from 'dayjs';

export const setBookedSessionsData = (payload: any): any => {
  return { type: SET_SESSIONS_GRAPH_DATA, payload };
};

export const setComplimentarySessionsData = (payload: any): any => {
  return { type: SET_COMPLIMENTARY_SESSION_DATA, payload };
};

export const setClientsStatisticsData = (payload: any): any => {
  return { type: SET_CLIENTS_STATISTICS_DATA, payload };
};

export const setSessionCardsDetails = (payload: any): any => {
  return { type: SET_SESSION_CARDS_DETAILS, payload };
};

export const setDashboardClients = (payload: any): any => {
  return { type: SET_DASHBOARD_LIST_CLIENTS_DATA, payload };
};

export const refetchDashboardData = (): any => {
  return { type: REFETCH_DASHBOARD_DATA };
};

export const filterDataByMonth = (sessionArr: any = [], currentMonth: string): any => {
  return (dispatch: any) => {
    try {
      dispatch(setLoadingState(true));
      let totalSession = 0;
      let totalDuration = 0;
      let totalIncome = 0;
      const paymentSet = new Map();
      const subscriptionSet = new Map();
      const sessionData: any = [];
      const stDate = dayjs(currentMonth).subtract(1, 'day').hour(23).minute(59).second(59).toString();
      const endDtate = getLastDateOfMonth(currentMonth);
      const totalDays = getTotalDatesInMonth(currentMonth);
      for (let index = 1; index <= totalDays; index++) {
        sessionData.push([`${index}`, 0]);
      }
      sessionArr.forEach((item: any) => {
        if (item?.recurrence) {
          const ruleSet = new RRuleSet();
          const rule = RRule.fromString(item.recurrence);
          ruleSet.rrule(rule);
          if (!isEmpty(item?.exceptions)) {
            item?.exceptions?.forEach((expSession: ExceptionSession) => {
              if (expSession?.prevStart) {
                ruleSet.exdate(new Date(expSession.prevStart));
              } else if (expSession.type === CANCELLED) {
                ruleSet.exdate(new Date(expSession.start));
              }
              if (expSession.type === RESCHEDULED && isDateInRange(expSession.start, stDate, endDtate)) {
                const duration = getMinuteDifference(expSession.start, expSession.end);
                const day = dayjs(expSession.start).get('date') - 1;
                sessionData[day][1] = Number(sessionData[day][1]) + 1;
                totalSession += 1;
                totalDuration += duration;
              }
            });
          }
          if (item?.subscriptionId?.payments && !subscriptionSet.has(item?.subscriptionId?.id)) {
            subscriptionSet.set(item?.subscriptionId?.id, item?.subscriptionId?.interval);
            const payableAmmount: number = item?.subscriptionId?.amount;
            let paymDates = getDatesAtWeeklyInterval(item?.subscriptionId?.start, endDtate);
            const paymentArr = item?.subscriptionId?.payments;
            paymentArr?.forEach((data: any) => {
              if ((data?.status === PAID_OUT) && isDateInRange(data?.chargeDate, stDate, endDtate)) {
                if (!paymentSet.has(data?.id)) {
                  paymentSet.set(data?.id, data?.amount);
                }
              } else if ((data?.status !== PAID_OUT) && (item?.subscriptionId?.interval !== 'WEEKLY') && isDateInRange(data?.chargeDate, stDate, endDtate)) {
                if (!paymentSet.has(data?.id)) {
                  paymentSet.set(data?.id, data?.amount);
                }
              }
              if ((data?.status === FAILED || data?.status === PAID_OUT)) {
                paymDates = paymDates.filter((chgDate) => chgDate !== data?.chargeDate);
              }
            });
            if (item?.subscriptionId?.status !== CANCELLED && item?.subscriptionId?.status !== FINISHED && isDateInCurrentMonth(currentMonth) && item?.subscriptionId?.interval === 'WEEKLY') {
              const noOfDays = paymDates.length;
              if (noOfDays && payableAmmount) {
                totalIncome += noOfDays * payableAmmount;
              }
            }
          }
          const sessionDates = ruleSet.between(new Date(stDate), new Date(endDtate));
          sessionDates.forEach((date: any) => {
            const day = dayjs(date).get('date') - 1;
            sessionData[day][1] = Number(sessionData[day][1]) + 1;
          });
          totalSession += sessionDates.length;
          if (sessionDates.length) {
            const sessionDt = sessionDates.length * item?.durationInMinutes;
            totalDuration += sessionDt;
          }
        } else {
          let start = item?.start;
          if (isDateInRange(item?.start, stDate, endDtate)) {
            if (!isEmpty(item?.exceptions)) {
              const updatedSession = item.exceptions?.[0];
              if (updatedSession?.type !== CANCELLED) {
                const duration = getMinuteDifference(updatedSession?.start, updatedSession?.end);
                totalSession += 1;
                start = updatedSession?.start;
                const day = dayjs(start).get('date') - 1;
                sessionData[day][1] = Number(sessionData[day][1]) + 1;
                totalDuration += duration;
              }
            } else {
              totalSession += 1;
              const day = dayjs(start).get('date') - 1;
              sessionData[day][1] = Number(sessionData[day][1]) + 1;
              totalDuration += Number(item?.durationInMinutes);
            }
          }
        }
      });
      paymentSet.forEach((val: number, key: string) => {
        totalIncome += val;
      });
      const durationInHrs = totalDuration / 60;
      const income = totalIncome / 100;
      const hourlyRate = durationInHrs ? (income / durationInHrs) : 0;
      dispatch(setBookedSessionsData(sessionData));
      dispatch(setSessionCardsDetails({
        sessions: totalSession,
        totalIncome: income.toFixed(2),
        hourlyRate: hourlyRate.toFixed(2),
        dailyEaring: (income / totalDays).toFixed(2)
      }));
      dispatch(setLoadingState(false));
    } catch (error: any) {
      dispatch(setErrorMessage(error?.message ?? APP_CUSTOM_ERROR));
    }
  };
};

export const calculateIncome = (sessionArr: any = [], stDate: string, endDtate: string): number => {
  let totalIncome = 0;
  const paymentSet = new Map();
  const subscriptionSet = new Map();
  sessionArr.forEach((item: any) => {
    if (item?.recurrence) {
      const ruleSet = new RRuleSet();
      const rule = RRule.fromString(item.recurrence);
      ruleSet.rrule(rule);
      if (item?.subscriptionId?.payments && !subscriptionSet.has(item?.subscriptionId?.id)) {
        subscriptionSet.set(item?.subscriptionId?.id, item?.subscriptionId?.interval);
        const paymentArr = item?.subscriptionId?.payments;
        paymentArr?.forEach((data: any) => {
          if ((data?.status === PAID_OUT) && isDateInRange(data?.chargeDate, stDate, endDtate)) {
            if (!paymentSet.has(data?.id)) {
              paymentSet.set(data?.id, data?.amount);
            }
          }
        });
      }
    }
  });
  paymentSet.forEach((val: number, key: string) => {
    totalIncome += val;
  });
  return totalIncome / 100;
};

export const getComplimentarySessionData = (
  onSuccess = () => { },
  onError = () => { }
): any => {
  return (dispatch: any) => {
    dispatch(beginAjaxCall());
    dispatch(setLoadingState(true));
    API_MIDDLEWARE(
      GET_ALL_SESSION_URL,
      'POST',
      { 'Content-Type': 'application/json' },
      { clientId: '' }
    )
      .then((res) => {
        const months = getDashBoardMonthOptions();
        months?.shift();
        const chartData: any = [];
        const complimentarySession = res.results.filter((item: any) => item?.type?.type !== 'Paid');
        months?.forEach((month: any) => {
          let totalSession = 0;
          const stDate = dayjs(month.value).subtract(1, 'day').hour(23).minute(59).second(59).toString();
          const endDtate = getLastDateOfMonth(month.value);
          complimentarySession.forEach((data: any) => {
            if (data?.recurrence) {
              const ruleSet = new RRuleSet();
              const rule = RRule.fromString(data.recurrence);
              ruleSet.rrule(rule);
              if (!isEmpty(data?.exceptions)) {
                data?.exceptions?.forEach((expSession: ExceptionSession) => {
                  if (expSession?.prevStart) {
                    ruleSet.exdate(new Date(expSession.prevStart));
                  } else if (expSession.type === CANCELLED) {
                    ruleSet.exdate(new Date(expSession.start));
                  }
                  if (expSession.type === RESCHEDULED && isDateInRange(expSession.start, stDate, endDtate)) {
                    totalSession += 1;
                  }
                });
              }
              const sessionDates = ruleSet.between(new Date(stDate), new Date(endDtate));
              totalSession += sessionDates.length;
            } else {
              if (isDateInRange(data?.start, stDate, endDtate)) {
                if (!isEmpty(data?.exceptions)) {
                  const updatedSession = data.exceptions?.[0];
                  if (updatedSession?.type !== CANCELLED) {
                    totalSession += 1;
                  }
                } else {
                  totalSession += 1;
                }
              }
            }
          });
          chartData.push([
            dayjs(month.value).format('MMM'),
            totalSession
          ]);
        });
        dispatch(setComplimentarySessionsData(chartData));
        dispatch(endAjaxCall());
        dispatch(setLoadingState(false));
        onSuccess();
      })
      .catch((err) => {
        onError();
        dispatch(setLoadingState(false));
        dispatch(setErrorMessage(err.errorMsg ?? APP_CUSTOM_ERROR));
        dispatch(endAjaxCall());
      });
  };
};

export const fetchDashboardMetricsData = (
  onSuccess = () => { },
  onError = () => { }
): any => {
  return (dispatch: any) => {
    dispatch(beginAjaxCall());
    dispatch(setLoadingState(true));
    API_MIDDLEWARE(
      GET_DASHBOARD_METRICS_URL,
      'GET',
      { 'Content-Type': 'application/json' }
    )
      .then((res) => {
        dispatch(setClientsStatisticsData(res.result));
        dispatch(endAjaxCall());
        dispatch(setLoadingState(false));
        onSuccess();
      })
      .catch((err) => {
        onError();
        dispatch(setLoadingState(false));
        dispatch(setErrorMessage(err.errorMsg ?? APP_CUSTOM_ERROR));
        dispatch(endAjaxCall());
      });
  };
};
