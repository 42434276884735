import React, { useState } from 'react';
import { Box, IconButton, ListItemText, MenuItem, useMediaQuery, useTheme } from '@mui/material';
import { ACTIVE, CANCELLED, FINISHED, mobileWidth } from '../../constants/AppVarConstants';
import { H5 } from '../common/Typography';
import SvgIconWrapper from '../common/SvgIcon';
import CustomMenu from '../common/CustomMenu';
import type { SubscriptionType } from '../../types/common';
import { capitalizeWord, formatDate } from '../../commonFunctions/utils';

interface SubscriptionCardProps {
  subscriptionData: SubscriptionType
  onEdit?: (data: SubscriptionType) => void
  onUpdateStatus?: (data: SubscriptionType) => void
  onPauseClick?: (data: SubscriptionType) => void
  onCancelSubscription?: (data: SubscriptionType) => void
}

const SubscriptionCard: React.FC<SubscriptionCardProps> = (props): JSX.Element => {
  const {
    subscriptionData,
    onEdit = () => { },
    onUpdateStatus = () => { },
    onPauseClick = () => { },
    onCancelSubscription = () => { }
  } = props;

  const { palette: { text, outline, primary } } = useTheme();
  const isMobile = useMediaQuery(`(${mobileWidth})`);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { client, start, end, status, interval, package: { name } } = subscriptionData;

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        color: text.secondary
      }}
    >
      <Box
        sx={{
          py: 2.5,
          flex: 1.5,
          minWidth: 200,
          borderBottom: `1px solid ${outline.main}`,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        <H5 pl={isMobile ? 1 : 4}>{client.fullName}</H5>
      </Box>
      <Box
        sx={{
          py: 2.5,
          flex: 1.5,
          minWidth: 160,
          textAlign: 'center',
          borderBottom: `1px solid ${outline.main}`,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        <H5>{name}</H5>
      </Box>
      <Box py={2.5} flex={1} minWidth={180} textAlign="center" borderBottom={`1px solid ${outline.main}`}>
        <H5>{capitalizeWord(status)}</H5>
      </Box>
      <Box py={2.5} flex={1} minWidth={160} textAlign="center" borderBottom={`1px solid ${outline.main}`}>
        <H5>{capitalizeWord(interval)}</H5>
      </Box>
      <Box py={2.5} flex={1} minWidth={160} textAlign="center" borderBottom={`1px solid ${outline.main}`}>
        <H5>{formatDate(start)}</H5>
      </Box>
      <Box py={2.5} flex={1} minWidth={160} textAlign="center" borderBottom={`1px solid ${outline.main}`}>
        <H5>{formatDate(end)}</H5>
      </Box>
      <Box
        sx={{
          py: 2,
          flex: 1,
          textAlign: 'center',
          background: 'white',
          borderBottom: ({ palette: { outline } }) => `1px solid ${outline.main}`,
          ...(isMobile && {
            position: 'sticky',
            borderLeft: ({ palette: { outline } }) => `1px solid ${outline.main}`,
            minWidth: 80,
            right: 0
          })
        }}
      >
        <Box pr={isMobile ? 1 : 4}>
          <IconButton disabled={status === CANCELLED || status === FINISHED} onClick={handleOpenMenu}>
            <SvgIconWrapper name='More' size={28} color={(status === CANCELLED || status === FINISHED) ? 'disabled' : 'primary'} />
          </IconButton>
          <CustomMenu
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            onClose={() => { setAnchorEl(null); }}
          >
            <MenuItem sx={{ px: 2 }}>
              <ListItemText
                onClick={() => {
                  onEdit(subscriptionData);
                  setAnchorEl(null);
                }}
                primary="Edit Amount"
                sx={{ color: primary.main }}
              />
            </MenuItem>
            <MenuItem sx={{ px: 2 }}>
              <ListItemText
                onClick={() => {
                  onCancelSubscription(subscriptionData);
                  setAnchorEl(null);
                }}
                primary="Cancel"
                sx={{ color: primary.main }}
              />
            </MenuItem>
            <MenuItem sx={{ px: 2 }}>
              <ListItemText
                onClick={() => {
                  if (status === ACTIVE) {
                    onPauseClick(subscriptionData);
                  }
                  onUpdateStatus(subscriptionData);
                  setAnchorEl(null);
                }}
                primary={status === ACTIVE ? 'Pause' : 'Resume'}
                sx={{ color: primary.main }} />
            </MenuItem>
          </CustomMenu>
        </Box>
      </Box>
    </Box>
  );
};

export default SubscriptionCard;
