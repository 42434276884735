import { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { Box, MenuItem, Select, useMediaQuery, useTheme } from '@mui/material';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import { RRule } from 'rrule';
import CustomToolbar from './Toolbar';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import './index.css';
import SessionDialog from './SessionDialog';
import CancelSessionDialog from './CancelSession';
import EventDetails from './EventDetails';
import RequestToCancelSession from './RequestCancelSession';
import type { EventType, ExceptionSession } from '../../types/common';
import type { RootState } from '../../types/reduxState';
import ConfirmationDialog from '../common/ConfirmationDialog';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetails, setSuccessDialogInfo } from '../../redux/actions/appActions';
import { CANCELLED, RESCHEDULED, mobileWidth } from '../../constants/AppVarConstants';
import { addNewSession, createExceptionSession, fetchAllEvents, filterEventsbyMonth, getSessionTypes, refetchAllSession, requestCancelSession, setCalendarEvents, setSessionsAllData, updateExceptionSession, updateSession } from '../../redux/actions/calendarAction';
import { checkForPastDate, getCurrentTimeZome, getEndDate, getUntilDate } from '../../commonFunctions/utils';
import SvgIconWrapper from '../common/SvgIcon';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';

const Calendar = (): JSX.Element => {
  const theme = useTheme();
  const params = useParams();
  const dispatch = useDispatch();
  const { allSessionsData, events, reFetchEvents, openFilter, filters } = useSelector((state: RootState) => state.calendarState);
  const { user } = useSelector((state: RootState) => state.app.user);
  const isMobile = useMediaQuery(`(${mobileWidth})`);
  const currentTimeZone = getCurrentTimeZome();

  const { outline, primary } = theme.palette;
  const [openEvent, setOpenEvent] = useState(false);
  const [cancelEvent, setCancelEvent] = useState(false);
  const [editEvent, setEditEvent] = useState(false);
  const [requestCancel, setRequestCancel] = useState(false);
  const [eventDetails, setEventDetails] = useState<EventType | null>(null);
  const [confirmEventEdit, setconfirmEventEdit] = useState(false);
  const [confirmRequestCancel, setconfirmRequestCancel] = useState(false);
  const [confirmCancelSession, setconfirmCancelSession] = useState(false);
  const [sessionPayload, setSessionPayload] = useState<any | null>(null);
  const [exceptionPayload, setExceptionPayload] = useState<any | null>(null);
  const [currentMonth, setCurrentMonth] = useState<Date>(new Date());
  const [sessionConsentPopup, setSessionConsentPopup] = useState(false);
  const [startDate, setStartDate] = useState<any>();
  const [selectEdit, setSelectEdit] = useState('SINGLE');
  const [timeZone, setTimeZone] = useState('');

  useEffect(() => {
    if (params?.userId) {
      dispatch(getUserDetails(params?.userId ?? '', (data) => {
        setTimeZone(data?.timezone ?? currentTimeZone);
        moment.tz.setDefault(data?.timezone ?? currentTimeZone);
      }));
    } else {
      dispatch(getSessionTypes());
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(user)) {
      moment.tz.setDefault(user?.zoneId ?? currentTimeZone ?? 'Europe/London');
      setTimeZone(user?.zoneId);
    }
  }, [user]);

  const localizer = momentLocalizer(moment);

  useEffect(() => {
    dispatch(fetchAllEvents({
      clientId: filters.clientId,
      typeId: filters.sessionId,
      id: params?.userId ?? user?.id ?? ''
    }));

    return () => {
      dispatch(setSessionsAllData([]));
      dispatch(setCalendarEvents([]));
    };
  }, [reFetchEvents, filters.clientId, filters.sessionId]);

  useEffect(() => {
    if (!isEmpty(allSessionsData)) {
      dispatch(filterEventsbyMonth(allSessionsData, currentMonth));
    }
  }, [currentMonth, allSessionsData]);

  const handleEvents = (event?: any): void => {
    if (event) {
      setEventDetails(event);
      if (event?.recurrence) {
        const originalRULE = RRule.fromString(event.recurrence);
        const date = originalRULE.origOptions.dtstart;
        setStartDate(date);
      }
    }
    setOpenEvent((prev) => !prev);
  };

  const handleRequestCancel = (): void => {
    setRequestCancel((prev) => !prev);
  };

  const eventStyleGetter = (event: any): object => {
    const style: React.CSSProperties = {
      backgroundColor: event.color,
      color: '#FFFFFF',
      borderRadius: '4px',
      border: '1px solid #fff'
    };
    return {
      style
    };
  };

  const eidtSession = (): void => {
    let payload = {};
    if (eventDetails?.recurrence && sessionPayload?.updateAll) {
      dispatch(updateSession({
        sessionId: eventDetails?.sessionId,
        durationInMinutes: Number(sessionPayload?.duration),
        recurrence: sessionPayload.recurrence
      }, () => {
        dispatch(refetchAllSession());
        dispatch(setSuccessDialogInfo({
          title: 'Successfully Updated',
          description: 'Changes are successfully updated.'
        }));
      }));
    } else if (eventDetails?.recurrence && sessionPayload?.updateUpcoming) {
      const originalRULE = RRule.fromString(eventDetails.recurrence);
      const endDate = getUntilDate(eventDetails.start);
      const count = originalRULE.origOptions.count;
      if (count) {
        originalRULE.origOptions.count = null;
      }
      originalRULE.origOptions.until = endDate;
      payload = {
        clientId: eventDetails.clientId,
        userId: user?.id,
        sessionTypeId: Number(sessionPayload?.sessionType),
        start: sessionPayload?.dateTime,
        timezone: user?.timezone ?? 'Europe/London',
        durationInMinutes: Number(sessionPayload?.duration),
        recurrence: sessionPayload.recurrence,
        subscriptionId: sessionPayload?.subscriptionId
      };
      dispatch(updateSession({
        sessionId: eventDetails?.sessionId,
        recurrence: originalRULE.toString()
      }, () => {
        dispatch(addNewSession(payload, () => {
          dispatch(setSuccessDialogInfo({
            title: 'Successfully Updated',
            description: 'Changes are successfully updated.'
          }));
        }));
      }));
    } else if (sessionPayload?.createException) {
      payload = {
        userId: user?.id,
        sessionId: eventDetails?.sessionId,
        clientId: eventDetails?.clientId,
        timezone: user?.timezone ?? 'Europe/London',
        type: RESCHEDULED,
        prevStart: eventDetails?.start,
        prevEnd: eventDetails?.end,
        start: sessionPayload?.dateTime,
        end: getEndDate(sessionPayload?.dateTime, Number(sessionPayload?.duration)),
        reason: RESCHEDULED
      };
      dispatch(createExceptionSession(payload, () => {
        dispatch(setSuccessDialogInfo({
          title: 'Successfully Updated',
          description: 'Changes are successfully updated.'
        }));
      }));
    } else {
      payload = {
        exceptionId: eventDetails?.exceptionId,
        type: RESCHEDULED,
        start: sessionPayload?.dateTime,
        end: getEndDate(sessionPayload?.dateTime, Number(sessionPayload?.duration))
      };
      dispatch(updateExceptionSession(payload, () => {
        dispatch(setSuccessDialogInfo({
          title: 'Successfully Updated',
          description: 'Changes are successfully updated.'
        }));
      }));
    }
    setconfirmEventEdit(false);
  };

  const handleCancelSession = (data: { reason: string, cancelAll: boolean }): void => {
    if (eventDetails) {
      const payload: ExceptionSession = {
        userId: user?.id,
        sessionId: eventDetails?.sessionId,
        type: CANCELLED,
        start: eventDetails?.start,
        end: eventDetails?.end,
        reason: data.reason,
        updateException: !!eventDetails?.exceptionId,
        allOccurrences: data.cancelAll
      };
      setExceptionPayload(payload);
      setconfirmCancelSession(true);
    }
  };

  const cancelSession = (): void => {
    setconfirmCancelSession(false);
    if (exceptionPayload?.allOccurrences && eventDetails?.recurrence) {
      const originalRULE = RRule.fromString(eventDetails.recurrence);
      const endDate = getUntilDate(eventDetails?.start);
      originalRULE.origOptions.until = endDate;
      const payload = {
        sessionId: eventDetails?.sessionId,
        recurrence: originalRULE.toString(),
        sessionTypeId: eventDetails.sessionTypeId,
        start: eventDetails.start,
        allOccurrences: exceptionPayload?.allOccurrences
      };
      dispatch(updateSession(payload, () => {
        dispatch(setSuccessDialogInfo({
          title: 'Successfully Cancelled',
          description: 'Your session has been cancelled Successfully.'
        }));
        dispatch(refetchAllSession());
      }));
    } else if (exceptionPayload?.updateException) {
      const payload = {
        clientId: eventDetails?.clientId,
        timezone: user?.timezone ?? 'Europe/London',
        exceptionId: eventDetails?.exceptionId,
        type: CANCELLED
      };
      dispatch(updateExceptionSession(payload, () => {
        dispatch(setSuccessDialogInfo({
          title: 'Successfully Cancelled',
          description: 'Your session has been cancelled Successfully.'
        }));
      }));
    } else {
      dispatch(createExceptionSession(exceptionPayload, () => {
        dispatch(setSuccessDialogInfo({
          title: 'Successfully Cancelled',
          description: 'Your session has been cancelled Successfully.'
        }));
      }));
    }
  };

  const handleRequestCancelSession = (): void => {
    const payload = {
      ...sessionPayload,
      timezone: currentTimeZone
    };
    dispatch(requestCancelSession(params?.userId ?? '', payload, () => {
      dispatch(setSuccessDialogInfo({
        title: 'Success!',
        description: 'Your request was submitted successfully. The trainer will reach out to confirm or reschedule.'
      }));
      setconfirmRequestCancel(false);
    }));
  };

  return (
    <Box
      sx={{
        height: '82vh'
      }}
    >
      <BigCalendar
        popup
        localizer={localizer}
        events={events}
        startAccessor="start"
        defaultView="month"
        endAccessor="end"
        views={['month', 'week', 'day']}
        selectable
        // onSelectSlot={handleSlotSelected}
        showMultiDayTimes
        onSelectEvent={handleEvents}
        eventPropGetter={eventStyleGetter}
        scrollToTime={moment().hour(5).minute(45).toDate()}
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          background: '#fff',
          ...(!isMobile && {
            border: `1px solid ${outline.main}`,
            borderRadius: '25px',
            overflow: 'hidden'
          }),
          ...(isMobile && {
            borderBottom: `1px solid ${outline.main}`
          })
        }}
        components={{
          toolbar: (props) => (
            <CustomToolbar
              {...props}
              openFilter={openFilter}
              isMobile={isMobile}
              currentMonth={currentMonth}
              setCurrentMonth={setCurrentMonth}
              timeZone={timeZone}
            />
          )
        }}
      />
      <SessionDialog
        eventData={eventDetails}
        open={editEvent}
        onClose={() => {
          setEditEvent(false);
          setSelectEdit('SINGLE');
        }}
        updateAll={selectEdit === 'ALL'}
        updateUpcoming={selectEdit === 'UPCOMING'}
        onEdit={(data) => {
          setSessionPayload(data);
          setEditEvent(false);
          setconfirmEventEdit(true);
        }}
      />
      <CancelSessionDialog
        eventData={eventDetails}
        open={cancelEvent}
        onClose={() => { setCancelEvent(false); }}
        handleCancelSession={handleCancelSession}
        resetState={reFetchEvents}
      />
      <RequestToCancelSession
        open={requestCancel}
        eventData={eventDetails}
        onClose={handleRequestCancel}
        handleRightClick={(data) => {
          setSessionPayload(data);
          setconfirmRequestCancel(true);
        }}
      />
      <EventDetails
        eventData={eventDetails}
        open={openEvent}
        onClose={handleEvents}
        onCancel={() => { setCancelEvent(true); }}
        onEdit={() => {
          setEditEvent(true);
        }}
        setConsentPopup={setSessionConsentPopup}
        onCancelRequest={() => { setRequestCancel(true); }}
      />
      <ConfirmationDialog
        open={confirmEventEdit}
        edit
        title='Edit Session'
        description='Are you sure that you’d like to edit this session?'
        onClose={() => { setconfirmEventEdit(false); }}
        onLeftClick={() => { setEditEvent(true); }}
        onClick={eidtSession}
      />
      <ConfirmationDialog
        open={confirmRequestCancel}
        warning
        title='Request Cancellation'
        description='Are you sure that you’d like to request to cancel this session? An email notification will be sent to your trainer.'
        onClose={() => { setconfirmRequestCancel(false); }}
        onLeftClick={() => {
          setconfirmRequestCancel(false);
          setRequestCancel(true);
        }}
        onClick={handleRequestCancelSession}
      />
      <ConfirmationDialog
        open={confirmCancelSession}
        deleteIcon
        title='Cancel Session'
        description='Are you sure you want to cancel this session?'
        onLeftClick={() => {
          setCancelEvent(true);
          setconfirmCancelSession(false);
        }}
        onClose={() => { setconfirmCancelSession(false); }}
        onClick={cancelSession}
      />
      <ConfirmationDialog
        open={sessionConsentPopup}
        edit
        maxWidth='xs'
        title='Edit Session'
        description='Do you want to edit only this session?'
        onClose={() => { setSessionConsentPopup(false); }}
        customField={(
          <Select
            value={selectEdit}
            onChange={(e) => { setSelectEdit(e.target.value); }}
            size='small'
            sx={{
              minWidth: 155,
              borderRadius: '25px'
            }}
          >
            <MenuItem value="SINGLE" sx={{ color: primary.main }}>Only This</MenuItem>
            <MenuItem value="UPCOMING" sx={{ color: primary.main }}>This & Following</MenuItem>
            <MenuItem disabled={checkForPastDate(startDate)} value="ALL" sx={{ color: primary.main }}>All Session</MenuItem>
          </Select>
        )}
        rightIcon={<SvgIconWrapper name='Confirm' size={16} />}
        rightButtonLabel='Ok'
        onClick={() => {
          setSessionConsentPopup(false);
          setEditEvent(true);
        }}
      />
    </Box>
  );
};

export default Calendar;
