import { Button } from '@mui/material';
import React from 'react';
import SvgIconWrapper from './SvgIcon';
import CustomDialog from './Dialog';
import MessageContent from './MessageContent';

interface SuccessDialogProps {
  open: boolean
  title?: string
  description?: string
  onClose?: () => void
  onClick?: () => void
};

const SuccessDialog: React.FC<SuccessDialogProps> = (props): JSX.Element => {
  const {
    open = false,
    title = '',
    description = '',
    onClose = () => { },
    onClick = () => { }
  } = props;

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      showDividers
      fullWidth
      maxWidth='xs'
      content={(
        <MessageContent
          title={title}
          body={description}
          success
        />
      )}
      rightButton={(
        <Button
          startIcon={(
            <SvgIconWrapper name="Check" size={16} />
          )}
          variant="contained"
          size="large"
          onClick={onClick}
        >
          Ok
        </Button>
      )}
    />
  );
};

export default SuccessDialog;
