import React, { useEffect, useState } from 'react';
import {
  Box,
  // Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  useTheme
} from '@mui/material';
import { getDashBoardMonthOptions, isDateInCurrentMonth } from '../../commonFunctions/utils';
import dayjs from 'dayjs';
import { SUBTITLE2 } from '../common/Typography';
import { useSelector } from 'react-redux';
import type { RootState } from '../../types/reduxState';

interface DashboardFiltersProps {
  client: string
  setClient: React.Dispatch<React.SetStateAction<string>>
  selectedMonth: string
  setSelectedMonth: React.Dispatch<React.SetStateAction<string>>
}

const DashboardFilters: React.FC<DashboardFiltersProps> = (props): JSX.Element => {
  const {
    client,
    setClient,
    selectedMonth,
    setSelectedMonth
  } = props;

  const { palette: { deepOrange } } = useTheme();
  const { clientsData } = useSelector((state: RootState) => state.dashboardState);

  const [monthOptions, setMonthOptions] = useState<any>(null);

  useEffect(() => {
    const data = getDashBoardMonthOptions();
    const currentMonth = dayjs().date(1).format('YYYY-MM-DD');
    setSelectedMonth(currentMonth);
    setMonthOptions(data);
  }, []);

  return (
    <Box>
      <Grid
        container
        spacing={2}
        sx={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Grid item xs={12} md={6}>
          <Box display="flex" gap={2}>
            <FormControl fullWidth>
              <Select
                size="small"
                value={client}
                onChange={(e) => { setClient(e.target.value); }}
                displayEmpty // This prop ensures that the first item is displayed as the placeholder
                inputProps={{ 'aria-label': 'Select Client' }}
                // disabled
              >
                <MenuItem value="" disabled>
                  Select Client
                </MenuItem>
                <MenuItem value='ALL'>All Clients</MenuItem>
                {clientsData?.map((item) => (
                  <MenuItem
                    key={item.key}
                    value={item.value}
                  >
                    {item.key}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <Select
                size="small"
                value={selectedMonth}
                onChange={(e) => { setSelectedMonth(e.target.value); }}
              >
                {monthOptions?.map((item: { key: string, value: string }) => (
                  <MenuItem
                    key={item.key}
                    value={item.value}
                  >
                    {item.key}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box>
          {
            isDateInCurrentMonth(selectedMonth) && (
              <SUBTITLE2
                sx={{
                  p: 1,
                  borderRadius: '5px',
                  fontWeight: 700,
                  color: deepOrange.main,
                  background: deepOrange.light
                }}
              >
                Projected
              </SUBTITLE2>
            )
          }
          </Box>
          {/* <Button
            startIcon={(
              <SvgIconWrapper size={18} name="Upload" />
            )}
            size="large"
            variant="outlined"
          >
            Export CSV
          </Button> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default DashboardFilters;
