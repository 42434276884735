import { v4 as uuidv4 } from 'uuid';

const LOCAL_UUID = 'app.uuid';

export const setupDeviceId = (): string => {
  const uuid = uuidv4();
  sessionStorage.setItem(LOCAL_UUID, uuid);
  return uuid;
};

export const getDeviceId = (): string => {
  let uuid = sessionStorage.getItem(LOCAL_UUID);
  if (!uuid || uuid === '') {
    uuid = setupDeviceId();
  }
  return uuid;
};
