import React from 'react';
import Drawer from '../common/Drawer';
import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { H2, H3, SUBTITLE1, SUBTITLE2 } from '../common/Typography';
import SvgIconWrapper from '../common/SvgIcon';
import type { Client } from '../../types/common';
import { formatDate, getDaysPassed } from '../../commonFunctions/utils';
import { INACTIVE, mobileWidth } from '../../constants/AppVarConstants';
import { useDispatch } from 'react-redux';
import { setCalFilterClientData, setCalFilterState } from '../../redux/actions/calendarAction';
import { useNavigate } from 'react-router-dom';
import { ROUTE_CALENDAR } from '../../constants/routes';

interface ClientDetailsProps {
  open: boolean
  clientDetails: Client | null
  onClose: () => void
  onOpen?: () => void
  onEdit: () => void
  onCreatePayment: () => void
  onRestore?: (id: string | number) => void
  onDelete?: (id: string | number) => void
  handleArchive?: (id: string | number) => void
};

const ClientDetails: React.FC<ClientDetailsProps> = (props): JSX.Element => {
  const {
    open = false,
    clientDetails = null,
    onClose = () => { },
    onOpen = () => { },
    onEdit = () => { },
    onCreatePayment = () => { },
    onRestore = () => { },
    onDelete = () => { },
    handleArchive = () => { }
  } = props;

  const { palette: { primary } } = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(`(${mobileWidth})`);

  const handleViewAllSessions = (): void => {
    if (clientDetails?.clientId) {
      dispatch(setCalFilterClientData({
        clientId: clientDetails?.clientId,
        clientName: clientDetails?.fullName
      }));
      dispatch(setCalFilterState(true));
      navigate(ROUTE_CALENDAR);
    }
  };

  return (
    <Drawer
      open={open}
      anchor='right'
      onOpen={onOpen}
      onClose={onClose}
    >
      {isMobile && <Box
        sx={{
          boxShadow: (theme) => theme.shadows?.[10]
        }}
      >
        <Box
          sx={{
            p: 3,
            display: 'flex',
            alignItems: 'center',
            background: 'white',
            gap: 2
          }}
        >
          <IconButton onClick={onClose}>
            <SvgIconWrapper size={16} color='primary' name='Backward' />
          </IconButton>
          <H2>Client Details</H2>
        </Box>
      </Box>}
      <Box
        sx={{
          width: { xs: '100vw', md: '30vw' },
          display: 'flex',
          py: 4,
          flexDirection: 'column'
        }}
      >
        <Stack direction="column" spacing={3}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              px: 4,
              gap: 2
            }}
          >
            <H2>{clientDetails?.fullName}</H2>
            <Stack direction="column" spacing={1}>
              <Stack direction="row" spacing={2}>
                <SvgIconWrapper
                  name="Phone"
                  size={16}
                  sx={{
                    color: (theme) => theme.palette.text.secondary
                  }}
                />
                <SUBTITLE1
                  sx={{
                    color: (theme) => theme.palette.text.secondary
                  }}
                >
                  {clientDetails?.phone}
                </SUBTITLE1>
              </Stack>
              <Stack direction="row" spacing={2}>
                <SvgIconWrapper
                  name="Mail"
                  size={16}
                  sx={{
                    color: (theme) => theme.palette.text.secondary
                  }}
                />
                <SUBTITLE1
                  sx={{
                    color: (theme) => theme.palette.text.secondary
                  }}
                >
                  {clientDetails?.email}
                </SUBTITLE1>
              </Stack>
            </Stack>
            {(clientDetails?.status === INACTIVE) &&
              <Box
                sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
              >
                <Box
                  sx={{
                    width: '10px',
                    height: '10px',
                    backgroundColor: (theme) => theme.palette.deepOrange.main,
                    borderRadius: '10px'
                  }}
                />
                <SUBTITLE1
                  sx={{
                    color: (theme) => theme.palette.deepOrange.main,
                    fontWeight: 700
                  }}
                >
                  Archived Client
                </SUBTITLE1>
              </Box>}
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                flexWrap: 'wrap'
              }}
            >
              {(clientDetails?.status !== INACTIVE)
                ? <Button
                  startIcon={(
                    <SvgIconWrapper
                      name="Edit"
                      size={16}
                    />
                  )}
                  size='large'
                  color="primary"
                  variant="outlined"
                  sx={{ flex: 1 }}
                  onClick={() => {
                    onClose();
                    onEdit();
                  }}
                >
                  Edit
                </Button>
                : <Button
                  startIcon={(
                    <SvgIconWrapper
                      name="Repeat"
                      size={16}
                    />
                  )}
                  size='large'
                  color="primary"
                  variant="outlined"
                  sx={{ flex: 1 }}
                  onClick={() => {
                    onClose();
                    if (clientDetails?.clientId) {
                      onRestore(clientDetails.clientId);
                    }
                  }}
                >
                  Restore
                </Button>
              }
              <Button
                startIcon={(
                  <SvgIconWrapper
                    name='View'
                    size={16}
                  />
                )}
                sx={{ flex: 1 }}
                size='large'
                variant="contained"
                onClick={handleViewAllSessions}
              >
                View Sessions
              </Button>
              <Button
                startIcon={(
                  <SvgIconWrapper
                    name='Payment'
                    size={16}
                  />
                )}
                size='large'
                fullWidth
                variant="outlined"
                onClick={() => {
                  onClose();
                  onCreatePayment();
                }}
                disabled={clientDetails?.mandate == null}
              >
                Create one-Off Payment
              </Button>
              {clientDetails?.mandate == null && <Box display="flex" justifyContent="center" alignItems="center" gap={1}>
                <SvgIconWrapper name='Alert' size={16} color='error' />
                <SUBTITLE2 color='error.main' fontSize={10}>This Client hasn&apos;t completed their payment setup</SUBTITLE2>
              </Box>}
            </Box>
          </Box>
          <Divider />
          <Box display="flex" flexDirection="column" px={4}>
            <SUBTITLE1
              sx={{
                fontWeight: 400,
                color: (theme) => theme.palette.text.secondary
              }}
            >
              Birth Date
            </SUBTITLE1>
            <H3>{formatDate(clientDetails?.birthdate)}</H3>
          </Box>
          <Divider />
          <Box display="flex" flexDirection="column" px={4}>
            <SUBTITLE1
              sx={{
                fontWeight: 400,
                color: (theme) => theme.palette.text.secondary
              }}
            >
              Start Date
            </SUBTITLE1>
            <H3>{formatDate(clientDetails?.started)}</H3>
          </Box>
          <Divider />
          <Box display="flex" flexDirection="column" px={4}>
            <SUBTITLE1
              sx={{
                fontWeight: 400,
                color: (theme) => theme.palette.text.secondary
              }}
            >
              Retention
            </SUBTITLE1>
            <H3>{`${getDaysPassed(clientDetails?.started ?? '')} ${getDaysPassed(clientDetails?.started ?? '') <= 1 ? 'Day' : 'Days'}`}</H3>
          </Box>
          <Divider />
          <Box display="flex" flexDirection="column" px={4}>
            <SUBTITLE1
              sx={{
                fontWeight: 400,
                color: (theme) => theme.palette.text.secondary
              }}
            >
              Gender
            </SUBTITLE1>
            <H3>{clientDetails?.gender}</H3>
          </Box>
          <Divider />
          <Box display="flex" flexDirection="column" px={4}>
            <SUBTITLE1
              sx={{
                fontWeight: 400,
                color: (theme) => theme.palette.text.secondary
              }}
            >
              Address
            </SUBTITLE1>
            <H3>{clientDetails?.address?.formattedAddress ?? ''}</H3>
          </Box>
          {clientDetails?.content?.content && <>
            <Divider />
            <Box display="flex" flexDirection="column" px={4}>
              <SUBTITLE1
                sx={{
                  fontWeight: 400,
                  color: (theme) => theme.palette.text.secondary
                }}
              >
                Note
              </SUBTITLE1>
              <SUBTITLE1>{clientDetails?.content?.content}</SUBTITLE1>
            </Box>
          </>}
          <Divider />
          {clientDetails?.activePackage && <Box display="flex" flexDirection="column" px={4}>
            <SUBTITLE1
              sx={{
                fontWeight: 400,
                color: (theme) => theme.palette.text.secondary
              }}
            >
              Package
            </SUBTITLE1>
            <H3 color={primary.main}>“{clientDetails.activePackage?.name}“</H3>
          </Box>}
          <Box px={4}>
            {(clientDetails?.status !== INACTIVE)
              ? <Button
                startIcon={(
                  <SvgIconWrapper
                    name="Box"
                    size={16}
                  />
                )}
                color="error"
                size="large"
                variant="outlined"
                onClick={() => {
                  onClose();
                  if (clientDetails?.clientId) {
                    handleArchive(clientDetails.clientId);
                  }
                }}
              >
                Archive Client
              </Button>
              : <Button
                startIcon={(
                  <SvgIconWrapper
                    name="Delete"
                    size={16}
                  />
                )}
                color="error"
                size="large"
                variant="outlined"
                onClick={() => {
                  onClose();
                  if (clientDetails?.clientId) {
                    onDelete(clientDetails.clientId);
                  }
                }}
              >
                Delete Client
              </Button>}
          </Box>
        </Stack>
      </Box>
    </Drawer>
  );
};

export default ClientDetails;
