import React, { useEffect, useState } from 'react';
import {
  Box,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  useTheme
} from '@mui/material';
import { RRule, rrulestr } from 'rrule';
import { H5, SUBTITLE2 } from './Typography';
import { DAY, END_AFTER, END_NEVER, END_ON, MONTH, RECURRENCE_OPTIONS, WEEK, WEEK_DAY_OPETIOS, YEAR } from '../../constants/AppVarConstants';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { getDateDetails, getWeekOfMonthlabel } from '../../commonFunctions/utils';
import dayjs from 'dayjs';

export interface WeekDaysType {
  value: string
  label: string
  checked: boolean
  accessKey?: any
};

interface CustomRecurrenceProps {
  date: any
  reccurrenceRule?: string | null
  getRule?: (rule: string) => void
};

const CustomRecurrence: React.FC<CustomRecurrenceProps> = (props): JSX.Element => {
  const {
    date = undefined,
    reccurrenceRule,
    getRule = () => { }
  } = props;

  const { palette: { common, primary, outline } } = useTheme();
  const [repeateValue, setRepeateValue] = useState(1);
  const [recurrenceType, setRecurrenceType] = useState(DAY);
  const [weekDays, setWeekDays] = useState([...WEEK_DAY_OPETIOS]);
  const [monthRecurrence, setMonthRecurrence] = useState('MONTH_DATE');
  const [recurrenceEnd, setRecurrenceEnd] = useState(END_NEVER);
  const [endDate, setEndDate] = useState<any>(dayjs(new Date()));
  const [dateSelected, setDateSelected] = useState({ dayOfMonth: '1', dayOfWeek: 'Monday', weekOfMonth: 1 });
  const [occurrenceAfter, setOccurrenceAfter] = useState(5);
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  useEffect(() => {
    if (date) {
      const currentDate = getDateDetails(date);
      setDateSelected((prev) => currentDate);
      if (!reccurrenceRule) {
        setEndDate(date);
        const idx = weekDays.findIndex((item) => item.value === currentDate?.dayOfWeek);
        const selectedweekday = [...weekDays];
        if (idx >= 0) {
          selectedweekday[idx].checked = !selectedweekday[idx].checked;
        }
        setWeekDays(selectedweekday);
      }
      if (dayjs(date).isAfter(endDate)) {
        setEndDate(date);
      }
    }
  }, [date]);

  useEffect(() => {
    if (reccurrenceRule) {
      const existingRule = rrulestr(reccurrenceRule);
      setRepeateValue(existingRule.origOptions.interval ?? 1);
      setRecurrenceType(getFrequencyBy(existingRule?.origOptions?.freq ?? 3));
      if (existingRule.origOptions?.count) {
        setOccurrenceAfter(existingRule.origOptions.count ?? 5);
        setRecurrenceEnd(END_AFTER);
      } else if (existingRule.origOptions?.until) {
        setEndDate(existingRule.origOptions.until);
        setRecurrenceEnd(END_ON);
      }
      if (existingRule.origOptions?.byweekday) {
        const days: any = existingRule.origOptions?.byweekday;
        const selectedweekday = [...weekDays].map((item) => ({ ...item, checked: false }));
        days?.forEach((item: { weekday: number, n?: undefined }) => {
          if (item.weekday === 6) {
            selectedweekday[0].checked = true;
          } else {
            selectedweekday[item.weekday + 1].checked = true;
          }
        });
        setWeekDays(selectedweekday);
      }
    }
  }, [reccurrenceRule]);

  const handleWeekDaysSelection = (index: number): void => {
    const arr = [...weekDays];
    arr[index].checked = !arr[index].checked;
    const DaySelected = arr.some((item) => item.checked);
    if (!DaySelected) {
      const defaultSelected = arr.map((item) => {
        if (item.value === dateSelected.dayOfWeek) {
          item.checked = true;
          return {
            ...item,
            checked: true
          };
        } else return item;
      });
      setWeekDays(defaultSelected);
      return;
    }
    setWeekDays(arr);
  };

  const getRepeatBy = (value: string): number => {
    switch (value) {
      case WEEK:
        return RRule.WEEKLY;
      case MONTH:
        return RRule.MONTHLY;
      case YEAR:
        return RRule.YEARLY;
      default:
        return RRule.DAILY;
    };
  };

  const getFrequencyBy = (value: number): string => {
    switch (value) {
      case 0:
        return YEAR;
      case 1:
        return MONTH;
      case 2:
        return WEEK;
      default:
        return DAY;
    };
  };

  const weekdayMap: Record<string, any> = {
    MO: RRule.MO,
    TU: RRule.TU,
    WE: RRule.WE,
    TH: RRule.TH,
    FR: RRule.FR,
    SA: RRule.SA,
    SU: RRule.SU,
    Monday: RRule.MO,
    Tuesday: RRule.TU,
    Wednesday: RRule.WE,
    Thursday: RRule.TH,
    Friday: RRule.FR,
    Saturday: RRule.SA,
    Sunday: RRule.SU
  };

  const setRecurrenceRule = (): void => {
    const rule = new RRule({
      freq: getRepeatBy(recurrenceType),
      interval: repeateValue,
      dtstart: date,
      ...(recurrenceType === WEEK && {
        byweekday: weekDays.filter((item) => item.checked).map(({ accessKey }) => weekdayMap[accessKey]),
        wkst: weekdayMap[dateSelected?.dayOfWeek]
      }),
      ...((recurrenceType === MONTH && monthRecurrence === 'MONTH_DATE') && {
        bymonthday: Number(dateSelected.dayOfMonth)
      }),
      ...((recurrenceType === MONTH && monthRecurrence === 'MONTHLY_ON') && {
        byweekday: weekdayMap[dateSelected.dayOfWeek],
        bysetpos: [dateSelected.weekOfMonth]
      }),
      ...(recurrenceEnd === END_ON && {
        until: dayjs(endDate).hour(23).minute(59).second(59).toDate()
      }),
      ...(recurrenceEnd === END_AFTER && {
        count: occurrenceAfter
      })
    });
    getRule(rule.toString());
  };

  useEffect(() => {
    setRecurrenceRule();
  }, [
    repeateValue,
    date,
    dateSelected,
    recurrenceType,
    monthRecurrence,
    weekDays,
    recurrenceEnd,
    endDate,
    occurrenceAfter
  ]);

  return (
    <Box
      sx={{
        py: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: 3
      }}
    >
      <Box display="flex" gap={2} alignItems="center" flexWrap="wrap">
        <H5>Repeat Every</H5>
        <TextField
          value={repeateValue}
          onChange={(e) => {
            const num = Number(e.target.value);
            if (num > 0) {
              setRepeateValue(num);
            }
          }}
          size='small'
          type='number'
          sx={{
            width: 70,
            '.MuiOutlinedInput-root': { bgcolor: 'common.white', color: 'text.secondary' }
          }}
        />
        <Select
          value={recurrenceType}
          onChange={(event) => { setRecurrenceType(event.target.value); }}
          size='small'
          sx={{
            width: 100,
            color: ({ palette: { text } }) => text.secondary
          }}
        >
          {RECURRENCE_OPTIONS.map((item: { key: string, value: string }) => (
            <MenuItem
              key={item.key}
              value={item.value}
            >
              {item.key}
            </MenuItem>
          ))}
        </Select>
        {(recurrenceType === MONTH) && <Select
          value={monthRecurrence}
          onChange={(event) => { setMonthRecurrence(event.target.value); }}
          size='small'
          sx={{
            width: 220,
            color: ({ palette: { text } }) => text.secondary
          }}
        >
          <MenuItem value="MONTH_DATE">{`Monthly on day ${dateSelected?.dayOfMonth}`}</MenuItem>
          <MenuItem value="MONTHLY_ON">{`Monthly on ${getWeekOfMonthlabel(dateSelected?.weekOfMonth)} ${dateSelected.dayOfWeek}`}</MenuItem>
        </Select>}
      </Box>
      {(recurrenceType === WEEK) && <Box display="flex" gap={3} alignItems="center">
        <H5>Repeat On</H5>
        <Box display="flex" gap={2} alignItems="center">
          {weekDays.map((item, index) => (
            <Box
              key={item.value}
              sx={{
                width: 24,
                height: 24,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '50%',
                cursor: 'pointer',
                color: item.checked ? common.white : common.black,
                bgcolor: item.checked ? primary.main : common.white,
                border: item.checked ? 'none' : `1px solid ${outline.main}`
              }}
              onClick={() => { handleWeekDaysSelection(index); }}
            >
              <SUBTITLE2 fontSize={12}>{item.label}</SUBTITLE2>
            </Box>
          ))}
        </Box>
      </Box>}
      <Box display="flex" flexDirection="column" gap={1}>
        <H5>Ends</H5>
        <Box>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={recurrenceEnd}
            onChange={(event) => { setRecurrenceEnd(event.target.value); }}
          >
            <FormControlLabel value={END_NEVER} control={<Radio />} label="Never" />
            <FormControlLabel
              value={END_ON}
              control={<Radio />}
              label={(
                <Box display="flex" alignItems="center">
                  <p style={{ width: 80 }}>On</p>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      open={datePickerOpen}
                      disablePast
                      format='DD/MM/YYYY'
                      minDate={dayjs(date)}
                      value={dayjs(endDate)}
                      onChange={(value) => { setEndDate(value); }}
                      onAccept={(value) => { setEndDate(value); }}
                      onClose={() => { setDatePickerOpen(false); }}
                      disabled={recurrenceEnd !== END_ON}
                      sx={{
                        '.MuiOutlinedInput-input': {
                          bgcolor: 'common.white', color: 'text.secondary'
                        }
                      }}
                      slotProps={{
                        textField: {
                          size: 'small',
                          onClick: () => {
                            setDatePickerOpen(true);
                          },
                          sx: {
                            '.MuiOutlinedInput-notchedOutline': {
                              cursor: 'pointer',
                              pointerEvents: 'all !important',
                              borderColor: 'border.main'
                            }
                          }
                        }
                      }}
                    />
                  </LocalizationProvider>
                </Box>
              )}
            />
            <FormControlLabel
              value={END_AFTER}
              control={<Radio />}
              label={(
                <Box display="flex" alignItems="center">
                  <p style={{ width: 80 }}>After</p>
                  <TextField
                    disabled={recurrenceEnd !== END_AFTER}
                    value={occurrenceAfter}
                    onChange={(event) => {
                      const num = Number(event.target.value);
                      if (num > 0) {
                        setOccurrenceAfter(num);
                      }
                    }}
                    size='small'
                    type='number'
                    sx={{
                      width: 70,
                      '.MuiOutlinedInput-root': { bgcolor: 'common.white', color: 'text.secondary' }
                    }}
                  />
                  <p>&nbsp;Occurrences</p>
                </Box>
              )}
            />
          </RadioGroup>
        </Box>
      </Box>
    </Box>
  );
};

export default CustomRecurrence;
