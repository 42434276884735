import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { Box, Button, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import SelectField from '../common/SelectField';
import FormDateField from '../common/FormDateField';
import CustomDialog from '../common/Dialog';
import Form from '../common/Form';
import {
  PACKAGE_INTERVAL_OPTIONS,
  mobileWidth
} from '../../constants/AppVarConstants';
import SvgIconWrapper from '../common/SvgIcon';
import type { SubscriptionType } from '../../types/common';
import { useDispatch, useSelector } from 'react-redux';
import { SubscriptionSchema } from '../../validations/validationSchema';
import FormInputTextField from '../common/FormInputTextField';
import type { RootState } from '../../types/reduxState';
import { ROUTE_PACKAGES } from '../../constants/routes';
import SelectFieldv2 from '../common/SelectFieldv2';
import { createSubscription, updateSubscription } from '../../redux/actions/subscriptionActions';
import { setSuccessDialogInfo } from '../../redux/actions/appActions';
import { getCurrencySymbol } from '../../commonFunctions/utils';
import { isEmpty } from 'lodash';

interface SubscriptionDialogProps {
  open: boolean
  makeApiCall?: boolean
  subscriptionInfo?: SubscriptionType | null
  onClose: () => void
  onEdit?: () => void
  fullWidth?: boolean
}

const SubscriptionDialog: React.FC<SubscriptionDialogProps> = (props): JSX.Element => {
  const {
    open = false,
    makeApiCall = false,
    subscriptionInfo = null,
    onClose = () => { },
    fullWidth = false
  } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(`(${mobileWidth})`);
  const { packageOptions } = useSelector((state: RootState) => state.packageState);

  const [selectedPackage, setSelectedPackage] = useState<any>(null);
  const [nextChargeDate, setNextChargeDate] = useState<any>(null);

  const {
    control,
    reset,
    watch,
    formState: { errors },
    setValue,
    handleSubmit
  } = useForm({
    defaultValues: {
      clientId: '',
      mandateId: '',
      packageId: '',
      count: '',
      interval: '',
      amount: '',
      startDate: null
    },
    resolver: yupResolver(SubscriptionSchema),
    mode: 'onSubmit'
  });

  useEffect(() => {
    if (subscriptionInfo) {
      setValue('subscriptionId', subscriptionInfo?.id);
      setValue('clientId', subscriptionInfo.client.id);
      setValue('amount', String(subscriptionInfo?.amount / 100));
      // setValue('packageId', subscriptionInfo.package?.id);
      setValue('interval', subscriptionInfo.interval);
      setValue('count', subscriptionInfo.count);
      setValue('startDate', new Date(subscriptionInfo.start));
    }
  }, [subscriptionInfo]);

  const packageId = watch('packageId');

  useEffect(() => {
    if (isEmpty(subscriptionInfo) && packageId) {
      setValue('interval', selectedPackage?.interval);
      setValue('amount', selectedPackage?.price);
    }
  }, [packageId]);

  const handleClose = (): void => {
    onClose();
    if (!subscriptionInfo) {
      reset();
    } else {
      setValue('amount', String(subscriptionInfo?.amount / 100));
    }
  };

  const onSubmit = (data: any): void => {
    if (!subscriptionInfo) {
      const payload = {
        clientId: data?.clientId,
        packageId: data?.packageId,
        mandateId: data?.mandateId,
        count: data?.count,
        amount: (Number(data.amount) * 100),
        interval: data?.interval,
        startDate: dayjs(data.startDate?.$d ?? nextChargeDate).format('YYYY-MM-DD')
      };
      dispatch(createSubscription(payload));
    } else {
      const payload = {
        subscriptionId: subscriptionInfo?.id,
        amount: (Number(data.amount) * 100)
      };
      dispatch(updateSubscription(payload, () => {
        dispatch(setSuccessDialogInfo({
          title: 'Successfully Updated',
          description: 'Your client will be charged the new amount at the next billing period.'
        }));
      }));
    }
    handleClose();
  };

  return (
    <CustomDialog
      open={open}
      onClose={handleClose}
      isMobileScreen={isMobile}
      fullWidth={fullWidth}
      title={subscriptionInfo ? 'Edit Subscription' : 'New Subscription'}
      description={subscriptionInfo ? 'Edit your Subscription' : 'Create New Subscription'}
      content={(
        <Form
          mainSX={{ columnSpacing: 3 }}
          fields={[
            {
              label: 'Select Client',
              labelSX: { variant: 'h5', lineHeight: 1.8 },
              gridSX: { xs: 12, md: 12 },
              component: <Controller
                name='clientId'
                control={control}
                render={({ field }) => (
                  <SelectFieldv2
                    field={field}
                    width="100%"
                    defaultValue={subscriptionInfo?.client?.fullName}
                    error={!!errors.clientId}
                    helperText={errors?.clientId?.message ?? ' '}
                    disabled={!!subscriptionInfo}
                    placeHolder='Select Client'
                    makeApiCall={makeApiCall}
                    activeMandatClinets
                    getClientName={(data) => {
                      if (data) {
                        setValue('clientId', data.key);
                        setValue('clientName', data.value);
                        setValue('mandateId', data?.mandateId);
                        setNextChargeDate(data?.nextPossibleChargeDate);
                        setValue('startDate', dayjs(data?.nextPossibleChargeDate));
                      }
                    }}
                  />
                )}
              />
            },
            {
              label: 'Package Name',
              labelSX: { variant: 'h5', lineHeight: 1.8 },
              gridSX: { xs: 12, md: 12 },
              component: <SelectField
                width="100%"
                name="packageId"
                control={control}
                options={packageOptions}
                disabled={!!subscriptionInfo}
                placeholder={subscriptionInfo ? subscriptionInfo.package.name : 'Select Package'}
                customFunction={(data) => {
                  setSelectedPackage(data);
                }}
                endButton={(
                  <Box textAlign="center" py={1}>
                    <Button
                      startIcon={<SvgIconWrapper name='Add' size={16} />}
                      variant='contained'
                      size='medium'
                      onClick={() => {
                        handleClose();
                        navigate(ROUTE_PACKAGES);
                      }}
                    >
                      Add Package
                    </Button>
                  </Box>
                )}
              />
            },
            {
              label: 'Interval',
              labelSX: { variant: 'h5', lineHeight: 1.8 },
              gridSX: { xs: 12, md: 6 },
              component: <SelectField width="100%" name='interval' control={control} options={PACKAGE_INTERVAL_OPTIONS} placeholder='Select Interval' disabled={!!subscriptionInfo} />
            },
            {
              label: 'Amount',
              labelSX: { variant: 'h5', lineHeight: 1.8 },
              gridSX: { xs: 12, md: 6 },
              component: <FormInputTextField
                type='number'
                width="100%"
                name='amount'
                control={control}
                showPrefix
                prefix={subscriptionInfo ? subscriptionInfo.symbol : getCurrencySymbol(selectedPackage?.currency ?? 'GBP')}
              />
            },
            {
              label: 'Interval Count',
              labelSX: { variant: 'h5', lineHeight: 1.8 },
              gridSX: { xs: 12, md: 6 },
              component: <FormInputTextField type='number' width="100%" name="count" control={control} placeholder='Enter Interval Count' disabled={!!subscriptionInfo} />
            },
            {
              label: 'Start date',
              labelSX: { variant: 'h5', lineHeight: 1.8 },
              gridSX: { xs: 12, md: 6 },
              component: <FormDateField
                width="100%"
                name='startDate'
                disabled={!!subscriptionInfo}
                control={control}
                minDate={nextChargeDate ? dayjs(nextChargeDate) : null}
              />
            }
          ]}
        />
      )}
      leftButton={(
        <Button
          startIcon={(
            <SvgIconWrapper
              name="Cancel"
              size={16}
            />
          )}
          size="large"
          variant="outlined"
          color="error"
          onClick={handleClose}
        >
          Cancel
        </Button>
      )}
      rightButton={(
        <Button
          startIcon={(
            <SvgIconWrapper
              name={subscriptionInfo ? 'Check' : 'Add'}
              size={16}
            />
          )}
          variant="contained"
          size="large"
          onClick={() => {
            void handleSubmit(onSubmit)();
          }}
        >
          {subscriptionInfo ? 'Update' : 'Add'}
        </Button>
      )}
    />
  );
};

export default SubscriptionDialog;
