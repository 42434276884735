import React, { useEffect, useState } from 'react';
import { Avatar, Box, Button, Divider, Stack, useMediaQuery } from '@mui/material';
import CustomToolbar from './Toolbar';
import ClientDialog from './ClientDialog';
import Card from './Card';
import ClientDetails from './ClientDetails';
import PaymentUrlDialog from './PaymentUrlDialog';
import { ACTIVE, DELETED, INACTIVE, mobileWidth } from '../../constants/AppVarConstants';
import type { Client, PackageType } from '../../types/common';
import ConfirmationDialog from '../common/ConfirmationDialog';
import { setErrorMessage, setSuccessDialogInfo } from '../../redux/actions/appActions';
import { useDispatch, useSelector } from 'react-redux';
import { archiveClient, chargeOneOfPayment, setAllClients, updateClient } from '../../redux/actions/clientActions';
import type { RootState } from '../../types/reduxState';
import { GET_ALL_CLIENTS, GET_PACKAGE_LIST_URL } from '../../redux/api/config';
import API_MIDDLEWARE from '../../redux/api/middleware';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from '../common/Loader';
import { useDebounce } from '../../hooks/useDebounce';
import { BODY1, H2, H5, SUBTITLE1 } from '../common/Typography';
import { setPackageOptions } from '../../redux/actions/packageActions';
import { APP_CUSTOM_ERROR } from '../../constants/MessageConstants';
import { isEmpty } from 'lodash';
import CustomDialog from '../common/Dialog';
import { InfoOutlined } from '@mui/icons-material';
import SvgIconWrapper from '../common/SvgIcon';
import OneOfPaymentDialog from './OneOfPaymentDialog';

const ClientView = (): JSX.Element => {
  const dispatch = useDispatch();
  const { clients, reFetchClients } = useSelector((state: RootState) => state.clientState);
  const isMobile = useMediaQuery(`(${mobileWidth})`);

  const [searchValue, setSearchValue] = useState('');
  const [archivedClients, setArchivedClients] = useState(false);
  const [viewClientDetails, setViewClientDetails] = useState(false);
  const [paymentDialog, setPaymentDialog] = useState(false);
  const [archiveDialog, setArchiveDialog] = useState(false);
  const [oneOfPaymentDialog, setOneOfPaymentDialog] = useState(false);
  const [editClient, setEditClient] = useState(false);
  const [confirmEditClient, setConfirmEditClient] = useState(false);
  const [confirmDeleteClient, setConfirmDeleteClient] = useState(false);
  const [clientData, setClientData] = useState<Client | null>(null);
  const [clientPayload, setClienPayload] = useState<any | null>(null);

  const [currentOffset, setCurrentOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const [hasMorePlayers, setHasMorePlayers] = useState(false);
  const [openMandateInfo, setOpenMandateInfo] = useState(false);

  const debouncedValue = useDebounce(searchValue, 500);

  const fetchClientList = (flag: boolean): void => {
    if (!flag) {
      setLoading(true);
    }
    const payload = {
      limit: 10,
      offset: flag ? currentOffset + 10 : 0,
      query: searchValue || '*',
      status: archivedClients ? 'INACTIVE' : 'ACTIVE'
    };
    API_MIDDLEWARE(
      GET_ALL_CLIENTS,
      'POST',
      {
        'Content-Type': 'application/json'
      },
      payload
    )
      .then((res) => {
        const { hits, isHasMore, offset } = res.result;
        const data = [...(flag ? clients : []), ...hits];
        dispatch(setAllClients(data));
        setHasMorePlayers(isHasMore);
        setCurrentOffset(offset);
        setLoading(false);
      })
      .catch((err) => {
        dispatch(setErrorMessage(err?.errorMsg));
        setLoading(false);
      });
  };

  const fetchPackages = (): void => {
    API_MIDDLEWARE(
      `${GET_PACKAGE_LIST_URL}?status=${ACTIVE}`,
      'GET',
      {
        'Content-Type': 'application/json'
      }
    )
      .then((res) => {
        const options = res?.results?.map((item: PackageType) => {
          return {
            key: item.name,
            value: item.id
          };
        });
        dispatch(setPackageOptions(options));
      })
      .catch((err) => {
        dispatch(setErrorMessage(err.errorMsg ?? APP_CUSTOM_ERROR));
      });
  };

  useEffect(() => {
    fetchClientList(false);
  }, [reFetchClients, debouncedValue, archivedClients]);

  useEffect(() => {
    fetchPackages();
  }, []);

  const handleClientDialog = (): void => {
    setViewClientDetails((prev) => !prev);
  };

  const handleClientData = (client?: Client): void => {
    if (client) {
      setClientData(client);
    }
  };

  const handlePaymentDialog = (clientData?: Client): void => {
    handleClientData(clientData);
    setPaymentDialog((prev) => !prev);
  };

  const handleArchiveDialog = (): void => {
    setArchiveDialog((prev) => !prev);
  };

  const handleConfirmDeleteClient = (): void => {
    setConfirmDeleteClient((prev) => !prev);
  };

  const updateClientProfile = (): void => {
    if (clientPayload) {
      const payload = {
        ...clientPayload,
        clientId: clientData?.clientId
      };
      dispatch(updateClient(payload, () => {
        setConfirmEditClient(false);
      }));
    }
  };

  const archiveClientProfile = (): void => {
    const payload = {
      clientId: clientData?.clientId,
      status: INACTIVE
    };
    dispatch(archiveClient(payload, () => {
      handleArchiveDialog();
      dispatch(setSuccessDialogInfo({
        title: 'Client Archived',
        description: 'The client was archived successfully.'
      }));
    }));
  };

  const restoreClientProfile = (clientId: string | number): void => {
    const payload = {
      clientId,
      status: ACTIVE
    };
    dispatch(archiveClient(payload, () => {
      dispatch(setSuccessDialogInfo({
        title: 'Successfully Restored',
        description: 'You have successfully restored this client. They are now visible in your main client list.'
      }));
    }));
  };

  const deleteClient = (): void => {
    const payload = {
      clientId: clientData?.clientId,
      status: DELETED
    };
    dispatch(archiveClient(payload, () => {
      handleConfirmDeleteClient();
      dispatch(setSuccessDialogInfo({
        title: 'Successfully Deleted',
        description: 'You have successfully deleted this client.'
      }));
    }));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        background: '#fff',
        overflow: 'hidden',
        ...(!isMobile && {
          borderRadius: '25px',
          border: (theme) => `1px solid ${theme.palette.outline.main}`
        })
      }}
    >
      <CustomToolbar
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        archiveValue={archivedClients}
        setArchiveValue={setArchivedClients}
        setopenInfo={setOpenMandateInfo}
        noClients={isEmpty(clients)}
      />
      <Box id="scrollableDiv" sx={{ maxHeight: 'calc(100vh - 220px)', overflowY: 'auto' }}>
        {loading
          ? (
            <Loader />)
          : (<InfiniteScroll
            style={{ overflow: 'none !important' }}
            dataLength={clients.length}
            next={() => { fetchClientList(true); }}
            hasMore={hasMorePlayers}
            loader={<Loader />}
            endMessage={(
              <Box display="flex" justifyContent="center" py={1}>
                {!clients.length
                  ? <SUBTITLE1>No results.</SUBTITLE1>
                  : <SUBTITLE1>No More Clients.</SUBTITLE1>
                }
              </Box>
            )}
            scrollableTarget="scrollableDiv"
          >
            {clients?.map((item) => (
              <Box key={item?.clientId}>
                <Card
                  clientData={item}
                  onClick={(client) => {
                    handleClientDialog();
                    handleClientData(client);
                  }}
                  handleSendRequest={handlePaymentDialog}
                />
                <Divider />
              </Box>
            ))}
          </InfiniteScroll>)
        }
      </Box>
      <ClientDialog
        open={editClient}
        clientDetails={clientData}
        onClose={() => {
          setEditClient(false);
          setConfirmEditClient(false);
        }}
        onEdit={(data) => {
          setClienPayload(data);
          setEditClient(false);
          setConfirmEditClient(true);
        }}
      />
      <ClientDetails
        open={viewClientDetails}
        onClose={handleClientDialog}
        clientDetails={clientData}
        onEdit={() => { setEditClient(true); }}
        onCreatePayment={() => { setOneOfPaymentDialog(true); }}
        handleArchive={(id) => {
          setClienPayload({ id });
          handleArchiveDialog();
        }}
        onRestore={(id) => {
          restoreClientProfile(id);
        }}
        onDelete={(id) => {
          setClienPayload({ id });
          handleConfirmDeleteClient();
        }}
      />
      <PaymentUrlDialog
        fullWidth
        clientDetails={clientData}
        open={paymentDialog}
        onClose={handlePaymentDialog}
      />
      <ConfirmationDialog
        open={archiveDialog}
        archive
        title='Archive Client?'
        description='Are you sure that you&apos;d like to archive this client? You may later restore this client on the Archived Clients list.'
        onClose={handleArchiveDialog}
        onLeftClick={handleArchiveDialog}
        onClick={archiveClientProfile}
      />
      <ConfirmationDialog
        open={confirmEditClient}
        edit
        title='Edit Client'
        description='Are you sure that you&apos;d like to edit client details'
        onClose={() => { setConfirmEditClient(false); }}
        onLeftClick={() => { setEditClient(true); }}
        onClick={updateClientProfile}
      />
      <ConfirmationDialog
        open={confirmDeleteClient}
        deleteIcon
        title='Delete Client'
        description='Are you sure that you&apos;d like to delete this client? All client information will be permanently removed from the system. '
        onClose={handleConfirmDeleteClient}
        onLeftClick={handleConfirmDeleteClient}
        onClick={deleteClient}
      />
      <CustomDialog
        open={openMandateInfo}
        onClose={() => { setOpenMandateInfo(false); }}
        showDividers
        fullWidth
        maxWidth='sm'
        content={(
          <Stack direction="column" spacing={3}>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <Avatar sx={{ bgcolor: '#eaf6ff' }}>
                <InfoOutlined color='primary'/>
              </Avatar>
              <H2>Mandate Information</H2>
            </Box>
            <Box display="flex" flexDirection="column" gap={3}>
              <BODY1 sx={{ color: 'text.secondary' }}>
                Sending a mandate to your client gives you permission to charge them for your services.
              </BODY1>
              <BODY1 sx={{ color: 'text.secondary' }}>
                This process takes about 4 business days. If you opted to add a subscription during this step, it will be created automatically and the client will be charged after the mandate is approved.
              </BODY1>
              <H5>
                Stages of Mandate Processing
              </H5>
              <BODY1 sx={{ color: 'text.secondary' }}>
                <b>Pending Customer Approval: </b> The client has received the mandate but has not entered their bank information and approved it yet.
              </BODY1>
              <BODY1 sx={{ color: 'text.secondary' }}>
                <b>Pending Submission: </b> The mandate was approved by the client and will be submitted to their bank shortly.
              </BODY1>
              <BODY1 sx={{ color: 'text.secondary' }}>
                <b>Submitted: </b> The mandate was submitted to the client&apos;s bank and is being processed.
              </BODY1>
              <BODY1 sx={{ color: 'text.secondary' }}>
                <b>Payment Setup Complete: </b> The mandate was successfully processed. You may create subscriptions and charge your client
              </BODY1>
            </Box>
            <Box textAlign="end">
              <Button
                endIcon={<SvgIconWrapper name='Check' size={16} />}
                variant="contained"
                size="large"
                onClick={() => { setOpenMandateInfo(false); }}
              >
                Ok
              </Button>
            </Box>
          </Stack>
        )}
      />
      <OneOfPaymentDialog
        open={oneOfPaymentDialog}
        clientDetails={clientData}
        onClose={() => { setOneOfPaymentDialog(false); }}
        onConfirmPayment={(data) => { dispatch(chargeOneOfPayment(data)); }}
      />
    </Box>
  );
};

export default ClientView;
