export const ROUTE_MAIN = '/';
export const ROUTE_DASHBOARD = '/dashboard';
export const ROUTE_CALENDAR = '/calendar';
export const ROUTE_CLIENT_LIST = '/clients';
export const ROUTE_SETTING = '/settings';
export const ROUTE_PROFILE = '/profile';
export const ROUTE_SUBSCRIPTION_LIST = '/subscriptions';
export const ROUTE_PACKAGES = '/packages';
export const ROUTE_PAYMENT_HISTORY = '/payment-history';

// User
export const ROUTE_LOGIN = '/login';
export const ROUTE_REGISTER = '/register';
export const ROUTE_RESET_PASSWORD = '/password/reset/:token';
export const ROUTE_FORGOT_PASSWORD = '/forgot-password';
export const ROUTE_EMAIL_VERIFY = '/email/verify/:token';

// payment
export const ROUTE_PAYMENT_SETUP = '/payment-setup';
export const ROUTE_PAYMENT_SETUP_ONBOARDING = '/payment/gocardless/onboarding';
export const ROUTE_PAYMENT_SETUP_VERIFICATION = '/payment/gocardless/verification';

// Client View
export const ROUTE_CLIENT_CALENDAR = '/client/calendar/:userId';
