import React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { H6, SUBTITLE2 } from '../common/Typography';
import { mobileWidth } from '../../constants/AppVarConstants';

interface DashboardCardProps {
  label: string
  value: string | number
  selected?: boolean
  showTag?: boolean
}

const DashboardCard: React.FC<DashboardCardProps> = (props): JSX.Element => {
  const {
    label,
    value,
    selected = false,
    showTag = false
  } = props;

  const isMobile = useMediaQuery(`(${mobileWidth})`);
  const { palette: { outline, primary, deepOrange }, shadows } = useTheme();

  return (
    <Box
      sx={{
        p: 2,
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        outline: selected ? `2px solid ${primary.main}` : `1px solid ${outline.main}`,
        borderRadius: '6px',
        boxShadow: selected ? 'none' : shadows[8] // box shadow needs to be updated based onClick
      }}
    >
      <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} justifyContent="space-between" alignItems={isMobile ? 'start' : 'center'}>
        <H6
          fontWeight={700}
          sx={{
            color: (theme) => theme.palette.text.secondary,
            fontWeight: 700,
            lineHeight: 2.2
          }}
        >
          {label}
        </H6>
        {showTag && <SUBTITLE2
          sx={{
            p: 1,
            borderRadius: '5px',
            fontWeight: 700,
            color: deepOrange.main,
            background: deepOrange.light
          }}
        >
          Projected
        </SUBTITLE2>}
      </Box>
      <Typography
        sx={{
          typography: { xs: 'h4', sm: 'h2' },
          WebkitLineClamp: '2',
          fontWeight: '700 !important',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical'
        }}
      >
        {value}
      </Typography>
    </Box>
  );
};

export default DashboardCard;
