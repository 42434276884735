import React, { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, useMediaQuery } from '@mui/material';
import CustomDialog from '../common/Dialog';
import Form from '../common/Form';
import FormInputTextField from '../common/FormInputTextField';
import { mobileWidth } from '../../constants/AppVarConstants';
import SvgIconWrapper from '../common/SvgIcon';
import FormDateField from '../common/FormDateField';
import type { Client } from '../../types/common';
import { OneOfPaymentDialogSchema } from '../../validations/validationSchema';
import { Controller, useForm } from 'react-hook-form';
import { getCurrencySymbol } from '../../commonFunctions/utils';
import InfiniteSelectField from '../common/InfiniteSelectField';
import { GET_SUBSCRIPTIONS_URL } from '../../redux/api/config';
import moment from 'moment';
import dayjs from 'dayjs';

interface OneOfPaymentDialogProps {
  open: boolean
  clientDetails?: Client | null
  onClose: () => void
  onConfirmPayment?: (data: any) => void
  fullWidth?: boolean
};

const OneOfPaymentDialog: React.FC<OneOfPaymentDialogProps> = (props) => {
  const {
    open = false,
    onClose = () => { },
    onConfirmPayment = () => { },
    clientDetails = null,
    fullWidth = false
  } = props;

  const isMobile = useMediaQuery(`(${mobileWidth})`);

  const {
    control,
    clearErrors,
    setValue,
    reset,
    formState: { isValid, errors },
    handleSubmit
  } = useForm({
    defaultValues: {
      subscriptionId: '',
      price: '',
      chargeDate: null,
      note: ''
    },
    resolver: yupResolver(OneOfPaymentDialogSchema),
    mode: 'onSubmit'
  });

  useEffect(() => {
    if (isValid) {
      clearErrors();
    }
  }, [isValid]);

  const onSubmit = async (data: any): Promise<void> => {
    const payload = {
      mandateId: clientDetails?.mandate?.id,
      subscriptionId: data?.subscriptionId,
      clientId: clientDetails?.clientId,
      chargeDate: moment(data.chargeDate?.$d).format('YYYY-MM-DD'),
      amount: Number(data?.price) * 100,
      ...(data?.note && {
        message: data?.note
      })
    };
    onConfirmPayment(payload);
    handleClose();
  };

  const handleClose = (): void => {
    reset();
    onClose();
    clearErrors();
  };

  return (
    <CustomDialog
      open={open}
      onClose={handleClose}
      fullWidth={fullWidth}
      isMobileScreen={isMobile}
      title="Create a One-Off Payment"
      description={`Charge ${clientDetails?.fullName ?? ''} a one-time fee that will be charged immediately.`}
      content={(
        <Form
          mainSX={{ columnSpacing: 3 }}
          fields={[
            {
              label: 'Select Subscription',
              labelSX: { variant: 'h5', lineHeight: 1.8 },
              gridSX: { xs: 12, md: 12 },
              component: <Controller
                  name='subscriptionId'
                  control={control}
                  render={({ field }) => (
                    <InfiniteSelectField
                      getSelect={(data: any) => {
                        setValue('subscriptionId', data.value);
                      }}
                      newPayload={{ clientId: clientDetails?.clientId }}
                      API={GET_SUBSCRIPTIONS_URL}
                      subscriptionID=''
                      error={!!errors.subscriptionId}
                      helperText={errors?.subscriptionId?.message ?? ' '}
                      width="100%"
                      field={field}
                      noMoreText='No More Subscription'
                    />
                  )}
                />
            },
            {
              label: 'Select Charge Date',
              labelSX: { variant: 'h5', lineHeight: 1.8 },
              gridSX: { xs: 12, md: 12 },
              component: <FormDateField width="100%" name="chargeDate" control={control} disablePast minDate={dayjs(clientDetails?.mandate?.nextPossibleChargeDate)} />
            },
            {
              label: 'Enter the amount you’d like to charge',
              labelSX: { variant: 'h5', lineHeight: 1.8 },
              gridSX: { xs: 12, md: 12 },
              component: <FormInputTextField
                  type='number'
                  width='20%'
                  name='price'
                  control={control}
                  showPrefix
                  prefix={getCurrencySymbol('GBP')}
                />
            },
            {
              label: 'Message ',
              sideLabel: '(Optional)',
              labelSX: { variant: 'h5', lineHeight: 1.8 },
              gridSX: { xs: 12, md: 12 },
              component: <FormInputTextField
                width="100%"
                name="note"
                control={control}
                placeholder='Add a custom note that will be sent to your client alongside a receipt from GoCardless'
                textFieldSx={{
                  multiline: true,
                  rows: 4
                }}
              />
            }
          ]}
        />
      )}
      leftButton={(
        <Button
          startIcon={(
            <SvgIconWrapper name="Cancel" size={16} />
          )}
          variant="outlined"
          size="large"
          color='error'
          onClick={handleClose}
        >
          Cancel
        </Button>
      )}
      rightButton={(
        <Button
          startIcon={(
            <SvgIconWrapper
              name="Check"
              size={16}
            />
          )}
          variant="contained"
          size="large"
          onClick={() => {
            void handleSubmit(onSubmit)();
          }}
        >
          Confirm Payment
        </Button>
      )}
    />
  );
};

export default OneOfPaymentDialog;
