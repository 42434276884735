import React, { useEffect, useState } from 'react';
import momenttz from 'moment-timezone';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import SelectField from '../common/SelectField';
import SelectFieldv2 from '../common/SelectFieldv2';
import { Box, Button, useMediaQuery, useTheme } from '@mui/material';
import CustomDialog from '../common/Dialog';
import Form from '../common/Form';
import {
  ACTIVE,
  DURATION_OPTIONS,
  RECURRENCE_TYPE_OPTIONS,
  mobileWidth
} from '../../constants/AppVarConstants';
import SvgIconWrapper from '../common/SvgIcon';
import type { EventType } from '../../types/common';
import DateTimeField from '../common/DateTimeField';
import CustomRecurrence from '../common/CustomRecurrence';
import type { RootState } from '../../types/reduxState';
import { AddSessionSchema } from '../../validations/validationSchema';
import { ROUTE_CLIENT_LIST, ROUTE_SUBSCRIPTION_LIST } from '../../constants/routes';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { RRule } from 'rrule';
import InfiniteSelectField from '../common/InfiniteSelectField';
import { GET_SUBSCRIPTIONS_URL } from '../../redux/api/config';
import { SUBTITLE2 } from '../common/Typography';

interface SessionDialogProps {
  open: boolean
  makeApiCall?: boolean
  eventData?: EventType | null
  updateAll?: boolean
  updateUpcoming?: boolean
  onClose: () => void
  handleAddSession?: (data?: any) => void
  onEdit?: (data?: EventType) => void
  fullWidth?: boolean
}

const SessionDialog: React.FC<SessionDialogProps> = (props): JSX.Element => {
  const {
    open = false,
    makeApiCall = false,
    eventData = null,
    updateAll = false,
    updateUpcoming = false,
    onClose = () => { },
    handleAddSession = () => { },
    onEdit = () => { },
    fullWidth = false
  } = props;

  const navigate = useNavigate();
  const { palette: { error } } = useTheme();
  const isMobile = useMediaQuery(`(${mobileWidth})`);
  const { sessionTypes } = useSelector((state: RootState) => state.calendarState);
  const { user } = useSelector((state: RootState) => state.app.user);

  const [reccurenceRule, setReccurenceRule] = useState('');
  const [allowSession, setAllowSession] = useState(true);

  const {
    control,
    setValue,
    reset,
    watch,
    clearErrors,
    formState: { errors, isValid },
    handleSubmit,
    getValues
  } = useForm({
    defaultValues: {
      clientDetails: {},
      clientName: '',
      clientId: '',
      dateTime: null,
      duration: user?.duration,
      sessionType: '1',
      recurrence: 'NO_REPEAT',
      subscription: ''
    },
    resolver: yupResolver(AddSessionSchema),
    mode: 'onSubmit'
  });

  const recurrence = watch('recurrence');
  const clinetName = watch('clientId');
  const startDate: any = watch('dateTime');
  const clientDetails = watch('clientDetails');
  const sessionType = watch('sessionType');

  const setDefaultValues = (): void => {
    if (eventData) {
      setValue('clientName', eventData.client);
      setValue('clientId', String(eventData?.clientId));
      setValue('dateTime', new Date(eventData.start));
      setValue('duration', eventData.duration);
      setValue('sessionType', eventData?.sessionTypeId ? String(eventData?.sessionTypeId) : '');
      setValue('recurrence', eventData.recurrence ? 'CUSTOM' : 'NO_REPEAT');
      setValue('sessionId', eventData.sessionId);
      if (updateAll && eventData.recurrence) {
        const originalRRULE = RRule.fromString(eventData.recurrence);
        const date: any = originalRRULE.origOptions.dtstart;
        setValue('dateTime', date);
      }
    }
  };

  useEffect(() => {
    if (user?.duration) {
      setValue('duration', user.duration);
    } else {
      setValue('duration', '60');
    }
  }, [user]);

  useEffect(() => {
    if (!isEmpty(clientDetails) && clientDetails?.allDetails?.mandate?.status !== ACTIVE && String(sessionType) === '1') {
      setAllowSession(false);
    } else {
      setAllowSession(true);
    }
  }, [clientDetails, sessionType]);

  useEffect(() => {
    if (isValid) {
      clearErrors();
    }
  }, [isValid, clinetName]);

  useEffect(() => {
    setDefaultValues();
  }, [eventData, updateAll]);

  const onSubmit = (data: any): void => {
    const selectedDatetime = momenttz.tz(data?.dateTime?.$d, user?.zoneId);
    // Get the current offset of Asia/Kolkata timezone
    const currentOffset = selectedDatetime.utcOffset();
    // Subtract the offset from the date
    const adjustedDatetime = selectedDatetime.subtract(currentOffset, 'minutes').subtract(new Date().getTimezoneOffset(), 'minutes');
    // Format for API (send this string to the server)
    if (!eventData) {
      const payload = {
        clientId: data?.clientId,
        userId: user?.id,
        sessionTypeId: Number(data?.sessionType),
        start: adjustedDatetime.format(),
        timezone: user?.zoneId ?? 'Europe/London',
        ...(data?.subscription && (Number(data?.sessionType) === 1) && {
          subscriptionId: data?.subscription
        }),
        durationInMinutes: Number(data?.duration),
        recurrence: recurrence === 'CUSTOM' ? reccurenceRule : ''
      };
      handleAddSession(payload);
    } else {
      onEdit({
        ...data,
        updateAll,
        updateUpcoming,
        createException: !eventData.exceptionId,
        ...(updateUpcoming && eventData?.subscriptionId && {
          subscriptionId: eventData?.subscriptionId
        }),
        recurrence: recurrence === 'CUSTOM' ? reccurenceRule : ''
      });
    }
    handleClose();
  };

  const handleClose = (): void => {
    if (!eventData) {
      reset();
    }
    onClose();
    clearErrors();
  };

  const handleAddClient = (): void => {
    handleClose();
    navigate(`${ROUTE_CLIENT_LIST}?redirect=${location.pathname}`);
  };

  const handleAddSubscription = (): void => {
    handleClose();
    navigate(ROUTE_SUBSCRIPTION_LIST);
  };

  return (
    <CustomDialog
      open={open}
      onClose={() => {
        handleClose();
        setDefaultValues();
      }}
      isMobileScreen={isMobile}
      fullWidth={fullWidth}
      title={eventData ? 'Edit Session' : 'New Session'}
      description={eventData ? 'Edit your session and we will notify your client.' : 'Create new session for your client'}
      content={(
        <Form
          mainSX={{ columnSpacing: 3 }}
          fields={[
            {
              label: 'Select Client',
              labelSX: { variant: 'h5', lineHeight: 1.8 },
              gridSX: { xs: 12, md: 12 },
              component:
                <Controller
                  name='clientId'
                  control={control}
                  render={({ field }) => (
                    <SelectFieldv2
                      field={field}
                      width="100%"
                      defaultValue={eventData?.client}
                      error={!!errors.clientId}
                      helperText={errors?.clientId?.message ?? ' '}
                      disabled={!!eventData}
                      placeHolder='Select Client'
                      showAddClient
                      makeApiCall={makeApiCall}
                      handleAddClient={handleAddClient}
                      getClientName={(data: any) => {
                        if (data) {
                          setValue('clientDetails', data);
                          setValue('clientId', data.key);
                          setValue('clientName', data.value);
                        }
                      }}
                    />
                  )}
                />
            },
            {
              label: 'Select Date & Time',
              labelSX: { variant: 'h5', lineHeight: 1.8 },
              gridSX: { xs: 12, md: 12 },
              component: <DateTimeField
                width="100%"
                name='dateTime'
                control={control}
              />
            },
            {
              label: 'Duration',
              labelSX: { variant: 'h5', lineHeight: 1.8 },
              gridSX: { xs: 12, md: 6 },
              component: <SelectField width="100%" name='duration' control={control} options={DURATION_OPTIONS} placeholder='Duration' />
            },
            {
              label: 'Session Type',
              labelSX: { variant: 'h5', lineHeight: 1.8 },
              gridSX: { xs: 12, md: 6 },
              component: <Box position="relative">
                <SelectField
                  disabled={!!eventData}
                  width="100%"
                  name='sessionType'
                  control={control}
                  options={sessionTypes}
                  placeholder='Select Session'
                />
                {!allowSession && <Box display="flex" alignItems="center" gap={1} position={isMobile ? 'relative' : 'absolute'}>
                  <SvgIconWrapper name='Alert' size={16} color='error' />
                  <SUBTITLE2 color={error.main} fontSize={10}>The selected client has not set up payment on GoCardless.</SUBTITLE2>
                </Box>}
              </Box>
            },
            {
              label: ((clientDetails?.allDetails?.mandate?.status === ACTIVE) && (String(sessionType) === '1')) || (eventData?.subscriptionId && (updateAll || updateUpcoming)) ? 'Subscription' : '',
              labelSX: { variant: 'h5', lineHeight: 1.8 },
              gridSX: { xs: 12, md: 12 },
              component: (((clientDetails?.allDetails?.mandate?.status === ACTIVE) && (String(sessionType) === '1')) || (eventData?.subscriptionId && (updateAll || updateUpcoming))
                ? <Controller
                  name='subscription'
                  control={control}
                  render={({ field }) => (
                    <InfiniteSelectField
                      getSelect={(data: any) => {
                        setValue('subscription', data.value);
                      }}
                      disabled={!!eventData}
                      newPayload={{ clientId: getValues('clientId') }}
                      API={GET_SUBSCRIPTIONS_URL}
                      subscriptionID={eventData ? eventData?.subscriptionId : ''}
                      placeHolder='Select Subscrption'
                      error={!!errors.subscription}
                      helperText={errors?.subscription?.message ?? ' '}
                      width="100%"
                      field={field}
                      showAdd
                      noMoreText='No More Subscription'
                      addText='Add Subscription'
                      handleAdd={handleAddSubscription}
                    />
                  )}
                />
                : <></>)
            },
            {
              label: 'Recurrence',
              labelSX: { variant: 'h5', lineHeight: 1.8 },
              gridSX: { xs: 12, md: 12 },
              component: <SelectField width="100%" name='recurrence' control={control} options={RECURRENCE_TYPE_OPTIONS} placeholder='Recurrence' disabled={!updateAll && !isEmpty(eventData)} />
            },
            {
              label: (recurrence === 'CUSTOM') && (eventData ? (updateAll || updateUpcoming) : true) ? 'Custom Recurrence' : '',
              labelSX: { variant: 'h5', lineHeight: 1.8 },
              gridSX: { xs: 12, md: 12 },
              component: (recurrence === 'CUSTOM') && (eventData ? (updateAll || updateUpcoming) : true)
                ? <CustomRecurrence
                  date={startDate?.$d ?? watch('dateTime')}
                  getRule={(rule) => { setReccurenceRule(rule); }}
                  reccurrenceRule={eventData ? eventData.recurrence : null}
                />
                : <></>
            }
          ]}
        />
      )}
      leftButton={(
        <Button
          startIcon={(
            <SvgIconWrapper
              name="Cancel"
              size={16}
            />
          )}
          size="large"
          variant="outlined"
          color="error"
          onClick={() => {
            handleClose();
            setDefaultValues();
          }}
        >
          Cancel
        </Button>
      )}
      rightButton={(
        <Button
          startIcon={(
            <SvgIconWrapper
              name={eventData ? 'Check' : 'Add'}
              size={16}
            />
          )}
          variant="contained"
          size="large"
          onClick={() => {
            void handleSubmit(onSubmit)();
          }}
          disabled={!allowSession}
        >
          {eventData ? 'Update' : 'Add'}
        </Button>
      )}
    />
  );
};

export default SessionDialog;
