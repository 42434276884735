import React, { useEffect, useState } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { ACTIVE, PAUSE, RESUME, mobileWidth } from '../../constants/AppVarConstants';
import API_MIDDLEWARE from '../../redux/api/middleware';
import SubscriptionDialog from './SubscriptionDialog';
import Headers from './Headers';
import SubscriptionCard from './SubscriptionCard';
import CustomToolbar from './Toolbar';
import { GET_PACKAGE_LIST_URL, GET_SUBSCRIPTIONS_URL } from '../../redux/api/config';
import { useDispatch, useSelector } from 'react-redux';
import type { RootState } from '../../types/reduxState';
import { cancelSubscription, setSubscriptionData, updateSubscription } from '../../redux/actions/subscriptionActions';
import { setErrorMessage, setSuccessDialogInfo } from '../../redux/actions/appActions';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from '../common/Loader';
import { H5, SUBTITLE1 } from '../common/Typography';
import { setPackageOptions } from '../../redux/actions/packageActions';
import { APP_CUSTOM_ERROR } from '../../constants/MessageConstants';
import type { PackageType, SubscriptionType } from '../../types/common';
import { useDebounce } from '../../hooks/useDebounce';
import SubscriptionPauseDialog from './SubscriptionPauseDialog';
import { isEmpty } from 'lodash';

const Subscriptions = (): JSX.Element => {
  const dispatch = useDispatch();
  const { palette: { outline } } = useTheme();
  const isMobile = useMediaQuery(`(${mobileWidth})`);
  const { subscriptions, reFetchSubscription } = useSelector((state: RootState) => state.subscriptionState);

  const [subscriptionInfo, setSubscriptionInfo] = useState<SubscriptionType | null>(null);
  const [subscriptionModal, setSubscriptionModal] = useState(false);
  const [subscriptionPauseModal, setSubscriptionPauseModal] = useState(false);

  const [searchValue, setSearchValue] = useState('');
  const [currentOffset, setCurrentOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const [hasMorePlayers, setHasMorePlayers] = useState(false);

  const debouncedValue = useDebounce(searchValue, 500);

  const fetchSubscriptions = (flag: boolean): void => {
    if (!flag) {
      setLoading(true);
    }
    const payload = {
      limit: 10,
      offset: flag ? currentOffset + 10 : 0,
      query: debouncedValue || '*'
    };
    API_MIDDLEWARE(
      GET_SUBSCRIPTIONS_URL,
      'POST',
      {
        'Content-Type': 'application/json'
      },
      payload
    )
      .then((res) => {
        const { hits, isHasMore, offset } = res.result;
        const data = [...(flag ? subscriptions : []), ...hits];
        dispatch(setSubscriptionData(data));
        setHasMorePlayers(isHasMore);
        setCurrentOffset(offset);
        setLoading(false);
      })
      .catch((err) => {
        dispatch(setErrorMessage(err?.errorMsg));
        setLoading(false);
      });
  };

  const fetchPackages = (): void => {
    API_MIDDLEWARE(
      `${GET_PACKAGE_LIST_URL}?status=${ACTIVE}`,
      'GET',
      {
        'Content-Type': 'application/json'
      }
    )
      .then((res) => {
        const options = res?.results?.map((item: PackageType) => {
          return {
            key: item.name,
            value: item.id,
            price: item.price,
            interval: item.interval,
            currency: item.currency,
            sessions: item.sessions
          };
        });
        dispatch(setPackageOptions(options));
      })
      .catch((err) => {
        dispatch(setErrorMessage(err.errorMsg ?? APP_CUSTOM_ERROR));
      });
  };

  useEffect(() => {
    fetchSubscriptions(false);
  }, [reFetchSubscription, debouncedValue]);

  useEffect(() => {
    fetchPackages();
  }, []);

  const handleSubscriptiontDialog = (): void => {
    setSubscriptionModal((prev) => !prev);
  };

  const updateSubscriptionStatus = (data: SubscriptionType): void => {
    const payload = {
      subscriptionId: data.id,
      state: data.status === ACTIVE ? PAUSE : RESUME
    };
    if (data.status !== ACTIVE) {
      dispatch(updateSubscription(payload, () => {
        dispatch(setSuccessDialogInfo({
          title: 'Successfully Updated',
          description: 'Subscription status is successfully updated'
        }));
      }));
    }
  };

  const handleCancelSubscription = (data: SubscriptionType): void => {
    dispatch(cancelSubscription(data.id, () => {
      dispatch(setSuccessDialogInfo({
        title: 'Successfully Updated',
        description: 'Subscription status is successfully updated'
      }));
    }));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        overflow: 'hidden',
        background: '#fff',
        ...(!isMobile && {
          borderRadius: '25px',
          border: `1px solid ${outline.main}`
        })
      }}
    >
      <CustomToolbar
        noSubscriptions={isEmpty(subscriptions)}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
      <Box sx={{ overflowX: 'auto' }}>
        <Box
          id="scrollableDiv"
          sx={{
            maxHeight: `calc(100vh - ${isMobile ? '285' : '220'}px)`,
            overflowY: 'auto',
            position: 'relative'
            // ...(isMobile && {
            //   borderBottom: `1px solid ${outline.main}`
            // })
          }}>
          <Headers />
          {loading
            ? <Loader />
            : isEmpty(subscriptions)
              ? (<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" py={1}>
                <H5>No Subscriptions Found.</H5>
              </Box>)
              : (<InfiniteScroll
                style={{ overflow: 'none !important' }}
                dataLength={subscriptions.length}
                next={() => { fetchSubscriptions(true); }}
                hasMore={hasMorePlayers}
                loader={<Loader />}
                endMessage={(
                  <Box display="flex" justifyContent="center" py={1}>
                    <SUBTITLE1>No More Results.</SUBTITLE1>
                  </Box>
                )}
                scrollableTarget="scrollableDiv"
              >
                {subscriptions?.map((item: SubscriptionType) => (
                  <Box key={item?.id}>
                    <SubscriptionCard
                      subscriptionData={item}
                      onEdit={(data) => {
                        setSubscriptionInfo(data);
                        handleSubscriptiontDialog();
                      }}
                      onPauseClick={(data) => {
                        setSubscriptionInfo(data);
                        setSubscriptionPauseModal(true);
                      }}
                      onUpdateStatus={(data) => { updateSubscriptionStatus(data); }}
                      onCancelSubscription={(data) => { handleCancelSubscription(data); }}
                    />
                  </Box>
                ))}
              </InfiniteScroll>)
          }
        </Box>
      </Box>
      <SubscriptionDialog
        open={subscriptionModal}
        subscriptionInfo={subscriptionInfo}
        onClose={handleSubscriptiontDialog}
      />
      <SubscriptionPauseDialog
        fullWidth
        subscriptionInfo={subscriptionInfo}
        open={subscriptionPauseModal}
        onClose={() => { setSubscriptionPauseModal(false); }}
      />
    </Box >
  );
};

export default Subscriptions;
