import { Box, CircularProgress, Typography } from '@mui/material';

const Loader = (): JSX.Element => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <CircularProgress />
      <Typography justifyContent="center" position="fixed" marginTop="6rem">
        Loading...
      </Typography>
    </Box>
  );
};

export default Loader;
