import React from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { mobileWidth } from '../../constants/AppVarConstants';
import { H5 } from '../common/Typography';
import type { PaymentDetailsType } from '../../types/common';
import { formatDate } from '../../commonFunctions/utils';

interface PaymentCardProps {
  paymentDetails: PaymentDetailsType
}

const PaymentCard: React.FC<PaymentCardProps> = ({ paymentDetails }): JSX.Element => {
  const { palette: { text, outline } } = useTheme();
  const isMobile = useMediaQuery(`(${mobileWidth})`);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        color: text.secondary
      }}
    >
      <Box
        sx={{
          py: 2.5,
          flex: 1.5,
          minWidth: 140,
          borderBottom: `1px solid ${outline.main}`,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        <H5 pl={isMobile ? 1 : 4}>{paymentDetails?.clientName}</H5>
      </Box>
      <Box
        sx={{
          py: 2.5,
          flex: 1,
          minWidth: 140,
          textAlign: 'center',
          borderBottom: `1px solid ${outline.main}`,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        <H5>{paymentDetails?.packageName}</H5>
      </Box>
      <Box py={2.5} flex={1} minWidth={120} textAlign="center" borderBottom={`1px solid ${outline.main}`}>
        <H5>{`${paymentDetails?.symbol} ${paymentDetails?.amount?.toFixed(2)}`}</H5>
      </Box>
      <Box py={2.5} flex={1} minWidth={120} textAlign="center" borderBottom={`1px solid ${outline.main}`}>
        <H5>{formatDate(paymentDetails?.date)}</H5>
      </Box>
      <Box
        sx={{
          py: 2.5,
          flex: 1.5,
          minWidth: 200,
          textAlign: 'center',
          borderBottom: `1px solid ${outline.main}`,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        <H5>{paymentDetails?.status}</H5>
      </Box>
      <Box py={2.5} flex={1} minWidth={180} textAlign="center" borderBottom={`1px solid ${outline.main}`}>
        <H5>{paymentDetails?.id}</H5>
      </Box>
    </Box>
  );
};

export default PaymentCard;
