import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { Chart } from 'react-google-charts';
import { BODY1, H2 } from '../common/Typography';

interface CustomChartProps {
  data: any
  title: string
  options?: any
  chartType: 'Bar' | 'PieChart' | 'LineChart' | 'BarChart' | 'ColumnChart'
  allowHorzScroll?: boolean
}

const CustomChart: React.FC<CustomChartProps> = (props): JSX.Element => {
  const {
    data,
    title,
    chartType,
    options = null,
    allowHorzScroll = false
  } = props;

  return (
    <Box
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 0',
        gap: 4
      }}
    >
      <H2>{title}</H2>
      <Box sx={{ overflowX: allowHorzScroll ? 'auto' : 'hidden', overflowY: 'hidden' }}>
        <Chart
          chartType={chartType}
          width={allowHorzScroll ? '750px' : '100%'}
          height="100%"
          data={data}
          options={options}
          loader={
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: 2 }}>
              <CircularProgress />
              <BODY1>Loading</BODY1>
            </Box>
          }
        />
      </Box>
    </Box>
  );
};

export default CustomChart;
