// /* eslint-disable*/
import React, { useEffect, useState } from 'react';
import {
  FormControl,
  TextField,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  CircularProgress,
  ClickAwayListener,
  type TextFieldProps,
  Box,
  Button
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import InfiniteScroll from 'react-infinite-scroll-component';
import { setErrorMessage } from '../../redux/actions/appActions';
import { useDispatch } from 'react-redux';
import API_MIDDLEWARE from '../../redux/api/middleware';
import { useDebounce } from '../../hooks/useDebounce';
import SvgIconWrapper from './SvgIcon';
import { SUBTITLE1 } from './Typography';

interface SelectFieldv2Props {
  field?: any
  textFieldSx?: TextFieldProps
  width?: string | number
  defaultValue?: string
  subscriptionID?: string
  placeHolder?: string
  error?: boolean
  helperText?: string
  disabled?: boolean
  clearLocation?: boolean
  getSelect?: ((item: { key: string, value: string }) => void) | undefined
  showAdd?: boolean
  handleAdd?: () => void
  noMoreText?: string
  addText?: string
  API: string
  newPayload?: object
}

const InfiniteSelectField: React.FC<SelectFieldv2Props> = (props): JSX.Element => {
  const {
    field,
    textFieldSx,
    width,
    subscriptionID = '',
    defaultValue = '',
    error = false,
    placeHolder,
    helperText,
    disabled,
    getSelect,
    showAdd = false,
    handleAdd = () => { },

    noMoreText = '',
    addText = '',
    API = '',
    newPayload = {}
  } = props;

  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const [updateValue, setUpdateValue] = useState(false);

  const [results, setResults] = useState<any>([]);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [hasMore, setHasMore] = useState(false);

  const debouncedValue = useDebounce(value, 500);

  const fetchClientList = (flag: boolean): void => {
    if (!flag) {
      setLoading(true);
    }
    const payload = {
      limit: 5,
      offset: flag ? currentOffset + 5 : 0,
      query: debouncedValue || '*',
      ...newPayload
    };
    API_MIDDLEWARE(
      API,
      'POST',
      {
        'Content-Type': 'application/json'
      },
      payload
    )
      .then((res) => {
        const { hits, isHasMore, offset } = res.result;
        const keyPair = hits?.map((d: any) => {
          const packageName: string | undefined = d?.package?.name;
          const interval: string | undefined = d?.interval;
          const amount: string | undefined = String(d?.amount / 100);
          const key: any = `${packageName as string} ${interval as string} ${amount ?? ''}`;
          if (d.id === subscriptionID && !updateValue) {
            setValue(key);
          }
          return {
            value: d.id,
            key
          };
        });
        const data = [...(flag ? results : []), ...keyPair];
        setResults(data);
        setHasMore(isHasMore);
        setCurrentOffset(offset);
        setLoading(false);
      })
      .catch((err) => {
        dispatch(setErrorMessage(err?.errorMsg));
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchClientList(false);
  }, [debouncedValue]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setOpen(true);
    setSelected(false);
    setValue(e.target.value);
    setUpdateValue(true);
    getSelect?.({ key: '', value: e.target.value });
  };

  const handleSelect = (client: { key: string, value: string }): void => {
    setOpen(false);
    setSelected(true);
    setValue(client.key);
    getSelect?.(client);
  };

  const handleClickAway = (): void => {
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <FormControl sx={{ width: { width } }}>
        <TextField
          {...field}
          {...textFieldSx}
          variant="outlined"
          onClick={() => { setOpen(true); }}
          fullWidth
          onChange={(e) => { handleChange(e); }}
          value={value}
          autoComplete="off"
          disabled={disabled}
          error={error}
          placeholder={placeHolder}
          helperText={helperText}
          sx={{
            '.MuiOutlinedInput-root': {
              backgroundColor: 'common.white',
              color: 'text.secondary'
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon fontSize='medium' />
              </InputAdornment>
            )
          }}
        />
        {open
          ? (
            <List
              id="scrollableDiv"
              sx={{
                width: '100%',
                position: 'absolute',
                marginTop: '3.5rem',
                background: 'white',
                borderRadius: '5px',
                zIndex: '1000',
                border: '1px solid',
                borderColor: (theme) => theme.palette.outline.main,
                ...(
                  results?.length &&
                  open && {
                    maxHeight: '200px'
                  }
                ),
                overflowY: 'scroll',
                '::-webkit-scrollbar': {
                  width: '5px'
                },
                '::-webkit-scrollbar-track': {
                  borderRadius: '5px'
                },
                '::-webkit-scrollbar-thumb': {
                  background: '#bebfcc',
                  borderRadius: '5px'
                }
              }}
              aria-label="clients"
            >
              {loading && !selected
                ? (<ListItem disablePadding divider>
                  <ListItemButton sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress color='primary' />
                  </ListItemButton>
                </ListItem>)
                : results.length
                  ? (<InfiniteScroll
                    style={{ overflow: 'hidden' }}
                    dataLength={results.length}
                    hasMore={hasMore}
                    next={() => { fetchClientList(true); }}
                    loader={(<Box textAlign="center">
                      <CircularProgress color='primary' />
                    </Box>)}
                    endMessage={!showAdd
                      ? <Box display='flex' justifyContent="center" py={1}>
                        <SUBTITLE1>{noMoreText}</SUBTITLE1>
                      </Box>
                      : <Box p={1} display="flex" justifyContent="center">
                        <Button
                          size='large'
                          variant='contained'
                          startIcon={<SvgIconWrapper name='Add' size={16} />}
                          onClick={handleAdd}
                        >
                          {addText}
                        </Button>
                      </Box>}
                    scrollableTarget="scrollableDiv"
                  >
                    <Box>
                      {
                        results.map((item: any) => {
                          return (
                            <ListItem
                              key={item?.value}
                              disablePadding
                              onClick={() => {
                                handleSelect(item);
                              }}
                              divider
                            >
                              <ListItemButton>
                                <ListItemText
                                  primary={item.key}
                                />
                              </ListItemButton>
                            </ListItem>
                          );
                        })
                      }
                    </Box>
                  </InfiniteScroll>)
                  : (<ListItem disablePadding>
                    {!showAdd
                      ? <ListItemButton disabled sx={{ display: 'flex', justifyContent: 'center' }}>
                        {noMoreText}
                      </ListItemButton>
                      : <Box width="100%" display="flex" justifyContent="center">
                        <Button
                          size='large'
                          variant='contained'
                          startIcon={<SvgIconWrapper name='Add' size={16} />}
                          onClick={handleAdd}
                        >
                          {addText}
                        </Button>
                      </Box>}
                  </ListItem>)
              }
            </List>)
          : ' '}
      </FormControl>
    </ClickAwayListener >
  );
};

export default InfiniteSelectField;
