import { getDeviceId } from '../../commonFunctions/device';
import { ACCESS_TOKEN, REFRESH_TOKEN } from '../../constants/AppVarConstants';
import { APP_CUSTOM_ERROR, EMAIL_LINK_SENT, PASSWORD_LINK_SENT, PASSWORD_UPDATED } from '../../constants/MessageConstants';
import type { PaymentInfoType } from '../../types/common';
import type { StepOneState, SussessData } from '../../types/reduxState';
import {
  GET_ADDRESS_ID,
  GET_IMAGE_MEDIA_ID,
  PAYMENT_EXPORT_URL,
  REMOVE_PROFILE_PICTURE_URL,
  RESEND_EMAIL_VERIFICATION_URL,
  RESET_PASSWORD_URL,
  SEND_RESET_PASSWORD_LINK_URL,
  USER_DETAILS_URL,
  USER_LOGIN_URL,
  USER_LOGOUT_URL,
  USER_PAYMENT_INFO_URL,
  USER_PAYMENT_SETUP_URL,
  USER_PROFILE_URL,
  USER_SIGNUP_URL
} from '../api/config';
import API_MIDDLEWARE from '../api/middleware';
import {
  RESET_REGISTRATION_STEP_ONE,
  RESET_SUCCESS_DIALOG_INFO,
  SET_ERROR_MESSAGE,
  SET_LOADING_STATE,
  SET_REGISTRATION_STEP_ONE,
  SET_SUCCESS_DIALOG_INFO,
  SET_SUCCESS_MESSAGE,
  SET_USER_INFO,
  SET_USER_PAYMENT_INFO,
  SET_USER_ROLE
} from './actionTypes';
import { beginAjaxCall, endAjaxCall } from './ajaxStatusActions';

export const setErrorMessage = (payload: string): any => {
  return { type: SET_ERROR_MESSAGE, payload };
};

export const setSuccessMessage = (payload: string): any => {
  return { type: SET_SUCCESS_MESSAGE, payload };
};

export const setLoadingState = (payload: boolean): any => {
  return { type: SET_LOADING_STATE, payload };
};

export const setSuccessDialogInfo = (payload: SussessData): any => {
  return { type: SET_SUCCESS_DIALOG_INFO, payload };
};

export const resetSuccessDialogInfo = (): any => {
  return { type: RESET_SUCCESS_DIALOG_INFO };
};

export const setUserInfo = (payload: any): any => {
  return { type: SET_USER_INFO, payload };
};

export const setUserRole = (payload: string): any => {
  return { type: SET_USER_ROLE, payload };
};

export const setRegistrationStep1Data = (payload: StepOneState): any => {
  return { type: SET_REGISTRATION_STEP_ONE, payload };
};

export const resetRegistrationStep1Data = (): any => {
  return { type: RESET_REGISTRATION_STEP_ONE };
};

export const setUserPaymentInfo = (payload: PaymentInfoType): any => {
  return { type: SET_USER_PAYMENT_INFO, payload };
};

export const registerUser = (
  payload: any,
  onSuccess = (res?: any) => { },
  onError = () => { }
): any => {
  return (dispatch: any) => {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    dispatch(beginAjaxCall());
    API_MIDDLEWARE(
      USER_SIGNUP_URL,
      'POST',
      { 'Content-Type': 'application/json' },
      payload
    )
      .then((res) => {
        const { accessToken, refreshToken } = res.result;
        localStorage.setItem(ACCESS_TOKEN, accessToken);
        localStorage.setItem(REFRESH_TOKEN, refreshToken);
        dispatch(setUserInfo(res.result));
        dispatch(setSuccessMessage(res.message ?? 'Success'));
        dispatch(endAjaxCall());
        onSuccess(res);
      })
      .catch((err) => {
        dispatch(setErrorMessage(err.errorMsg ?? APP_CUSTOM_ERROR));
        dispatch(endAjaxCall());
        onError();
      });
  };
};

export const userLogin = (
  payload: { username: string, password: string },
  onSuccess = () => { },
  onError = () => { }
): any => {
  return (dispatch: any) => {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    dispatch(beginAjaxCall());
    API_MIDDLEWARE(
      USER_LOGIN_URL,
      'POST',
      { 'Content-Type': 'application/json' },
      payload
    )
      .then((res) => {
        const { accessToken, refreshToken } = res.result;
        localStorage.setItem(ACCESS_TOKEN, accessToken);
        localStorage.setItem(REFRESH_TOKEN, refreshToken);
        dispatch(setUserInfo(res.result));
        dispatch(setSuccessMessage(res?.message ?? 'Success'));
        onSuccess();
        dispatch(endAjaxCall());
      })
      .catch((err) => {
        dispatch(setErrorMessage(err.errorMsg ?? APP_CUSTOM_ERROR));
        dispatch(endAjaxCall());
        onError();
      });
  };
};

export const fetchUserProfile = (
  onSuccess = () => { },
  onError = () => { }
): any => {
  return (dispatch: any) => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN);
    const refreshToken = localStorage.getItem(REFRESH_TOKEN);
    dispatch(beginAjaxCall());
    API_MIDDLEWARE(
      USER_PROFILE_URL,
      'GET',
      { 'Content-Type': 'application/json' }
    )
      .then((res) => {
        const UserData = {
          accessToken,
          refreshToken,
          user: res.result
        };
        dispatch(setUserInfo(UserData));
        dispatch(endAjaxCall());
        onSuccess();
      })
      .catch((err) => {
        dispatch(setErrorMessage(err.errorMsg ?? APP_CUSTOM_ERROR));
        dispatch(endAjaxCall());
        onError();
      });
  };
};

export const setupUserPayment = (
  type: 'existing' | 'new',
  onSuccess = () => { },
  onError = () => { }
): any => {
  return (dispatch: any) => {
    dispatch(beginAjaxCall());
    API_MIDDLEWARE(
      `${USER_PAYMENT_SETUP_URL}/${type}`,
      'POST',
      { 'Content-Type': 'application/json' }
    )
      .then((res) => {
        const data = res.result;
        location.replace(data.redirectUrl);
        dispatch(endAjaxCall());
        onSuccess();
      })
      .catch((err) => {
        dispatch(setErrorMessage(err.errorMsg ?? APP_CUSTOM_ERROR));
        dispatch(endAjaxCall());
        onError();
      });
  };
};

export const getUserPaymentInfo = (
  onSuccess = () => { },
  onError = () => { }
): any => {
  return (dispatch: any) => {
    dispatch(beginAjaxCall());
    API_MIDDLEWARE(
      USER_PAYMENT_INFO_URL,
      'POST',
      { 'Content-Type': 'application/json' },
      {}
    )
      .then((res) => {
        dispatch(setUserPaymentInfo(res?.result ?? {}));
        dispatch(endAjaxCall());
        onSuccess();
      })
      .catch((err) => {
        dispatch(setErrorMessage(err.errorMsg ?? APP_CUSTOM_ERROR));
        dispatch(endAjaxCall());
        onError();
      });
  };
};

export const getUserDetails = (
  userId: string,
  onSuccess = (res: any) => { },
  onError = () => { }
): any => {
  return (dispatch: any) => {
    dispatch(beginAjaxCall());
    API_MIDDLEWARE(
      `${USER_DETAILS_URL}?id=${userId}`,
      'GET',
      { 'Content-Type': 'application/json' }
    )
      .then((res) => {
        dispatch(endAjaxCall());
        onSuccess(res?.result);
      })
      .catch((err) => {
        dispatch(setErrorMessage(err.errorMsg ?? APP_CUSTOM_ERROR));
        dispatch(endAjaxCall());
        onError();
      });
  };
};

export const updateProfile = (
  payLoad: any,
  onSuccess = () => { },
  onError = () => { }
): any => {
  return (dispatch: any) => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN);
    const refreshToken = localStorage.getItem(REFRESH_TOKEN);
    dispatch(beginAjaxCall());
    API_MIDDLEWARE(
      USER_PROFILE_URL,
      'POST',
      { 'Content-Type': 'application/json' },
      payLoad
    )
      .then((res) => {
        const UserData = {
          accessToken,
          refreshToken,
          user: res.result
        };
        dispatch(setUserInfo(UserData));
        dispatch(setSuccessMessage(res?.message));
        dispatch(endAjaxCall());
        onSuccess();
      })
      .catch((err) => {
        dispatch(setErrorMessage(err.errorMsg ?? APP_CUSTOM_ERROR));
        dispatch(endAjaxCall());
        onError();
      });
  };
};

export const sendResetPasswordLink = (
  payload: { email: string },
  onSuccess = () => { },
  onError = () => { }
): any => {
  return (dispatch: any) => {
    dispatch(beginAjaxCall());
    API_MIDDLEWARE(
      SEND_RESET_PASSWORD_LINK_URL,
      'POST',
      {
        'x-email-address': payload.email
      }
    )
      .then((res) => {
        dispatch(endAjaxCall());
        dispatch(setSuccessMessage(PASSWORD_LINK_SENT));
        onSuccess();
      })
      .catch((err) => {
        dispatch(setErrorMessage(err.errorMsg ?? APP_CUSTOM_ERROR));
        dispatch(endAjaxCall());
        onError();
      });
  };
};

export const resetPassword = (
  payload: { emailToken: string | undefined, password: string },
  onSuccess = () => { },
  onError = () => { }
): any => {
  return (dispatch: any) => {
    dispatch(beginAjaxCall());
    API_MIDDLEWARE(
      RESET_PASSWORD_URL,
      'POST',
      {
        'x-email-token': payload.emailToken,
        'x-new-password': payload.password
      }
    )
      .then((res) => {
        dispatch(endAjaxCall());
        dispatch(setSuccessMessage((PASSWORD_UPDATED)));
        onSuccess();
      })
      .catch((err) => {
        dispatch(setErrorMessage(err.errorMsg ?? APP_CUSTOM_ERROR));
        dispatch(endAjaxCall());
        onError();
      });
  };
};

export const resendEmailVerificationLink = (): any => {
  return (dispatch: any) => {
    dispatch(beginAjaxCall());
    API_MIDDLEWARE(
      RESEND_EMAIL_VERIFICATION_URL,
      'POST'
    )
      .then((res) => {
        dispatch(setSuccessMessage(EMAIL_LINK_SENT));
        dispatch(endAjaxCall());
      })
      .catch((err) => {
        dispatch(setErrorMessage(err.errorMsg ?? APP_CUSTOM_ERROR));
        dispatch(endAjaxCall());
      });
  };
};

export const userLogout = (
  onSuccess = () => { },
  onError = () => { }
): any => {
  return (dispatch: any) => {
    dispatch(beginAjaxCall());
    API_MIDDLEWARE(
      USER_LOGOUT_URL,
      'POST',
      { 'Content-Type': 'application/json' },
      { uuid: getDeviceId(), platform: 'WEB' }
    )
      .then((res) => {
        onSuccess();
        localStorage.clear();
        sessionStorage.clear();
        dispatch(resetRegistrationStep1Data());
        dispatch(setUserInfo({}));
        dispatch(endAjaxCall());
      })
      .catch((err) => {
        dispatch(setErrorMessage(err.errorMsg ?? APP_CUSTOM_ERROR));
        dispatch(endAjaxCall());
        onError();
      });
  };
};

export const removeProfilePicture = (
  onSuccess = () => { },
  onError = () => { }
): any => {
  return (dispatch: any) => {
    dispatch(beginAjaxCall());
    API_MIDDLEWARE(
      REMOVE_PROFILE_PICTURE_URL,
      'POST',
      { 'Content-Type': 'application/json' },
      {}
    )
      .then((res) => {
        onSuccess();
        dispatch(fetchUserProfile());
      })
      .catch((err) => {
        dispatch(setErrorMessage(err.errorMsg ?? APP_CUSTOM_ERROR));
        dispatch(endAjaxCall());
        onError();
      });
  };
};

export const getAddressId = async (payload: { placeId: string }): Promise<any> => {
  return await API_MIDDLEWARE(
    GET_ADDRESS_ID,
    'POST',
    { 'Content-Type': 'application/json' },
    payload
  );
};

export const getMediaId = async (payload: any): Promise<any> => {
  return await API_MIDDLEWARE(
    GET_IMAGE_MEDIA_ID,
    'POST',
    {},
    payload,
    true
  );
};

export const exportPaymentHistory = (query: string): any => {
  return (dispatch: any) => {
    dispatch(beginAjaxCall());
    API_MIDDLEWARE(
      `${PAYMENT_EXPORT_URL}?query=${query}`,
      'GET',
      {
        'Content-Type': 'application/json'
      }
    )
      .then((res) => {
        dispatch(setSuccessMessage(res?.status));
        window.open(res?.result, '_blank');
        dispatch(endAjaxCall());
      })
      .catch((err) => {
        dispatch(setErrorMessage(err.errorMsg ?? APP_CUSTOM_ERROR));
        dispatch(endAjaxCall());
      });
  };
};
