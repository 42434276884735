// /* eslint-disable*/
import React, { useEffect, useMemo, useState } from 'react';
import { debounce, isEmpty } from 'lodash';
import {
  FormControl,
  TextField,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  CircularProgress,
  ClickAwayListener
} from '@mui/material';
import SvgIconWrapper from './SvgIcon';
import { ADDRESS_AUTOCOMPLETE_URL } from '../../redux/api/config';
import { getDeviceId } from '../../commonFunctions/device';
import API_MIDDLEWARE from '../../redux/api/middleware';

interface SearchLocationProps {
  field?: any
  width?: string | number
  outterSX?: React.CSSProperties
  defaultValue?: string
  placeHolder?: string
  helperText?: string
  disabled?: boolean
  validationError?: boolean
  clearLocation?: boolean
  getPlaceId?: ((locationId: string, locationData: any) => void) | undefined
  handleSelection?: ((location: any, locationText: string, locationId: string) => void) | undefined
  clearErrors?: (() => void) | undefined
}

const SearchLocation: React.FC<SearchLocationProps> = (props): JSX.Element => {
  const {
    field,
    width,
    outterSX,
    defaultValue = '',
    placeHolder = '',
    helperText = '',
    disabled = false,
    validationError = false,
    clearLocation = false,
    getPlaceId = () => { },
    handleSelection = () => { },
    clearErrors = () => { }
  } = props;

  const [open, setOpen] = useState(false);
  const [results, setResults] = useState([]);
  const [errorApi, setErrorApi] = useState('');
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(false);
  const [value, setValue] = useState(defaultValue);

  // const storedResultsString = localStorage.getItem('locations');
  // const storedResults = storedResultsString ? JSON.parse(storedResultsString) : [];
  const sessionId: string = getDeviceId();

  const getResults = (query: any): void => {
    setLoading(true);
    API_MIDDLEWARE(
      `${ADDRESS_AUTOCOMPLETE_URL}?q=${query as string}&st=${sessionId}`,
      'GET',
      {
        'Content-Type': 'application/json'
      }
    )
      .then((data) => {
        setLoading(false);
        setResults(data?.result);
      })
      .catch((err) => {
        setLoading(false);
        setErrorApi(err?.response?.data?.message);
      });
  };

  const debounceLoadData = useMemo(() => debounce(getResults, 400), []);

  useEffect(() => {
    if (value) {
      debounceLoadData(value);
    }
  }, [value, debounceLoadData]);

  // Additional useEffect for Clearing Location
  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    } else {
      setValue('');
    }
  }, [clearLocation]);

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, location?: any): void => {
    setOpen(true);
    setSelected(false);
    setValue(e.target.value);
    getPlaceId?.(location?.placeId, location);
  };

  const handleSelect = (location: any): void => {
    setSelected(true);
    setValue(location?.description);
    getPlaceId?.(location?.placeId, location);
  };

  const handleClickAway = (): void => {
    setOpen(false);
  };

  const handleClick = (): void => {
    setOpen((prev) => !prev);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <FormControl sx={{ width: { width }, ...outterSX }}>
        <TextField
          {...field}
          variant="outlined"
          onClick={handleClick}
          fullWidth
          sx={{
            '.MuiOutlinedInput-root': {
              backgroundColor: 'common.white',
              color: 'text.secondary'
            }
          }}
          onChange={(e) => { handleAddressChange(e); }}
          onBlur={clearErrors}
          value={value}
          autoComplete="off"
          disabled={disabled}
          error={validationError}
          placeholder={placeHolder}
          helperText={helperText ?? errorApi}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <SvgIconWrapper name='Location' size={16} />
              </InputAdornment>
            )
          }}
        />
        {open
          ? (
            <List
              sx={{
                width: '100%',
                position: 'absolute',
                marginTop: '4rem',
                background: 'white',
                borderRadius: '5px',
                padding: '0',
                zIndex: '1000',
                ...(
                  results?.length &&
                  open && !selected && {
                    maxHeight: '300px',
                    border: '1px solid',
                    borderColor: (theme) => theme.palette.outline.main
                  }
                ),
                overflowY: 'scroll',
                '::-webkit-scrollbar': {
                  width: '5px'
                },

                '::-webkit-scrollbar-track': {
                  borderRadius: '5px'
                },

                '::-webkit-scrollbar-thumb': {
                  background: '#bebfcc',
                  borderRadius: '5px'
                }
              }}
              aria-label="contacts"
            >
              {loading && !selected
                ? (<ListItem disablePadding divider>
                  <ListItemButton sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress color='primary' />
                  </ListItemButton>
                </ListItem>)
                : (
                  <>
                    {/* {
                      storedResults.length && !value
                        ? storedResults.map((item: any) => {
                          return (
                            <ListItem
                              key={item.description}
                              disablePadding
                              onClick={() => {
                                handleSelect(item);
                                if (handleSelection) {
                                  handleSelection(item?.structuredFormatting?.mainText, item?.description, item?.placeId);
                                }
                                setFocus(false);
                              }}
                              divider
                            >
                              <ListItemButton>
                                <ListItemText
                                  primary={item?.description}
                                />
                              </ListItemButton>
                            </ListItem>
                          );
                        })
                        : null
                    } */}
                    {!isEmpty(results) && !selected
                      ? results.map((item: any) => {
                        return (
                          <ListItem
                            key={item.description}
                            disablePadding
                            onClick={() => {
                              handleSelect(item);
                              if (handleSelection) {
                                handleSelection(item?.structuredFormatting?.mainText, item?.description, item?.placeId);
                              }
                            }}
                            divider
                          >
                            <ListItemButton>
                              <ListItemText
                                primary={item.description}
                              />
                            </ListItemButton>
                          </ListItem>
                        );
                      })
                      : null}
                  </>)
              }
            </List>)
          : ' '}
      </FormControl>
    </ClickAwayListener>
  );
};

export default SearchLocation;
