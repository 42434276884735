export const MAX_NAME_LENGTH = 50; // number
export const MAX_FULL_NAME_LENGTH = 101; // number
export const MAX_NOTES_LENGTH = 500; // number

export const MIN_PASSWORD_LENGTH = 6; // number
export const MAX_PASSWORD_LENGTH = 40; // number
export const MIN_PHONE_LENGTH = 10; // number
export const MAX_PHONE_LENGTH = 16; // number

export const IMAGE_MAX_SIZE = 25000000;
