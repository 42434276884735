import React, { useState } from 'react';
import { Box, Button, Divider, useMediaQuery, useTheme } from '@mui/material';
import Form from '../common/Form';
import SvgIconWrapper from '../common/SvgIcon';
import { H3 } from '../common/Typography';
import { mobileWidth } from '../../constants/AppVarConstants';
import Layout from './Layout';
import { useDispatch } from 'react-redux';
import { setupUserPayment } from '../../redux/actions/appActions';
import GoCardLess from '../common/GoCardLess';

const PaymentSetup = (): JSX.Element => {
  const { palette: { text, outline, primary } } = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(`(${mobileWidth})`);

  const [accountPresent, setAccountPresent] = useState(true);

  const boxStyle = {
    py: 4,
    px: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '15px',
    borderWidth: 1,
    gap: 2,
    borderStyle: 'solid',
    borderColor: outline.main,
    cursor: 'pointer',
    '&:hover': {
      borderColor: 'primary.main'
    }
  };

  const handlePaymentSetup = (): void => {
    dispatch(setupUserPayment(accountPresent ? 'existing' : 'new'));
  };

  return (
    <Layout
      title='Get Started'
      description='Create your account now'
    >
      <Box
        sx={{
          display: 'flex',
          height: '100%',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        <Box>
          <Form
            mainSX={{
              spacing: 2
            }}
            fields={[
              {
                gridSX: { xs: 12, md: 12 },
                component: (
                  <Box>
                    {isMobile && <Divider />}
                    <H3 color={text.secondary}>Payment Setup</H3>
                  </Box>
                )
              }
            ]}
          />
          <Box
            sx={{
              mt: 3,
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              gap: 3,
              justifyContent: 'space-evenly',
              alignItems: 'center'
            }}
          >
            <Box
              sx={{
                ...boxStyle,
                borderColor: accountPresent ? primary.main : outline.main
              }}
              onClick={() => { setAccountPresent(true); }}
            >
              <Box display="flex" alignItems="center">
                <SvgIconWrapper color='primary' name='Payment' />
                <Divider sx={{ width: 30 }} />
                <SvgIconWrapper color='primary' name='Payment' />
              </Box>
              <Box textAlign='center'>
                <H3>
                  Integrate with Existing&nbsp;
                  <GoCardLess fill="black" sx={{ width: 100 }} />
                </H3>
              </Box>
            </Box>
            <Box
              sx={{
                ...boxStyle,
                borderColor: !accountPresent ? primary.main : outline.main
              }}
              onClick={() => { setAccountPresent(false); }}
            >
              <Box>
                <SvgIconWrapper color='primary' name='Add' />
              </Box>
              <Box textAlign='center'>
                <H3>
                  Create New Integration&nbsp;
                  <GoCardLess fill="black" sx={{ width: 100 }} />
                </H3>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display="flex" justifyContent="end" gap={2}>
          {/* <Button
            size='large'
            variant='outlined'
            startIcon={<SvgIconWrapper name='LeftArrow' size={16} />}
            onClick={() => { }}
          >
            Go Back
          </Button> */}
          <Button
            size='large'
            variant='contained'
            endIcon={<SvgIconWrapper name='RightArrow' size={18} />}
            onClick={handlePaymentSetup}
          >
            Gocardless Setup
          </Button>
        </Box>
      </Box >
    </Layout>
  );
};

export default PaymentSetup;
