import React from 'react';
import {
  Menu,
  type MenuProps
} from '@mui/material';

interface CustomMenuProps {
  anchorEl: any
  onClose: () => void
  children: React.ReactNode
  menuProps?: MenuProps
  anchorOrigin?: {
    vertical: 'top' | 'center' | 'bottom'
    horizontal: 'left' | 'center' | 'right'
  }
}

const CustomMenu: React.FC<CustomMenuProps> = (props): JSX.Element => {
  const {
    anchorEl = false,
    onClose = () => { },
    menuProps = null,
    anchorOrigin = {
      vertical: 'bottom',
      horizontal: 'right'
    },
    children
  } = props;

  return (
    <Menu
      {...menuProps}
      sx={{
        mt: '45px'
      }}
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      keepMounted
      transformOrigin={anchorOrigin}
      open={Boolean(anchorEl)}
      onClose={onClose}
    >
      {children}
    </Menu>
  );
};

export default CustomMenu;
