import React from 'react';
import {
  Grid,
  type TypographyProps,
  type GridProps,
  Typography,
  Box
} from '@mui/material';
import { SUBTITLE1 } from './Typography';

interface Field {
  label?: string
  sideLabel?: string
  labelSX?: TypographyProps
  gridSX?: GridProps
  component: JSX.Element
}

interface FormProps {
  fields: Field[]
  mainSX?: GridProps
}

const Form: React.FC<FormProps> = (props): JSX.Element => {
  const {
    mainSX,
    fields
  } = props;

  return (
    <Grid container {...mainSX}>
      {fields.map(({ label = '', sideLabel = '', component, gridSX, labelSX }, id) => (
        <Grid key={id} item {...gridSX}>
          {label && (
            <Box display="flex" alignItems="center" gap={1}>
              <Typography
                {...labelSX}
              >
                {label}
              </Typography>
              {sideLabel &&
                <SUBTITLE1
                  sx={{
                    color: (theme) => theme.palette.text.secondary
                  }}
                >
                  {sideLabel}
                </SUBTITLE1>
              }
            </Box>
          )}
          {component}
        </Grid>
      ))}
    </Grid>
  );
};

export default Form;
