// navigationConfig.ts
import About from '../components/About';
import Home from '../components/Home';
import Calendar from '../components/calendar/Calendar';
import ClientView from '../components/client/ClientView';
import Packages from '../components/package/Packages';
import PaymentHistory from '../components/payment/PaymentHistory';
import ProfileMenu from '../components/setting/ProfileMenu';
import Settings from '../components/setting/Settings';
import Subscriptions from '../components/subscription/Subscriptions';
import { ROUTE_CALENDAR, ROUTE_CLIENT_CALENDAR, ROUTE_CLIENT_LIST, ROUTE_DASHBOARD, ROUTE_PACKAGES, ROUTE_PAYMENT_HISTORY, ROUTE_PROFILE, ROUTE_SETTING, ROUTE_SUBSCRIPTION_LIST } from '../constants/routes';

export const navigationConfig = [
  {
    path: '/admin',
    roles: ['admin'],
    component: About
  },
  {
    path: ROUTE_DASHBOARD,
    roles: ['admin', 'user'],
    component: Home
  },
  {
    path: ROUTE_CALENDAR,
    roles: ['admin'],
    component: Calendar
  },
  {
    path: ROUTE_CLIENT_CALENDAR,
    roles: ['user', 'admin'],
    component: Calendar
  },
  {
    path: ROUTE_CLIENT_LIST,
    roles: ['user', 'admin'],
    component: ClientView
  },
  {
    path: ROUTE_SETTING,
    roles: ['admin'],
    component: Settings
  },
  {
    path: ROUTE_PROFILE,
    roles: ['admin'],
    component: ProfileMenu
  },
  {
    path: ROUTE_SUBSCRIPTION_LIST,
    roles: ['admin'],
    component: Subscriptions
  },
  {
    path: ROUTE_PAYMENT_HISTORY,
    roles: ['admin'],
    component: PaymentHistory
  },
  {
    path: ROUTE_PACKAGES,
    roles: ['admin'],
    component: Packages
  }
  // Add more routes here
];
