import React, { useState } from 'react';
import { Avatar, Badge, Box, Button, FormHelperText, IconButton, useMediaQuery } from '@mui/material';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

import SvgIconWrapper from './SvgIcon';
import { SUBTITLE1 } from './Typography';
import CustomDialog from './Dialog';
import { generateRandomString, imageCompresser } from '../../commonFunctions/utils';
import { IMAGE_MAX_SIZE } from '../../constants/common';
import { IMAGE_SIZE, IMAGE_TYPE } from '../../constants/MessageConstants';

interface ImageUploadProps {
  cropedImg?: File | null
  defaultSrc?: string
  setcropedImg?: React.Dispatch<React.SetStateAction<File | null>>
  handleImgRemove?: () => void
  handleImgUpload?: (file: File) => void
};

const ImageUpload: React.FC<ImageUploadProps> = (props): JSX.Element => {
  const {
    cropedImg = null,
    defaultSrc,
    // handleImgRemove = () => { },
    handleImgUpload = () => { },
    setcropedImg = () => { }
  } = props;
  const isMobile = useMediaQuery('only screen and (max-width: 600px)', { noSsr: true });

  const [open, setopen] = useState(false);
  const [image, setImage] = useState<any>(defaultSrc);
  const [cropper, setCropper] = useState<any>();
  const [error, setError] = useState(' ');
  const [showLoader, setShowLoder] = useState(false);
  const [fileName, setFileName] = useState(defaultSrc?.split('/').pop());

  const handleImageUpload = (): void => {
    setopen((prev) => !prev);
    setImage('');
    setError(' ');
    setFileName('');
  };

  const onChange = async (e: any): Promise<void> => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    if (files[0].type === 'image/jpeg' || files[0].type === 'image/png') {
      if (files[0].size >= IMAGE_MAX_SIZE) {
        setError(IMAGE_SIZE);
      } else {
        setFileName(files[0].name);
        setError('');
        const compressedFile = await imageCompresser(files[0]);
        const reader = new FileReader();
        reader.onload = () => {
          setImage(reader?.result ?? undefined);
        };
        reader.readAsDataURL(compressedFile);
      }
    } else {
      setError(IMAGE_TYPE);
    }
  };

  const getCropData = async (): Promise<void> => {
    const blob = await fetch(cropper.getCroppedCanvas().toDataURL()).then(async (it) => await it.blob());
    const newFile = new File([blob], `${generateRandomString(24)}.jpg`, { type: 'image/jpeg' });
    setcropedImg(newFile);
    handleImgUpload(newFile);
    handleImageUpload();
  };

  return (
    <>
      <Box>
        <Badge
          overlap='circular'
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={(
            <IconButton sx={{ p: 0 }} onClick={handleImageUpload}>
              <Avatar sx={{ bgcolor: ({ palette: { primary } }) => primary.main }}>
                <SvgIconWrapper name='Image' />
              </Avatar>
            </IconButton>
          )}
        >
          <Avatar
            variant='rounded'
            sx={{
              width: 65,
              height: 65
            }}
            src={cropedImg ? URL.createObjectURL(cropedImg) : defaultSrc}
            alt='User'
          />
        </Badge>
      </Box>
      <CustomDialog
        open={open}
        fullWidth
        title='Upload Image'
        onClose={handleImageUpload}
        content={(
          <Box>
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                flexDirection: { xs: 'column', md: 'row' },
                alignItems: { xs: 'left', md: 'center' },
                flexWrap: 'nowrap'
              }}
            >
              <Button
                variant="contained"
                component="label"
                sx={{
                  '&.MuiButton-root': {
                    width: '110px'
                  }
                }}
              >
                Choose file
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={(e) => {
                    void onChange(e);
                  }}
                />
              </Button>
              <SUBTITLE1>{fileName}</SUBTITLE1>
            </Box>
            <Box sx={{ display: `${showLoader ? 'none' : 'block'}` }}>
              <Cropper
                style={{ height: 300, width: '100%', marginTop: '1rem' }}
                zoomTo={0}
                aspectRatio={1}
                preview=".img-preview"
                src={image as string}
                viewMode={1}
                ready={() => {
                  setShowLoder(false);
                }}
                minCropBoxHeight={6}
                minCropBoxWidth={6}
                minContainerHeight={300}
                minContainerWidth={isMobile ? 300 : 552}
                background={false}
                responsive
                autoCropArea={1}
                checkOrientation={false}
                onInitialized={(instance) => {
                  setShowLoder(true);
                  setCropper(instance);
                }}
                guides
              />
            </Box>
            <Box sx={{
              display: `${showLoader ? 'flex' : 'none'}`,
              width: '100%',
              height: '300px',
              mt: 2,
              alignItems: 'center',
              flexDirection: 'column',
              gap: 2,
              justifyContent: 'center'
            }}>
              <SUBTITLE1>Select an image to upload</SUBTITLE1>
            </Box>
            <FormHelperText error={!!error}>{error || ' '}</FormHelperText>
          </Box>
        )}
        leftButton={(
          <Button
            size='large'
            variant='outlined'
            onClick={handleImageUpload}
          >
            Cancel
          </Button>
        )}
        rightButton={(
          <Button
            size='large'
            variant='contained'
            onClick={() => {
              void getCropData();
            }}
            disabled={!image}
          >
            Save
          </Button>
        )}
      />
    </>
  );
};

export default ImageUpload;
