import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { FormControl, Box } from '@mui/material';
// import { renderTimeViewClock } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import LabelDescriptionField from './LabelDescriptionField';
import dayjs from 'dayjs';

interface DateTimeFieldProps {
  name: string
  control: any
  label?: string
  sideLabel?: string
  width?: string | number
  minDate?: any
  maxDate?: any
  readOnly?: boolean
  disabled?: boolean
  errorMessage?: string
  disableFuture?: boolean
  timezone?: string
}

const DateTimeField: React.FC<DateTimeFieldProps> = (props): JSX.Element => {
  const {
    name,
    control,
    label,
    sideLabel,
    width,
    disabled,
    minDate,
    maxDate,
    timezone
  } = props;

  const [popperState, setPopperState] = useState(false);

  return (
    <Box width="100%">
      <LabelDescriptionField
        label={label}
        sideLabel={sideLabel}
      />
      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange, value },
          fieldState: { error }
        }) => (
          <FormControl sx={{ width: { width } }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                format='DD/MM/YYYY hh:mm a'
                // viewRenderers={{
                //   hours: renderTimeViewClock,
                //   minutes: renderTimeViewClock,
                //   seconds: renderTimeViewClock
                // }}
                selectedSections={null}
                open={popperState}
                onOpen={() => { setPopperState(true); }}
                onClose={() => { setPopperState(false); }}
                onChange={!popperState ? () => null : onChange}
                onAccept={(newValue) => {
                  if (popperState) {
                    onChange(newValue);
                  }
                }}
                disablePast
                value={dayjs(value)}
                ampmInClock
                maxDate={maxDate}
                minDate={minDate}
                timezone={timezone}
                sx={{
                  '.MuiOutlinedInput-input': {
                    bgcolor: 'common.white', color: 'text.secondary'
                  }
                }}
                slotProps={{
                  textField: {
                    helperText: error?.message ?? ' ',
                    error: !!error?.message,
                    onClick: () => {
                      if (!disabled) {
                        setPopperState(true);
                      }
                    },
                    sx: {
                      '.MuiOutlinedInput-notchedOutline': {
                        cursor: 'pointer',
                        pointerEvents: 'all !important',
                        borderColor: 'border.main'
                      }
                    }
                  }
                }}
              />
            </LocalizationProvider>
          </FormControl>
        )}
      />
    </Box>
  );
};

export default DateTimeField;
