import React, { useEffect, useState } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { mobileWidth } from '../../constants/AppVarConstants';
import Headers from './Headers';
import CustomToolbar from './Toolbar';
import PaymentCard from './PaymentCard';
import { useDispatch } from 'react-redux';
import { useDebounce } from '../../hooks/useDebounce';
import API_MIDDLEWARE from '../../redux/api/middleware';
import type { PaymentDetailsType } from '../../types/common';
import { PAYMENT_HISTORY_URL } from '../../redux/api/config';
import { setErrorMessage } from '../../redux/actions/appActions';
import Loader from '../common/Loader';
import InfiniteScroll from 'react-infinite-scroll-component';
import { H5, SUBTITLE1 } from '../common/Typography';
import { isEmpty } from 'lodash';

const PaymentHistory = (): JSX.Element => {
  const dispatch = useDispatch();
  const { palette: { outline } } = useTheme();
  const isMobile = useMediaQuery(`(${mobileWidth})`);

  const [searchValue, setSearchValue] = useState('');
  const [results, setResults] = useState<PaymentDetailsType[]>([]);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const [hasMorePlayers, setHasMorePlayers] = useState(false);

  const debouncedValue = useDebounce(searchValue, 500);

  const fetchPaymentHistory = (flag: boolean): void => {
    if (!flag) {
      setLoading(true);
    }
    const payload = {
      limit: 10,
      offset: flag ? currentOffset + 10 : 0,
      query: debouncedValue || '*'
    };
    API_MIDDLEWARE(
      PAYMENT_HISTORY_URL,
      'POST',
      {
        'Content-Type': 'application/json'
      },
      payload
    )
      .then((res) => {
        const { hits, isHasMore, offset } = res.result;
        const data = [...(flag ? results : []), ...hits];
        setResults(data);
        setHasMorePlayers(isHasMore);
        setCurrentOffset(offset);
        setLoading(false);
      })
      .catch((err) => {
        dispatch(setErrorMessage(err?.errorMsg));
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchPaymentHistory(false);
  }, [debouncedValue]);

  return (
    <Box
      sx={{
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        overflow: 'hidden',
        background: '#fff',
        ...(!isMobile && {
          borderRadius: '25px',
          border: `1px solid ${outline.main}`
        })
      }}
    >
      <CustomToolbar
        noHistory={isEmpty(results)}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
      <Box sx={{ overflowX: 'auto' }}>
        <Box
          id="scrollableDiv"
          sx={{
            maxHeight: `calc(100vh - ${isMobile ? '285' : '220'}px)`,
            overflowY: 'auto'
          }}>
          <Headers />
          {loading
            ? <Loader />
            : isEmpty(results)
              ? (<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" py={1}>
                <H5>No Payment History Found.</H5>
              </Box>)
              : (<InfiniteScroll
                style={{ overflow: 'none !important' }}
                dataLength={results.length}
                next={() => { fetchPaymentHistory(true); }}
                hasMore={hasMorePlayers}
                loader={<Loader />}
                endMessage={(
                  <Box display="flex" justifyContent="center" py={1}>
                    <SUBTITLE1>No More Results.</SUBTITLE1>
                  </Box>
                )}
                scrollableTarget="scrollableDiv"
              >
                {results?.map((item: PaymentDetailsType) => (
                  <Box key={item?.id}>
                    <PaymentCard
                      paymentDetails={item}
                    />
                  </Box>
                ))}
              </InfiniteScroll>)
          }
        </Box>
      </Box>
    </Box >
  );
};

export default PaymentHistory;
