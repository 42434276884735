import React, { useState } from 'react';
import { Box, Button, Stack } from '@mui/material';
import { BODY1, H3 } from './Typography';
import SvgIconWrapper from './SvgIcon';
import CustomDialog from './Dialog';
import { useDispatch, useSelector } from 'react-redux';
import { resendEmailVerificationLink, userLogout } from '../../redux/actions/appActions';
import type { RootState } from '../../types/reduxState';
import { ROUTE_LOGIN } from '../../constants/routes';
import { useNavigate } from 'react-router-dom';

const VerifyEmail = (): JSX.Element => {
  const { user } = useSelector((state: RootState) => state.app.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [linkSent, setLinkSent] = useState(false);

  const getEmailVerificationLink = (): void => {
    dispatch(resendEmailVerificationLink());
    setLinkSent(true);
  };

  const handleUserLogout = (): void => {
    dispatch(userLogout(() => {
      navigate(ROUTE_LOGIN);
    }));
  };

  return (
    <CustomDialog
      open={user && !user?.isValidEmail}
      onClose={() => { }}
      showDividers
      fullWidth
      maxWidth='xs'
      content={(
        <Stack direction="column" spacing={2}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2
            }}
          >
            <SvgIconWrapper name='LockFilled' size={45} />
            <H3 fontWeight={700}>Verify Email Id</H3>
          </Box>
          <Box display="flex" gap={1} alignItems="center">
            <BODY1>
              Please confirm & verify that you want to use this email as your freelancer account.
            </BODY1>
          </Box>
        </Stack>
      )}
      leftButton={(
        <Button
          size='large'
          variant='outlined'
          color='error'
          onClick={handleUserLogout}
          endIcon={<SvgIconWrapper name='Logout' size={14} />}
        >
          Logout
        </Button>
      )}
      rightButton={(
        <Button
          size='large'
          variant='contained'
          onClick={getEmailVerificationLink}
          endIcon={<SvgIconWrapper name='RightArrow' size={16} />}
        >
          {linkSent ? 'Resend Link' : 'Verify Now'}
        </Button>
      )}
    />
  );
};

export default VerifyEmail;
