import React from 'react';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Switch
} from '@mui/material';
import { useDispatch } from 'react-redux';
import SvgIconWrapper from '../common/SvgIcon';
import SearchField from '../common/SearchField';
import { SUBTITLE1 } from '../common/Typography';
import { exportClientList } from '../../redux/actions/clientActions';
import { Info } from '@mui/icons-material';

interface CustomToolbarProps {
  searchValue: string
  setSearchValue: React.Dispatch<React.SetStateAction<string>>
  setopenInfo: React.Dispatch<React.SetStateAction<boolean>>
  archiveValue: boolean
  setArchiveValue: React.Dispatch<React.SetStateAction<boolean>>
  noClients?: boolean
}

const CustomToolbar: React.FC<CustomToolbarProps> = (props): JSX.Element => {
  const {
    searchValue = '',
    setSearchValue,
    archiveValue = false,
    noClients = false,
    setopenInfo,
    setArchiveValue
  } = props;

  const dispatch = useDispatch();

  const alphabeticRegex = /^[A-Za-z]+$/;
  const handleSearchValue = (value: string): void => {
    if (alphabeticRegex.test(value) || value === '') {
      setSearchValue(value);
    }
  };

  const handleExportCSV = (): void => {
    dispatch(exportClientList(archiveValue ? 'INACTIVE' : 'ACTIVE'));
  };

  return (
    <Grid
      container
      sx={{
        px: { xs: 2, md: 4 },
        py: 2,
        borderBottom: (theme) => `1px solid ${theme.palette.outline.main}`,
        rowGap: { xs: 3, md: 0 }
      }}
    >
      <Grid
        item
        xs={12}
        md={7}
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2
        }}
      >
        <SearchField
          fullWidth
          value={searchValue}
          handleChange={handleSearchValue}
          placeholder='Search by name…'
        />
      </Grid>
      <Grid item xs={12} md={5}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: { xs: 'space-between', md: 'end' },
            alignItems: 'center',
            gap: 1
          }}
        >
          <SUBTITLE1 fontWeight={700}>
            Archived Clients
          </SUBTITLE1>
          <Switch
            value={archiveValue}
            onChange={(e) => {
              setSearchValue('');
              setArchiveValue(e.target.checked);
            }}
          />
          <Button
            startIcon={(
              <SvgIconWrapper
                name="Download"
                size={18}
                color={noClients ? 'disabled' : 'primary'}
              />
            )}
            variant="outlined"
            size="large"
            onClick={handleExportCSV}
            disabled={noClients}
          >
            Download
          </Button>
          <IconButton onClick={() => { setopenInfo(true); }}>
            <Info color='primary'/>
          </IconButton>
        </Box>
      </Grid>
    </Grid>
  );
};

export default CustomToolbar;
