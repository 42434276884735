import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Box, Button, Card, CardContent, CircularProgress, useTheme } from '@mui/material';
import API_MIDDLEWARE from '../../redux/api/middleware';
import SvgIconWrapper from '../common/SvgIcon';
import { H3, H5 } from '../common/Typography';
import { USER_PAYMENT_AUTHORIZE_URL } from '../../redux/api/config';
import type { PaymentInfoType } from '../../types/common';
import { ROUTE_CALENDAR } from '../../constants/routes';
import { useDispatch } from 'react-redux';
import { setErrorMessage, setUserPaymentInfo } from '../../redux/actions/appActions';
import { COMPLETED } from '../../constants/AppVarConstants';
import { APP_CUSTOM_ERROR } from '../../constants/MessageConstants';

const PaymentOnboarding = (): JSX.Element => {
  const naviagte = useNavigate();
  const dispatch = useDispatch();
  const { palette: { common, success, error } } = useTheme();

  const [verified, setVerified] = useState('');
  const [paymentInfo, setPaymentInfo] = useState<PaymentInfoType | null>(null);
  const [errMessage, setErrMessage] = useState('');

  useEffect(() => {
    const params = new URL(window.location.href).searchParams;
    const code = params.get('code');
    if (code) {
      API_MIDDLEWARE(
        USER_PAYMENT_AUTHORIZE_URL,
        'POST',
        { 'x-proxy-authenticate': code }
      )
        .then((res) => {
          setVerified('Success');
          setPaymentInfo(res.result);
          naviagte(ROUTE_CALENDAR);
          dispatch(setUserPaymentInfo(res.result));
        })
        .catch((err) => {
          setVerified('Error');
          naviagte(ROUTE_CALENDAR);
          dispatch(setErrorMessage(err?.response?.data?.message ?? APP_CUSTOM_ERROR));
          setErrMessage(err?.response?.data?.message || '');
        });
    }
  }, [location]);

  if (!verified) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        height="100vh"
        alignItems="center"
        bgcolor={common.white}
      >
        <CircularProgress />
        <H5 mt={2}>Initializing Payment Setup...</H5>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: common.white
      }}
    >
      <Card
        sx={{
          display: 'flex',
          justifyContent: 'center',
          textAlign: 'center',
          alignItems: 'center',
          width: { xs: 350, md: 475 },
          minHeight: 225,
          borderRadius: '10px',
          bgcolor: (verified === 'Success') ? '#EAF6FF' : '#F9E3E1'
        }}
      >
        <CardContent sx={{ p: 2, marginTop: '1.5rem', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {
            (verified === 'Success')
              ? <Avatar sx={{ width: 65, height: 65, bgcolor: success.main }}>
                <SvgIconWrapper sx={{ color: 'white' }} name='Confirm' size={30} />
              </Avatar>
              : <Avatar sx={{ width: 65, height: 65, bgcolor: error.main }}>
                <SvgIconWrapper sx={{ color: 'white' }} name='Alert' size={30} />
              </Avatar>
          }
          <H3 mt={2}>
            {
              (paymentInfo?.verification === COMPLETED)
                ? 'Your payment setup completed successfully'
                : (errMessage || 'Your payment setup is in-progress please submit all required documents.')
            }

          </H3>
          {(paymentInfo?.verification !== COMPLETED) && <Button
            sx={{ mt: 2 }}
            size='large'
            variant='contained'
            onClick={() => {
              if (paymentInfo?.redirectUrl) {
                location.replace(paymentInfo.redirectUrl);
              }
            }}
          >
            Continue to payment setup
          </Button>}
        </CardContent>
      </Card>
    </Box >
  );
};

export default PaymentOnboarding;
