import React from 'react';
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
  type SelectChangeEvent,
  IconButton
} from '@mui/material';
import { type ToolbarProps, type View } from 'react-big-calendar';
import { ADMIN, CALENDAR_VIEW_OPTIONS, LABELS_OPTIONS } from '../../constants/AppVarConstants';
import Filters from './Filters';
import SvgIconWrapper from '../common/SvgIcon';
import { H3, SUBTITLE2 } from '../common/Typography';
import FilterModal from './FilterModal';
import { useDispatch, useSelector } from 'react-redux';
import { setCalFilterState } from '../../redux/actions/calendarAction';
import { getNextMonthFirstDate } from '../../commonFunctions/utils';
import moment from 'moment';
import type { RootState } from '../../types/reduxState';

interface CustomToolbarProps extends ToolbarProps {
  openFilter: boolean
  isMobile?: boolean
  timeZone: string
  currentMonth: Date | string
  setCurrentMonth: any
}

const CustomToolbar: React.FC<CustomToolbarProps> = (props): JSX.Element => {
  const {
    view,
    label,
    onView,
    timeZone,
    onNavigate,
    currentMonth,
    setCurrentMonth,
    openFilter,
    isMobile
  } = props;

  const dispatch = useDispatch();
  const { userRole } = useSelector((state: RootState) => state.app);

  const handleSelect = (event: SelectChangeEvent): void => {
    onView(event.target.value as View);
  };

  const handleSelectCurrentMonth = (): void => {
    if (view === 'month') {
      const newDate = getNextMonthFirstDate(currentMonth);
      setCurrentMonth(newDate);
    }
  };

  const formatLabel = (label: string, view: string): string => {
    if (view === 'month') {
      const arr = label.split(' ');
      const dayLabel = `${arr[0].slice(0, 3)} ${arr[1]}`;
      return dayLabel;
    } else if (view === 'day') {
      const arr = label.split(' ');
      const dayLabel = `${arr[0]} ${arr[2]} ${arr[1]}`;
      return dayLabel;
    }
    const dates = label.split('–');
    const year = moment().get('year');
    if (dates[0].length > 3 && dates[1].length > 3) {
      const str1 = moment(`${dates[0]} ${year}`).format('D MMM');
      const str2 = moment(`${dates[1]} ${year}`).format('D MMM');
      return `${str1} - ${str2}`;
    }
    const startDate = dates[0].split(' ');
    const month = dates[0].split(' ')?.[0]?.slice(0, 3);
    return `${startDate[1]} ${month} - ${dates[1]} ${month}`;
  };

  return (
    <>
      <Grid
        container
        sx={{
          p: 2,
          borderBottom: (theme) => `1px solid ${theme.palette.outline.main}`
        }}
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: 2
          }}
        >
          <Box
            sx={{
              width: { xs: '100%', md: 110 }
            }}
          >
            <FormControl fullWidth>
              <Select
                value={view}
                onChange={handleSelect}
                sx={{
                  minWidth: '96px',
                  color: ({ palette }) => palette.text.secondary,
                  fontWeight: 700,
                  borderRadius: '25px'
                }}
                size='small'
              >
                {CALENDAR_VIEW_OPTIONS.map((item: { key: string, value: string }) => (
                  <MenuItem
                    key={item.key}
                    value={item.value}
                    sx={{
                      color: (theme) => theme.palette.primary.main
                    }}
                  >
                    {item.key}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              flexDirection: { xs: 'column', md: 'row' },
              gap: { xs: 0.5, md: 2 },
              ...(isMobile && {
                pb: 2
              })
            }}
          >
            <Box
              sx={{
                flex: 1,
                width: '100%',
                display: 'flex',
                justifyContent: isMobile ? 'space-between' : 'start',
                alignItems: 'center'
              }}
            >
              <IconButton onClick={() => { onNavigate('PREV'); }}>
                <SvgIconWrapper name="Backward" size={16} color='primary' />
              </IconButton>
              {!isMobile && <IconButton onClick={() => {
                handleSelectCurrentMonth();
                onNavigate('NEXT');
              }}>
                <SvgIconWrapper name="Forward" size={16} color='primary' />
              </IconButton>}
              <H3>{formatLabel(label, view)}</H3>
              {isMobile && <IconButton onClick={() => {
                handleSelectCurrentMonth();
                onNavigate('NEXT');
              }}>
                <SvgIconWrapper name="Forward" size={16} color='primary' />
              </IconButton>}
            </Box>
            <Box flex={1}>
              <SUBTITLE2
                sx={{
                  color: (theme) => theme.palette.text.secondary,
                  fontWeight: 700,
                  textTransform: 'uppercase'
                }}
              >
                {timeZone}
              </SUBTITLE2>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: { xs: 'center', md: 'end' },
              alignItems: 'center',
              gap: { xs: 1, md: 3 },
              ...(isMobile && {
                pt: 2,
                borderTop: (theme) => `1px solid ${theme.palette.outline.main}`
              })
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: { xs: 'space-between', md: 'end' },
                alignItems: 'center',
                gap: { xs: 1.5, md: 2 }
              }}
            >
              {LABELS_OPTIONS.map((item: { color: string, label: string }) => (
                <Box
                  key={item.label}
                  sx={{ display: 'flex', alignItems: 'center', gap: isMobile ? 0.5 : 1 }}
                >
                  <Box
                    sx={{
                      width: isMobile ? '14px' : '18px',
                      height: isMobile ? '14px' : '18px',
                      backgroundColor: item.color,
                      borderRadius: '10px'
                    }}
                  />
                  <Typography
                    variant='subtitle2'
                    fontWeight={300}
                    fontSize={isMobile ? 10 : 12}
                  >
                    {item.label}
                  </Typography>
                </Box>
              ))}
            </Box>
            {!isMobile
              ? userRole === ADMIN && <Button
                startIcon={(
                  <SvgIconWrapper
                    name="Filter"
                    size={16}
                    color='primary'
                  />
                )}
                variant="outlined"
                size="large"
                onClick={() => {
                  dispatch(setCalFilterState(!openFilter));
                }}
                sx={{
                  ...(openFilter && {
                    bgcolor: '#BEDDF4'
                  })
                }}
              >
                Filter
              </Button>
              : userRole === ADMIN && (
                <IconButton
                  sx={{
                    border: ({ palette: { outline } }) => `1px solid ${outline.main}`
                  }}
                  onClick={() => {
                    dispatch(setCalFilterState(true));
                  }}
                >
                  <SvgIconWrapper
                    name='Filter'
                    size={14}
                    color='primary'
                  />
                </IconButton>)}
          </Box>
        </Grid>
      </Grid>
      {isMobile && <FilterModal
        fullWidth
        applyFilter={() => { }}
      />}
      {openFilter && !isMobile && <Filters />}
    </>
  );
};

export default CustomToolbar;
