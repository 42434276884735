import {
  createTheme
} from '@mui/material';

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    white: true
  }
}
declare module '@mui/material/styles' {
  interface Palette {
    deepPink: Palette['primary']
    deepPurple: Palette['primary']
    deepOrange: Palette['primary']
    teal: Palette['primary']
    outline: Palette['primary']
    white?: Palette['primary']
  }

  interface PaletteOptions {
    deepPink?: PaletteOptions['deepOrange']
    deepPurple?: PaletteOptions['primary']
    deepOrange?: PaletteOptions['primary']
    teal?: PaletteOptions['primary']
    outline?: PaletteOptions['primary']
    white?: PaletteOptions['primary']
  }
}

const theme: object = createTheme({
  typography: {
    fontFamily: [
      'Inter',
      'Roboto',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Nunito',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    h1: {
      fontWeight: 700,
      fontSize: '2.25rem', // 36px
      lineHeight: 3.375 // 54px
    },
    h2: {
      fontWeight: 700,
      fontSize: '1.75rem' // 28px
      // lineHeight: 2.625 // 42px
    },
    h3: {
      fontWeight: 700,
      fontSize: '1.25rem', // 20px
      lineHeight: 1.875 // 30px
    },
    h4: {
      fontWeight: 500,
      fontSize: '1.25rem', // 20px
      lineHeight: 1.875 // 30px
    },
    h5: {
      fontWeight: 700,
      fontSize: '1rem', // 16px
      lineHeight: 1.5 // 24px
    },
    h6: {
      fontWeight: 700,
      fontSize: '0.875rem', // 14px
      lineHeight: 1.313 // 21px
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: '0.875rem', // 14px
      lineHeight: 1.313 // 21px
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: '0.75rem', // 12px
      lineHeight: 1.125 // 18px
    },
    body1: {
      fontWeight: 400,
      fontSize: '0.875rem', // 14px
      lineHeight: 1.313 // 21px
    },
    body2: {
      fontWeight: 400,
      fontSize: '0.75rem', // 12px
      lineHeight: 1.125 // 18px
    },
    button: {
      fontWeight: 700,
      fontSize: '0.75rem', // 12px
      lineHeight: 1.125 // 18px
    },
    caption: {
      fontWeight: 500,
      fontSize: '0.625rem', // 10px
      lineHeight: 0.938 // 15px
    }
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#0984E3',
      contrastText: '#FFF'
    },
    secondary: {
      main: '#05C09F',
      contrastText: '#FFF'
    },
    success: {
      main: '#05C09F'
    },
    info: {
      main: '#0984E3'
    },
    error: {
      main: '#D84539',
      contrastText: '#FFFFFF'
    },
    warning: {
      main: '#EAB440'
    },
    background: {
      default: '#F6FAFD'
    },
    text: {
      primary: '#212121',
      secondary: '#757575'
    },
    common: {
      white: '#FFFFFF',
      black: '#000000'
    },
    deepPink: {
      main: '#F96F77',
      light: '#FEE9EB'
    },
    deepPurple: {
      main: '#6771C5',
      light: '#E1E3F3'
    },
    deepOrange: {
      main: '#EAB440',
      light: '#FEF8EB'
    },
    teal: {
      main: '#05C09F',
      light: '#EBFAF8'
    },
    outline: {
      main: '#E8E8E8'
    },
    white: {
      main: '#fff',
      contrastText: '#0984E3'
    }
  },
  shadows: [
    'none', // 0
    '0px 2px 1px -1px rgba(0,0,0,0.15),0px 1px 1px 0px rgba(0,0,0,0.08),0px 1px 3px 0px rgba(0,0,0,0.05)', // 1
    '0px 3px 1px -2px rgba(0,0,0,0.15),0px 2px 2px 0px rgba(0,0,0,0.08),0px 1px 5px 0px rgba(0,0,0,0.05)', // 2
    '0 3px 6px rgba(0,0,0,0.08), 0 3px 6px rgba(0,0,0,0.08); transition: all 0.3s cubic-bezier(.25,.8,.25,1);', // 3
    'inset 0 1.5px 6px 0 rgba(0, 0, 0, 0.05)', // 4
    '0 -3px 22px 0 rgba(0, 0, 0, 0.16)', // 5
    '0px 3px 5px -1px rgba(0,0,0,0.15),0px 6px 10px 0px rgba(0,0,0,0.08),0px 1px 18px 0px rgba(0,0,0,0.05)', // 6
    'rgba(0, 0, 0, 0.1) 0px 4px 12px;', // 7
    '0px 4px 4px 0px #0000001A;', // 8
    '0 6px 12px rgba(0,0,0,0.10), 0 6px 12px rgba(0,0,0,0.08);', // 9
    '0px 4px 20px 0px #00000040', // 10
    '0px 6px 7px -4px rgba(0,0,0,0.15),0px 11px 15px 1px rgba(0,0,0,0.08),0px 4px 20px 3px rgba(0,0,0,0.05)',
    '0px 7px 8px -4px rgba(0,0,0,0.15),0px 12px 17px 2px rgba(0,0,0,0.08),0px 5px 22px 4px rgba(0,0,0,0.05)',
    '0px 7px 8px -4px rgba(0,0,0,0.15),0px 13px 19px 2px rgba(0,0,0,0.08),0px 5px 24px 4px rgba(0,0,0,0.05)',
    '0px 7px 9px -4px rgba(0,0,0,0.15),0px 14px 21px 2px rgba(0,0,0,0.08),0px 5px 26px 4px rgba(0,0,0,0.05)',
    '0px 8px 9px -5px rgba(0,0,0,0.15),0px 15px 22px 2px rgba(0,0,0,0.08),0px 6px 28px 5px rgba(0,0,0,0.05)',
    '0px 8px 10px -5px rgba(0,0,0,0.15),0px 16px 24px 2px rgba(0,0,0,0.08),0px 6px 30px 5px rgba(0,0,0,0.05)',
    '0px 8px 11px -5px rgba(0,0,0,0.15),0px 17px 26px 2px rgba(0,0,0,0.08),0px 6px 32px 5px rgba(0,0,0,0.05)',
    '0px 9px 11px -5px rgba(0,0,0,0.15),0px 18px 28px 2px rgba(0,0,0,0.08),0px 7px 34px 6px rgba(0,0,0,0.05)',
    '0px 9px 12px -6px rgba(0,0,0,0.15),0px 19px 29px 2px rgba(0,0,0,0.08),0px 7px 36px 6px rgba(0,0,0,0.05)',
    '0px 10px 13px -6px rgba(0,0,0,0.15),0px 20px 31px 3px rgba(0,0,0,0.08),0px 8px 38px 7px rgba(0,0,0,0.05)',
    '0px 10px 13px -6px rgba(0,0,0,0.15),0px 21px 33px 3px rgba(0,0,0,0.08),0px 8px 40px 7px rgba(0,0,0,0.05)',
    '0px 10px 14px -6px rgba(0,0,0,0.15),0px 22px 35px 3px rgba(0,0,0,0.08),0px 8px 42px 7px rgba(0,0,0,0.05)',
    '0px 11px 14px -7px rgba(0,0,0,0.15),0px 23px 36px 3px rgba(0,0,0,0.08),0px 9px 44px 8px rgba(0,0,0,0.05)',
    '0px 11px 15px -7px rgba(0,0,0,0.15),0px 24px 38px 3px rgba(0,0,0,0.08),0px 9px 46px 8px rgba(0,0,0,0.05)'
  ],
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          textTransform: 'none',
          borderRadius: '25px',
          ...(ownerState.variant === 'outlined' && {
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: theme.palette.outline.main
          })
        })
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&:hover': {
            backgroundColor: '#EAF6FF'
          }
        })
      }
    }
  }
});

export default theme;
