import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import GoCardLess from '../common/GoCardLess';
// import SvgIconWrapper from '../common/SvgIcon';

const SectionSix = (): JSX.Element => {
  return (
    <Box sx={{
      bgcolor: 'white.main',
      py: { xs: 2, md: 4 },
      scrollSnapAlign: 'start'
    }}>
      <Container sx={{
        display: 'flex',
        justifyContent: 'center'
      }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          py: { xs: 0, md: 3 },
          maxWidth: 750
        }}>
          <Typography sx={{ typography: { xs: 'h2', md: 'h1' }, py: { xs: 2, md: 0 }, fontFamily: 'Nunito, Sans-Serif !important', textAlign: 'center' }}>
            Secure Payments Powered by
            <Box component="span" color="primary.main">
              <GoCardLess fill="#0984E3" sx={{ width: 170 }} />
              {/* <SvgIconWrapper name='GoCardless' sx={{ width: 170 }} /> */}
            </Box>
          </Typography>
          <Typography variant='h5' fontWeight={600} color="text.secondary" fontFamily='Nunito, Sans-Serif !important' textAlign="center">
            We use GoCardless&apos;s powerful and secure payment processing engine, allowing you to safely collect payment from your clients while avoiding costly bank fees and administrative headaches. GoCardless’s integration is available to 2,300+ banks across the UK and Europe.          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default SectionSix;
