import initialState from './initialState';
import * as types from '../actions/actionTypes';
import type { CalendarState } from '../../types/reduxState';

interface appActions {
  type: string
  payload: any
}

export default function calendarReducer (currentState: CalendarState, action: appActions): any {
  const state = currentState || initialState.calendarState;
  switch (action.type) {
    case types.SET_ALL_SESSIONS_DATA:
      return {
        ...state,
        allSessionsData: action.payload
      };

    case types.SET_CALENDAR_EVENTS:
      return {
        ...state,
        events: action.payload
      };

    case types.SET_SESSION_TYPES:
      return {
        ...state,
        sessionTypes: action.payload
      };

    case types.REFETCH_ALL_SESSION:
      return {
        ...state,
        reFetchEvents: Number(state.reFetchEvents) + 1
      };

    case types.SET_CAL_FILTER_CLIENT_DATA:
      return {
        ...state,
        filters: {
          ...state.filters,
          clientId: action.payload.clientId,
          clientName: action.payload.clientName
        }
      };

    case types.SET_CAL_FILTER_SESSIONTYPE:
      return {
        ...state,
        filters: {
          ...state.filters,
          sessionId: action.payload
        }
      };

    case types.RESET_CAL_FILTER_DATA:
      return {
        ...state,
        filters: {
          clientId: '',
          clientName: '',
          sessionId: ''
        }
      };

    case types.TOGGLE_CAL_FILTER_STATE:
      return {
        ...state,
        openFilter: action.payload
      };

    default:
      return state;
  }
}
