import React from 'react';
import { Box, Button, Stack } from '@mui/material';
import { BODY1, H3 } from './Typography';
import CustomDialog from './Dialog';
import { useDispatch, useSelector } from 'react-redux';
import { userLogout } from '../../redux/actions/appActions';
import { ROUTE_LOGIN } from '../../constants/routes';
import { useNavigate } from 'react-router-dom';
import { COMPLETED } from '../../constants/AppVarConstants';
import type { RootState } from '../../types/reduxState';

const PaymentSetupStatusDialog = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { paymentInfo } = useSelector((state: RootState) => state.app);

  const handleUserLogout = (): void => {
    dispatch(userLogout(() => {
      navigate(ROUTE_LOGIN);
    }));
  };

  return (
    <CustomDialog
        open={!!paymentInfo.verification && paymentInfo.verification !== COMPLETED}
      onClose={() => { }}
      showDividers
      fullWidth
      maxWidth='xs'
      content={(
        <Stack direction="column" spacing={2}>
            <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2
                }}
            >
                <Box component="img" src="/Pending-Icon.svg" sx={ { width: { xs: 45, sm: 45 } }}/>
                <H3 fontSize={20} fontWeight={700}>Verification Pending</H3>
            </Box>
            <BODY1 lineHeight={1.8}>
                <div>
                Please ensure that you have submitted all
                required verification documents to GoCardless.
                You may access your Freelancer Flows account
                after the GoCardless verification process is
                complete.
                </div>
                <br/>
                <div>
                After you submit all required documents to
                GoCardless, the verification process is
                completed within 3-5 business days.
                </div>
            </BODY1>
          <Box display="flex" justifyContent="space-evenly" alignItems="" alignSelf="auto">
              <Button
                  variant="outlined"
                  size="large"
                  onClick={handleUserLogout}>
                  Logout
              </Button>
            <Button
              variant="contained"
              size="large"
              onClick={() => {
                if (paymentInfo?.redirectUrl) {
                  location.replace(paymentInfo.redirectUrl);
                }
              }}>
              Back to GoCardless
            </Button>
          </Box>
        </Stack>
      )}
    />
  );
};

export default PaymentSetupStatusDialog;
