import React from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton
} from '@mui/material';
import { H2, SUBTITLE1 } from './Typography';
import SvgIconWrapper from './SvgIcon';

interface CustomDialogProps {
  open: boolean
  onClose: () => void
  title?: string
  description?: string
  content: React.ReactNode
  leftButton?: React.ReactElement
  rightButton?: React.ReactElement
  fullWidth?: boolean
  showDividers?: boolean
  isMobileScreen?: boolean
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
}

const CustomDialog: React.FC<CustomDialogProps> = (props) => {
  const {
    open = false,
    onClose = () => { },
    title = '',
    description = '',
    content = null,
    leftButton = null,
    rightButton = null,
    fullWidth = false,
    showDividers = false,
    isMobileScreen = false,
    maxWidth = 'sm'
  } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={isMobileScreen}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      aria-labelledby="customized-dialog"
    >
      {isMobileScreen
        ? <Box
          sx={{
            boxShadow: (theme) => theme.shadows?.[10]
          }}
        >
          <Box
            sx={{
              p: 3,
              display: 'flex',
              alignItems: 'center',
              gap: 1
            }}
          >
            <IconButton onClick={onClose}>
              <SvgIconWrapper size={16} color='primary' name='Backward' />
            </IconButton>
            <H2>{title}</H2>
          </Box>
        </Box>
        : (title || description) && (
          <Box
            sx={{
              px: 3,
              pt: 3,
              display: 'flex',
              flexDirection: 'column',
              gap: 1
            }}
          >
            {title && !isMobileScreen && <H2>
              {title}
            </H2>
            }
            {description &&
              <SUBTITLE1
                sx={{
                  fontWeight: 400,
                  color: (theme) => theme.palette.text.secondary
                }}
              >
                {description}
              </SUBTITLE1>}
          </Box>)}
      <DialogContent dividers={showDividers}>
        {isMobileScreen && description &&
          <SUBTITLE1
            sx={{
              py: 1,
              fontWeight: 400,
              color: (theme) => theme.palette.text.secondary
            }}
          >
            {description}
          </SUBTITLE1>}
        {content}
      </DialogContent>
      {
        (leftButton ?? rightButton) && (
          <DialogActions
            sx={{
              px: 3,
              pt: showDividers ? 3 : 0,
              pb: 3,
              gap: 2,
              ...(isMobileScreen && {
                justifyContent: 'space-between'
              })
            }}
          >
            {leftButton}
            {rightButton}
          </DialogActions>
        )
      }
    </Dialog >
  );
};

export default CustomDialog;
