import React from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
import { getIcon } from '../../images/Icons/Icons';

interface SvgIconWrapperProps extends SvgIconProps {
  size?: string | number
  fill?: string
  name?: string
}

const SvgIconWrapper: React.FC<SvgIconWrapperProps> = ({
  size = 20,
  fill = 'none',
  children,
  name = '',
  ...otherProps
}) => {
  return (
    <SvgIcon
      style={{ fontSize: size, fill }}
      {...otherProps}
    >
      {getIcon(name)}
    </SvgIcon>
  );
};

export default SvgIconWrapper;
