import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Switch, useMediaQuery } from '@mui/material';
import SelectField from '../common/SelectField';
import CustomDialog from '../common/Dialog';
import Form from '../common/Form';
import {
  ACTIVE,
  INACTIVE,
  PACKAGE_INTERVAL_OPTIONS,
  mobileWidth
} from '../../constants/AppVarConstants';
import SvgIconWrapper from '../common/SvgIcon';
import { PackageSchema } from '../../validations/validationSchema';
import FormInputTextField from '../common/FormInputTextField';
import { SUBTITLE1 } from '../common/Typography';
import { useDispatch } from 'react-redux';
import { craetePackage, updatePackage } from '../../redux/actions/packageActions';
import type { PackageType } from '../../types/common';

interface PackageDialogProps {
  open: boolean
  onClose: () => void
  packageData?: PackageType | null
  fullWidth?: boolean
}

const PackageDialog: React.FC<PackageDialogProps> = (props): JSX.Element => {
  const {
    open = false,
    onClose = () => { },
    packageData = null,
    fullWidth = false
  } = props;

  const dispatch = useDispatch();
  const isMobile = useMediaQuery(`(${mobileWidth})`);
  const [archive, setArchive] = useState(false);

  const {
    control,
    setValue,
    reset,
    clearErrors,
    handleSubmit
  } = useForm({
    defaultValues: {
      packageName: '',
      price: '',
      numberOfSession: '',
      interval: 'MONTHLY'
    },
    resolver: yupResolver(PackageSchema),
    mode: 'onSubmit'
  });

  useEffect(() => {
    if (packageData) {
      setValue('packageName', packageData.name);
      setValue('price', String(packageData.price));
      setValue('numberOfSession', String(packageData.sessions));
      setValue('interval', packageData.interval);
      setArchive(packageData.status !== ACTIVE);
    }
  }, [packageData]);

  const onSubmit = (data: any): void => {
    const payload = {
      name: data?.packageName,
      price: Number(data?.price),
      sessions: Number(data?.numberOfSession),
      interval: data?.interval,
      status: archive ? INACTIVE : ACTIVE
    };
    if (!packageData) {
      dispatch(craetePackage(payload));
    } else {
      dispatch(updatePackage({
        id: packageData.id,
        ...payload
      }));
    }
    handleClose();
  };

  const handleClose = (): void => {
    onClose();
    if (!packageData) {
      reset();
    } else {
      reset({
        packageName: packageData.name,
        price: String(packageData.price),
        numberOfSession: String(packageData.sessions),
        interval: packageData.interval
      });
      setArchive(packageData.status !== ACTIVE);
    }
    clearErrors();
  };

  return (
    <CustomDialog
      open={open}
      onClose={handleClose}
      isMobileScreen={isMobile}
      fullWidth={fullWidth}
      title={packageData ? 'Edit Package ' : 'Create New Package'}
      description={packageData ? 'Edit package and update your details' : 'Create new package'}
      content={(
        <>
          {packageData && <Box
            sx={{
              width: 70,
              display: 'flex',
              alignItems: 'center',
              position: 'absolute',
              top: 25,
              right: isMobile ? 60 : 80
            }}
          >
            <Switch
              checked={archive}
              onChange={(e) => {
                setArchive(e.target.checked);
              }}
            />
            <SUBTITLE1 fontWeight={700}>
              Archived
            </SUBTITLE1>
          </Box>}
          <Form
            mainSX={{ columnSpacing: 3 }}
            fields={[
              {
                label: 'Package Name',
                labelSX: { variant: 'h5', lineHeight: 1.8 },
                gridSX: { xs: 12, md: 12 },
                component: <FormInputTextField width="100%" name="packageName" control={control} placeholder='Enter Package Name' disabled={packageData?.status === INACTIVE} />
              },
              {
                label: 'Package Price',
                labelSX: { variant: 'h5', lineHeight: 1.8 },
                gridSX: { xs: 12, md: 12 },
                component: <FormInputTextField type='number' width="100%" name="price" control={control} placeholder='Enter Package price' disabled={packageData?.status === INACTIVE} />
              },
              {
                label: 'Number of Sessions',
                labelSX: { variant: 'h5', lineHeight: 1.8 },
                gridSX: { xs: 12, md: 6 },
                component: <FormInputTextField type='number' width="100%" name="numberOfSession" control={control} placeholder='Enter Number of Sessions' disabled={packageData?.status === INACTIVE} />
              },
              {
                label: 'Interval',
                labelSX: { variant: 'h5', lineHeight: 1.8 },
                gridSX: { xs: 12, md: 6 },
                component: <SelectField width="100%" name='interval' control={control} options={PACKAGE_INTERVAL_OPTIONS} placeholder='Select Interval' disabled={packageData?.status === INACTIVE} />
              }
            ]}
          />
        </>
      )}
      leftButton={(
        <Button
          startIcon={(
            <SvgIconWrapper
              name="Cancel"
              size={16}
            />
          )}
          size="large"
          variant="outlined"
          color="error"
          onClick={handleClose}
        >
          Cancel
        </Button>
      )}
      rightButton={(
        <Button
          startIcon={(
            <SvgIconWrapper
              name="Check"
              size={16}
            />
          )}
          variant="contained"
          size="large"
          onClick={() => {
            void handleSubmit(onSubmit)();
          }}
        >
          {packageData ? 'Update' : 'Add'}
        </Button>
      )}
    />
  );
};

export default PackageDialog;
