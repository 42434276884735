import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Box, Button, Card, CardContent, CircularProgress, useTheme } from '@mui/material';
import API_MIDDLEWARE from '../../redux/api/middleware';
import SvgIconWrapper from '../common/SvgIcon';
import { H3, H5 } from '../common/Typography';
import { USER_PAYMENT_ACCOUNT_VERIFICATION_URL } from '../../redux/api/config';
import type { PaymentInfoType } from '../../types/common';
import { ROUTE_MAIN } from '../../constants/routes';
import { useDispatch } from 'react-redux';
import { setUserPaymentInfo } from '../../redux/actions/appActions';
import { COMPLETED } from '../../constants/AppVarConstants';
import PaymentSetupStatusDialog from '../common/PaymentSetupStatusDialog';

const GoCardlessVerification = (): JSX.Element => {
  const naviagte = useNavigate();
  const dispatch = useDispatch();
  const { palette: { common, success, error } } = useTheme();

  const [verified, setVerified] = useState('');
  const [paymentInfo, setPaymentInfo] = useState<PaymentInfoType | null>(null);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    API_MIDDLEWARE(
      USER_PAYMENT_ACCOUNT_VERIFICATION_URL,
      'POST'
    )
      .then((res) => {
        setVerified('Success');
        setPaymentInfo(res.result);
        dispatch(setUserPaymentInfo(res.result));
      })
      .catch((err) => {
        setVerified('Error');
        setErrorMessage(err?.response?.data?.message || '');
      });
  }, [location]);

  useEffect(() => {
    setTimeout(() => {
      if (paymentInfo?.verification === COMPLETED) {
        naviagte(ROUTE_MAIN);
      }
    }, 3000);
  }, [verified]);

  if (!verified) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        height="100vh"
        alignItems="center"
        bgcolor={common.white}
      >
        <CircularProgress />
        <H5 mt={2}>Initializing Payment Setup...</H5>
      </Box>
    );
  }

  if (paymentInfo?.verification !== COMPLETED) {
    return <PaymentSetupStatusDialog/>;
  }
  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: common.white
      }}
    >
      <Card
        sx={{
          display: 'flex',
          justifyContent: 'center',
          textAlign: 'center',
          alignItems: 'center',
          width: { xs: 350, md: 475 },
          minHeight: 225,
          borderRadius: '10px',
          bgcolor: (verified === 'Success') ? '#EAF6FF' : '#F9E3E1'
        }}
      >
        <CardContent sx={{ p: 2, marginTop: '1.5rem', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {
            (verified === 'Success')
              ? <Avatar sx={{ width: 65, height: 65, bgcolor: success.main }}>
                <SvgIconWrapper sx={{ color: 'white' }} name='Confirm' size={30} />
              </Avatar>
              : <Avatar sx={{ width: 65, height: 65, bgcolor: error.main }}>
                <SvgIconWrapper sx={{ color: 'white' }} name='Alert' size={30} />
              </Avatar>
          }
          <H3 mt={2}>
            {
              (paymentInfo?.verification === COMPLETED)
                ? 'Your payment setup completed successfully'
                : (errorMessage || 'Your payment setup is in-progress please submit all required documents.')
            }
          </H3>
          {(paymentInfo?.verification !== COMPLETED) && <Button
            sx={{ mt: 2 }}
            size='large'
            variant='contained'
            onClick={() => {
              if (paymentInfo?.redirectUrl) {
                location.replace(paymentInfo.redirectUrl);
              }
            }}
          >
            Continue to payment setup
          </Button>}
        </CardContent>
      </Card>
    </Box >
  );
};

export default GoCardlessVerification;
