import initialState from './initialState';
import * as types from '../actions/actionTypes';
import type { SubscriptionState } from '../../types/reduxState';

interface appActions {
  type: string
  payload: any
}

export default function subscriptionReducer (currentState: SubscriptionState, action: appActions): any {
  const state = currentState || initialState.subscriptionState;
  switch (action.type) {
    case types.SET_SUBSCRIPTIONS_DATA:
      return {
        ...state,
        subscriptions: action.payload
      };

    case types.SET_CLIENT_OPTIONS:
      return {
        ...state,
        clientOptions: action.payload
      };

    case types.REFETCH_SUBSCRIPTIONS:
      return {
        ...state,
        reFetchSubscription: Number(state.reFetchSubscription) + 1
      };

    default:
      return state;
  }
}
