import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import imageCompression from 'browser-image-compression';
import { CURRENCY_OPETIOS } from '../constants/AppVarConstants';

dayjs.extend(customParseFormat);

export const formatDate = (dateString: Date | string | undefined): string => {
  if (!dateString) {
    return '';
  }
  const formattedDate = dayjs(dateString).format('D MMM YYYY');
  return formattedDate;
};

export const capitalFirstLetter = (str: string): string => {
  return str?.charAt(0).toUpperCase() + str?.toLowerCase().slice(1);
};

export const formatTimeRange = (startDate: Date | string | undefined, endDate: Date | string | undefined, timeZone?: string): string => {
  if (startDate && endDate) {
    const options: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
      timeZone
    };

    const formattedStart = new Intl.DateTimeFormat('en-US', options).format(new Date(startDate));
    const formattedEnd = new Intl.DateTimeFormat('en-US', options).format(new Date(endDate));

    return `${formattedStart} - ${formattedEnd}`;
  }
  return '';
};

export const getDateDetails = (dateString: string | Date): { dayOfWeek: string, dayOfMonth: string, weekOfMonth: number } => {
  const date = dayjs(dateString);

  return {
    dayOfWeek: date.format('dddd'),
    dayOfMonth: date.format('D'),
    weekOfMonth: getWeekOfMonth(date)
  };
};

export const getDateAfterMonth = (dateString: string | Date): string => {
  const date = dayjs(dateString);
  const newDate = date.add(35, 'day').format('YYYYMMDDTHHmmss');
  return `${newDate}Z`;
};

export const getUntilDate = (dateString: string | Date): Date => {
  const date = dayjs(dateString).format('YYYY-MM-DD');
  const newDate = dayjs(date).subtract(1, 'day').hour(23).minute(59).second(59);
  return newDate.toDate();
};

export const checkForPastDate = (dateString: string | Date, dayBefore: boolean = false): boolean => {
  let currentDate = dayjs();
  if (dayBefore) {
    currentDate = currentDate.subtract(1, 'day').hour(23).minute(59).second(59);
  }
  return dayjs(dateString).isBefore(currentDate);
};

export const checkForValidDate = (lastDate: string | Date, evenDate: string | Date): boolean => {
  const endDate = dayjs(lastDate);
  const sessionDate = dayjs(evenDate);
  return sessionDate.isBefore(endDate);
};

export const getNextMonthFirstDate = (dateString: string | Date): Date => {
  const nextMonthFirstDate = dayjs(dateString)
    .add(1, 'month')
    .startOf('month')
    .toDate();

  return nextMonthFirstDate;
};

const getWeekOfMonth = (date: dayjs.Dayjs): number => {
  const weekOfMonth = Math.floor((date.date() - 1) / 7) + 1;
  return weekOfMonth;
};

export const getWeekOfMonthlabel = (weekOfMonth: number): string => {
  switch (weekOfMonth) {
    case 1:
      return 'first';
    case 2:
      return 'second';
    case 3:
      return 'third';
    case 4:
      return 'fourth';
    default:
      return 'last';
  }
};

export const calculateAge = (date: Date | string): number => {
  const today = dayjs();
  const birthday = dayjs(date);
  const age = today.diff(birthday, 'year');
  return age;
};

export const getEndDate = (date: Date | string, minutes: number): Date => {
  const originalDate = dayjs(date);
  const newDate = originalDate.add(minutes, 'minute');
  return newDate.toDate();
};

export const checkEqualDates = (date1: Date | string, date2: Date | string): boolean => {
  const dayjsDate1 = dayjs(date1);
  const dayjsDate2 = dayjs(date2);

  // Compare just the date parts (year, month, day)
  return dayjsDate1.isSame(dayjsDate2, 'day');
};

export const checkEqualDateAndTime = (date1: Date | string, date2: Date | string): boolean => {
  const dayjsDate1 = dayjs(date1);
  const dayjsDate2 = dayjs(date2);

  return dayjsDate1.isSame(dayjsDate2);
};

export const getMinuteDifference = (startDate: string | Date, endDate: string | Date): number => {
  const start = dayjs(startDate);
  const end = dayjs(endDate);

  const differenceInMinutes = end.diff(start, 'minute');
  return differenceInMinutes;
};

export const getEventTypeColor = (status: string): string => {
  switch (status.toLowerCase()) {
    case 'incomplete':
      return '#F96F77';
    case 'pending':
      return '#EAB440';
    case 'paid':
      return '#05C09F';
    default:
      return '#6771C5';
  }
};

export const formatDateToMMDDYY = (date: Date): string => {
  const options: Intl.DateTimeFormatOptions = {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit'
  };
  return date.toLocaleDateString('en-US', options);
};

export const imageCompresser = async (file: any): Promise<any> => {
  if (file === null) {
    return file;
  }
  const imageFile = file?.length > 0 ? file[0] : file;
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 500,
    maxIteration: 30,
    useWebWorker: true
  };
  const result = await imageCompression(imageFile, options);
  const fileObject = new File([result], result.name);
  return fileObject;
};

export const generateRandomString = (length: number): string => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const capitalizeWord = (str: string): string => {
  if (str.length === 0) {
    return str;
  }
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const getCurrencySymbol = (code: string = 'GBP'): string => {
  const data = CURRENCY_OPETIOS.filter((item) => item.code === code);
  return data?.[0]?.symbol;
};

export const getPaymentEndDate = (date: string, interval: string): string => {
  const repeatType: any = interval?.slice(0, -2)?.toLowerCase();
  const endDate = dayjs(date);
  if (repeatType === 'week') {
    return endDate.add(6, 'day').format('YYYY-MM-DD');
  } else if (repeatType === 'year') {
    return endDate.add(365, 'day').format('YYYY-MM-DD');
  }
  return endDate.add(1, repeatType).format('YYYY-MM-DD');
};

export const isDateInRange = (date: string | Date, startDate: string, endDate: string): boolean => {
  const currentDate = dayjs(date);
  const dtStart = dayjs(startDate);
  const dtEnd = dayjs(endDate).hour(23).minute(59).second(59);

  return dtStart.isSame(currentDate, 'day') || dtEnd.isSame(currentDate, 'day') || (currentDate.isAfter(dtStart, 'day') && currentDate.isBefore(dtEnd, 'day'));
};

export const getDashBoardMonthOptions = (): any => {
  const currentDate = dayjs();
  const currentMonth = currentDate.date(1);
  const lastMonth = currentDate.subtract(1, 'month').date(1);
  const secondLastMonth = currentDate.subtract(2, 'month').date(1);
  const thirdLastMonth = currentDate.subtract(3, 'month').date(1);

  const data = [
    { key: currentMonth.format('MMMM YYYY'), value: currentMonth.format('YYYY-MM-DD') },
    { key: lastMonth.format('MMMM YYYY'), value: lastMonth.format('YYYY-MM-DD') },
    { key: secondLastMonth.format('MMMM YYYY'), value: secondLastMonth.format('YYYY-MM-DD') },
    { key: thirdLastMonth.format('MMMM YYYY'), value: thirdLastMonth.format('YYYY-MM-DD') }
  ];

  return data;
};

export const getLastDateOfMonth = (inputDate: string): any => {
  const date = dayjs(inputDate).endOf('month').hour(23).minute(59).second(59);
  return date.toDate();
};

export const getTotalDatesInMonth = (inputDate: string): number => {
  const date = dayjs(inputDate).endOf('month').format('YYYY-MM-DD');
  const lastDate = date.split('-')[2];
  return Number(lastDate);
};

export const getDatesAtWeeklyInterval = (startDate: string, endDate: string): string[] => {
  const dates: string[] = [];
  let currentDate = dayjs(startDate);

  while (currentDate.isBefore(endDate) || currentDate.isSame(endDate)) {
    dates.push(currentDate.format('YYYY-MM-DD')); // Adjust the format as needed
    currentDate = currentDate.add(7, 'day');
  }
  return dates;
};

export const isDateInCurrentMonth = (startDate: string): boolean => {
  const currentDate = dayjs();
  const targetDate = dayjs(startDate);

  return currentDate.isSame(targetDate, 'month');
};

export const copyToClipboard = (
  text: string,
  onSuccess = () => { },
  onError = () => { }
): void => {
  navigator.clipboard.writeText(text)
    .then(() => {
      onSuccess();
    }).catch(() => {
      onError();
    });
};

export const getCurrentTimeZome = (): string => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return timeZone;
};

export const getDaysPassed = (dateString: string): number => {
  const date = dayjs(dateString, 'YYYY-MM-DD');
  const daysPassed = dayjs().diff(date, 'days');

  return daysPassed;
};
