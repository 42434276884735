import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Avatar,
  Box,
  Button,
  IconButton,
  ListItemIcon,
  MenuItem,
  Stack,
  Toolbar,
  useTheme
} from '@mui/material';
import { H1, H2, H5, SUBTITLE2 } from './Typography';
import SvgIconWrapper from './SvgIcon';
import { useLocation, useNavigate } from 'react-router-dom';
import Drawer from './Drawer';
import { MENU_OPTIONS } from '../json/appbar';
import ClientDialog from '../client/ClientDialog';
import SessionDialog from '../calendar/SessionDialog';
import RequestSession from '../calendar/RequestSession';
import { useDispatch, useSelector } from 'react-redux';
import { addNewSession } from '../../redux/actions/calendarAction';
import { ROUTE_CALENDAR, ROUTE_CLIENT_LIST, ROUTE_LOGIN, ROUTE_PACKAGES, ROUTE_PAYMENT_HISTORY, ROUTE_SETTING, ROUTE_SUBSCRIPTION_LIST } from '../../constants/routes';
import { setSuccessDialogInfo, setSuccessMessage, userLogout } from '../../redux/actions/appActions';
import type { RootState } from '../../types/reduxState';
import CustomMenu from './CustomMenu';
import SubscriptionDialog from '../subscription/SubscriptionDialog';
import PackageDialog from '../package/PackageDialog';
import { copyToClipboard } from '../../commonFunctions/utils';
import { CLIENTS_LIST_DATA, CLIENT_SEARCH_VALUE, USER } from '../../constants/AppVarConstants';

interface HeaderProps {
  isMobile?: boolean
}

const Header: React.FC<HeaderProps> = (props): JSX.Element => {
  const {
    isMobile = false
  } = props;

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user: { user }, userRole } = useSelector((state: RootState) => state.app);

  const { palette: { primary }, shadows } = useTheme();
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [anchorElMore, setAnchorElMore] = useState<null | HTMLElement>(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [requestSession, setRequestSession] = useState(false);
  const [clientDialog, setClientDialog] = useState(false);
  const [sessionDialog, setSessionDialog] = useState(false);
  const [packageDialog, setPackageDialog] = useState(false);
  const [subscriptionModal, setSubscriptionModal] = useState(false);

  const params = new URL(window.location.href).searchParams;
  const redirectURL = params.get('redirect');

  useEffect(() => {
    if (redirectURL) {
      handleClientDialog();
    }
  }, [location.pathname]);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (): void => {
    setAnchorElUser(null);
  };

  const toggleMenu = (): void => {
    setOpenMenu((prev) => !prev);
  };

  const handleNavigate = (path: string): void => {
    navigate(path);
    setOpenMenu(false);
    localStorage.removeItem(CLIENTS_LIST_DATA);
    localStorage.removeItem(CLIENT_SEARCH_VALUE);
  };

  const handleNavigateRoute = (route: string): void => {
    navigate(route);
    setAnchorElUser(null);
    setAnchorElMore(null);
  };

  const handleClientDialog = (): void => {
    setClientDialog((prev) => !prev);
    setAnchorElMore(null);
  };

  const handleRequestSessionDialog = (): void => {
    setRequestSession((prev) => !prev);
  };

  const handleSessionDialog = (): void => {
    setSessionDialog((prev) => !prev);
  };

  const handlePackageDialog = (): void => {
    setPackageDialog((prev) => !prev);
  };

  const handleOpenUMoreMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorElMore(event.currentTarget);
  };

  const handleSubscriptiontDialog = (): void => {
    setSubscriptionModal((prev) => !prev);
    setAnchorElMore(null);
  };

  const setTitle = (): string => {
    if (pathname.includes(ROUTE_CALENDAR)) {
      return 'Calendar';
    } else if (pathname.includes(ROUTE_CLIENT_LIST)) {
      return 'Clients';
    } else if (pathname.includes(ROUTE_SETTING) && isMobile) {
      return 'Settings';
    } else if (pathname.includes(ROUTE_SUBSCRIPTION_LIST)) {
      return 'Subscription List';
    } else if (pathname.includes(ROUTE_PACKAGES)) {
      return 'Packages';
    } else if (pathname.includes(ROUTE_PAYMENT_HISTORY)) {
      return 'Payment History';
    }
    return 'Dashboard';
  };

  const handleAddSession = (data: any): void => {
    dispatch(addNewSession(data, () => {
      dispatch(setSuccessDialogInfo({
        title: 'Successfully Created',
        description: 'Your session has been created successfully'
      }));
    }));
  };

  const handleUserLogout = (data: any): void => {
    dispatch(userLogout(() => {
      navigate(ROUTE_LOGIN);
    }));
  };

  const handleShareLink = (): void => {
    const baseUrl = window.location.origin;
    const shareUrl = `${baseUrl}/client/calendar/${user?.id as string}`;
    copyToClipboard(shareUrl, () => {
      dispatch(setSuccessMessage('Link copied successfully.'));
      handleCloseUserMenu();
    });
  };

  return (
    <>
      <AppBar
        position='fixed'
        color='inherit'
      >
        <Toolbar
          id="Appbar"
          sx={{
            display: 'flex',
            py: isMobile ? 2 : 0,
            justifyContent: 'space-between',
            boxShadow: shadows[7]
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: isMobile ? 'space-between' : 'start',
              gap: 2
            }}
          >
            <Stack direction="row" spacing={2} alignItems="center">
              {(userRole !== USER && !isMobile) && <IconButton onClick={toggleMenu}>
                <SvgIconWrapper name='Menu' size={28} />
              </IconButton>}
              <H2>
                {setTitle()}
              </H2>
            </Stack>
            <Box>
              {pathname.includes(ROUTE_CALENDAR) && userRole !== USER &&
                <Button
                  startIcon={(
                    <SvgIconWrapper name='Add' size={16} />
                  )}
                  size='large'
                  variant='contained'
                  onClick={handleSessionDialog}
                >
                  Session
                </Button>
              }
              {pathname.includes(ROUTE_CALENDAR) && userRole === USER &&
                <Button
                  startIcon={(
                    <SvgIconWrapper name='Add' size={16} />
                  )}
                  size='large'
                  variant='contained'
                  onClick={handleRequestSessionDialog}
                >
                  Request a Session
                </Button>
              }
              {pathname.includes(ROUTE_PACKAGES) &&
                <Button
                  startIcon={(
                    <SvgIconWrapper name='Add' size={16} />
                  )}
                  size='large'
                  variant='contained'
                  onClick={handlePackageDialog}
                >
                  Create
                </Button>
              }
              {(pathname.includes(ROUTE_CLIENT_LIST) || pathname.includes(ROUTE_SUBSCRIPTION_LIST)) &&
                <>
                  <IconButton
                    sx={{ p: 0.5 }}
                    onClick={handleOpenUMoreMenu}
                  >
                    <Avatar
                      sx={{
                        width: 32,
                        height: 32,
                        bgcolor: primary.main
                      }}
                    >
                      <SvgIconWrapper
                        sx={{
                          color: 'white'
                        }}
                        name='More'
                        size={22}
                      />
                    </Avatar>
                  </IconButton>
                  <CustomMenu
                    anchorEl={anchorElMore}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left'
                    }}
                    onClose={() => {
                      setAnchorElMore(null);
                    }}
                  >
                    {
                      pathname.includes(ROUTE_CLIENT_LIST)
                        ? <Box>
                          <MenuItem sx={{ px: 2, mb: 1 }} onClick={handleClientDialog}>
                            <ListItemIcon>
                              <SvgIconWrapper size={14} name='Add' color='primary' />
                            </ListItemIcon>
                            <SUBTITLE2 color={primary.main} textAlign="center" fontWeight={700}>New Client</SUBTITLE2>
                          </MenuItem>
                          <MenuItem sx={{ px: 2 }} onClick={() => { handleNavigateRoute(ROUTE_SUBSCRIPTION_LIST); }}>
                            <ListItemIcon>
                              <SvgIconWrapper size={16} name='File' color='primary' />
                            </ListItemIcon>
                            <SUBTITLE2 color={primary.main} textAlign="center" fontWeight={700}>View Subscriptions</SUBTITLE2>
                          </MenuItem>
                        </Box>
                        : <Box>
                          <MenuItem sx={{ px: 2, mb: 1 }} onClick={handleSubscriptiontDialog}>
                            <ListItemIcon>
                              <SvgIconWrapper size={14} name='Add' color='primary' />
                            </ListItemIcon>
                            <SUBTITLE2 color={primary.main} textAlign="center" fontWeight={700}>New Subscription</SUBTITLE2>
                          </MenuItem>
                          <MenuItem sx={{ px: 2 }} onClick={() => { handleNavigateRoute(ROUTE_CLIENT_LIST); }}>
                            <ListItemIcon>
                              <SvgIconWrapper size={16} name='User' color='primary' />
                            </ListItemIcon>
                            <SUBTITLE2 color={primary.main} textAlign="center" fontWeight={700}>View Clients</SUBTITLE2>
                          </MenuItem>
                        </Box>
                    }
                  </CustomMenu>
                </>
              }
            </Box>
          </Box>
          {!isMobile && userRole !== USER && <Box>
            <IconButton sx={{ p: 0 }} onClick={handleOpenUserMenu}>
              <Avatar alt={user?.firstName} src={user?.imageUrl} />
            </IconButton>
            <CustomMenu
              anchorEl={anchorElUser}
              onClose={handleCloseUserMenu}
            >
              <MenuItem sx={{ px: 2 }} onClick={() => { handleNavigateRoute(ROUTE_SETTING); }}>
                <ListItemIcon>
                  <SvgIconWrapper size={16} name='Setting' color='primary' />
                </ListItemIcon>
                <H5 textAlign="center" fontWeight={700}>Settings</H5>
              </MenuItem>
              <MenuItem sx={{ px: 2 }} onClick={handleShareLink}>
                <ListItemIcon>
                  <SvgIconWrapper size={16} name='Share' color='primary' />
                </ListItemIcon>
                <H5 textAlign="center" fontWeight={700}>Share Link</H5>
              </MenuItem>
              <MenuItem sx={{ px: 2 }} onClick={handleUserLogout}>
                <ListItemIcon>
                  <SvgIconWrapper size={16} name='Logout' color='primary' />
                </ListItemIcon>
                <H5 textAlign="center" fontWeight={700}>Logout</H5>
              </MenuItem>
            </CustomMenu>
          </Box>}
        </Toolbar>
      </AppBar>
      <Drawer
        open={!isMobile && openMenu}
        anchor="left"
        onOpen={toggleMenu}
        onClose={toggleMenu}
      >
        <Box width="20vw">
          <Stack direction="column">
            <Box display="flex" gap={3} alignItems="center" p={3}>
              <SvgIconWrapper name="Menu" size={24} color="primary" />
              <H1 lineHeight={2}>Menu</H1>
            </Box>
            <Stack direction="column">
              {MENU_OPTIONS.map((item: { name: string, path: string, icon: string, size: number }, id) => (
                <Box
                  py={2}
                  px={3}
                  key={id}
                  display="flex"
                  gap={2}
                  alignItems="center"
                  sx={{
                    background: item.path === pathname ? '#EAF6FF' : '#FFF',
                    cursor: 'pointer'
                  }}
                  onClick={() => { handleNavigate(item.path); }}
                >
                  <SvgIconWrapper name={item.icon} size={item.size} color="primary" />
                  <H5 fontWeight={700} lineHeight={2}>{item.name}</H5>
                </Box>
              ))}
            </Stack>
          </Stack>
        </Box>
      </Drawer>
      <ClientDialog
        open={clientDialog}
        onClose={handleClientDialog}
      />
      <SessionDialog
        open={sessionDialog}
        makeApiCall
        onClose={handleSessionDialog}
        handleAddSession={handleAddSession}
      />
      <RequestSession
        open={requestSession}
        onClose={handleRequestSessionDialog}
      />
      <PackageDialog
        open={packageDialog}
        onClose={handlePackageDialog}
      />
      <SubscriptionDialog
        makeApiCall
        open={subscriptionModal}
        onClose={handleSubscriptiontDialog}
      />
    </>
  );
};

export default Header;
