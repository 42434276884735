import * as Yup from 'yup';
import {
  ADDRESS_REQUIRED,
  BIRTHDATE_REQUIRED,
  EMAIL_INVALID,
  EMAIL_REQUIRED,
  FIRSTNAME_REQUIRED,
  FULLNAME_NO_SPECIAL,
  FULLNAME_REQUIRED,
  FULL_NAME_MAX_LENGTH,
  GENDER_REQUIRED,
  LASTNAME_REQUIRED,
  NAME_MAX_LENGTH,
  NAME_NO_WHITESPACE,
  NOTES_MAX_LENGTH,
  PASSWORD_MATCH,
  PASSWORD_NO_WHITESPACE,
  PASSWORD_REQUIRED,
  PHONE_MIN_LENTH,
  PHONE_NUMBER_REQUIRED,
  TYPE_DATE
} from '../constants/MessageConstants';
import { MAX_FULL_NAME_LENGTH, MAX_NAME_LENGTH, MAX_NOTES_LENGTH, MIN_PHONE_LENGTH } from '../constants/common';
import { calculateAge, checkForPastDate } from '../commonFunctions/utils';

const FisrtNameSchema = Yup.string()
  .required(FIRSTNAME_REQUIRED)
  .matches(/^[A-Za-z \-']+$/, FULLNAME_NO_SPECIAL)
  .matches(/^(?!\s+$)/, NAME_NO_WHITESPACE)
  .max(MAX_NAME_LENGTH, NAME_MAX_LENGTH);

export const LastNameSchema = Yup.string()
  .required(LASTNAME_REQUIRED)
  .matches(/^[A-Za-z \-']+$/, FULLNAME_NO_SPECIAL)
  .matches(/^(?!\s+$)/, NAME_NO_WHITESPACE)
  .max(MAX_NAME_LENGTH, NAME_MAX_LENGTH);

export const FullNameSchema = Yup.string()
  .required(FULLNAME_REQUIRED)
  .matches(/^[A-Za-z \-']+$/, FULLNAME_NO_SPECIAL)
  .matches(/^(?!\s+$)/, NAME_NO_WHITESPACE)
  .max(MAX_FULL_NAME_LENGTH, FULL_NAME_MAX_LENGTH);

export const EmailSchema = Yup.string()
  .required(EMAIL_REQUIRED)
  .email(EMAIL_INVALID)
  .matches(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-\\.]+$/, EMAIL_INVALID);

export const PasswordSchema = Yup.string()
  .test('no-whitespace', PASSWORD_NO_WHITESPACE, function (value) {
    if (value) {
      return !/\s/.test(value);
    }
  })
  .required(PASSWORD_REQUIRED);

export const StepOneSchema = Yup.object().shape({
  firstName: FisrtNameSchema,
  lastName: LastNameSchema,
  phone: Yup.array().of(
    Yup.object().shape({
      isoCode: Yup.string(),
      phoneNumber: Yup.string()
    })
  ),
  email: EmailSchema,
  password: PasswordSchema,
  confirmPassword: PasswordSchema
    .oneOf([Yup.ref('password')], PASSWORD_MATCH)
});

export const StepTwoSchema = Yup.object().shape({
  howYouFind: Yup.string()
    .required('Field is required.'),
  experience: Yup.string()
    .required('Field is required.'),
  expertise: Yup.string()
});

export const ClientDialogSchema = Yup.object().shape({
  firstName: FisrtNameSchema,
  lastName: LastNameSchema,
  email: EmailSchema,
  gender: Yup.string()
    .required(GENDER_REQUIRED),
  phone: Yup.array().of(
    Yup.object().shape({
      isoCode: Yup.string(),
      phoneNumber: Yup.string()
        .required(PHONE_NUMBER_REQUIRED)
        .min(MIN_PHONE_LENGTH, PHONE_MIN_LENTH)
    })
  ),
  placeId: Yup.string(),
  address: Yup.string()
    .required(ADDRESS_REQUIRED),
  birthDate: Yup.mixed()
    .test('is-valid-date', BIRTHDATE_REQUIRED, function (value) {
      if (!value) {
        return false;
      }
      return true;
    })
    .test('is-valid-age', 'The client should be at least 16 years old.', function (value: any) {
      const userAge = calculateAge(value);
      return userAge >= 16;
    })
    .typeError(TYPE_DATE)
    .nullable()
    .default(null),
  startDate: Yup.mixed()
    .test('is-valid-date', 'Start date is required.', function (value) {
      if (!value) {
        return false;
      }
      return true;
    })
    .typeError(TYPE_DATE)
    .nullable()
    .default(null),
  note: Yup.string()
    .max(MAX_NOTES_LENGTH, NOTES_MAX_LENGTH)
});

export const SettingsSchema = Yup.object().shape({
  firstName: FisrtNameSchema,
  lastName: LastNameSchema,
  email: EmailSchema,
  timezone: Yup.string()
    .required('Time zone is required.'),
  currencyCode: Yup.string()
    .required('Currency code is required.'),
  defaultTime: Yup.string()
});

export const AddSessionSchema = Yup.object().shape({
  clientDetails: Yup.object().shape({
    allDetails: Yup.object().shape({
      mandate: Yup.object().shape({
        status: Yup.string().nullable()
      }).nullable()
    })
  }),
  clientName: Yup.string(),
  clientId: Yup.string()
    .required('Client Name is required.'),
  sessionId: Yup.string(),
  dateTime: Yup.mixed()
    .test('is-valid-date', 'Date is required.', function (value) {
      if (!value) {
        return false;
      }
      return true;
    })
    .test('is-valid-dateTime', 'Please select a upcoming date and time.', function (value: any) {
      if (value) {
        const sessionId = this.parent.sessionId;
        const slectedDate = checkForPastDate(new Date(value), sessionId);
        return !slectedDate;
      }
    })
    .typeError(TYPE_DATE)
    .nullable()
    .default(null),
  duration: Yup.string()
    .required('Duration is required.'),
  sessionType: Yup.string()
    .required('Session type is required.'),
  recurrence: Yup.string()
    .required('Recurrence is required.'),
  subscription: Yup.string().test(
    'required-based-on-conditions',
    'Subscription is required.',
    function (value) {
      const status = this.parent.clientDetails?.allDetails?.mandate?.status;
      const sessionType = this.parent.sessionType;

      if (status === 'ACTIVE' && sessionType === '1') {
        return !!value;
      }

      return true;
    }
  )
});

export const CancelSessionSchema = Yup.object().shape({
  reason: Yup.string()
    .required('Note is required.')
    .max(MAX_NOTES_LENGTH, NOTES_MAX_LENGTH)
    .matches(/^(?!\s+$)/, NAME_NO_WHITESPACE),
  cancelAll: Yup.boolean()
});

export const SubscriptionSchema = Yup.object().shape({
  clientName: Yup.string(),
  subscriptionId: Yup.string(),
  clientId: Yup.string()
    .required('Client Name is required.'),
  mandateId: Yup.string(),
  packageId: Yup.string().test(
    'required-based-on-conditions',
    'Package is required.',
    function (value) {
      const subId = this.parent.subscriptionId;
      if (!subId) {
        return !!value;
      }

      return true;
    }
  ),
  interval: Yup.string()
    .required('Interval is required.'),
  count: Yup.string(),
  amount: Yup.string()
    .required('Amount is required.'),
  startDate: Yup.mixed()
    .test('is-valid-date', 'Start date is required.', function (value) {
      if (!value) {
        return false;
      }
      return true;
    })
    .typeError(TYPE_DATE)
    .nullable()
    .default(null)
});

export const PackageSchema = Yup.object().shape({
  packageName: Yup.string()
    .required('Package name is required.')
    .matches(/^(?!\s+$)/, NAME_NO_WHITESPACE)
    .test('no-arithmetic', 'Package name cannot contain arithmetic symbols', (value) => {
      return value ? !/[-+=<>?`]/.test(value) : true;
    }),
  price: Yup.string()
    .required('Package price is required.'),
  numberOfSession: Yup.string()
    .required('Number of Sessions is required.')
    .test('check-number', 'only 200 sessions are allowed.', function (value) {
      const num = Number(value) ?? 0;
      if (num > 200) {
        return false;
      }
      return true;
    }),
  interval: Yup.string()
    .required('Interval is required.')
});

export const RequestSessionSchema = Yup.object().shape({
  firstName: FisrtNameSchema,
  lastName: LastNameSchema,
  email: EmailSchema,
  phone: Yup.array().of(
    Yup.object().shape({
      isoCode: Yup.string(),
      phoneNumber: Yup.string()
        .required(PHONE_NUMBER_REQUIRED)
        .min(MIN_PHONE_LENGTH, PHONE_MIN_LENTH)
    })
  ),
  sessionType: Yup.string()
    .required('Session type is required.'),
  dateTime: Yup.mixed()
    .test('is-valid-date', 'Date is required.', function (value) {
      if (!value) {
        return false;
      }
      return true;
    })
    .test('is-valid-dateTime', 'Please select a upcoming date and time.', function (value: any) {
      if (value) {
        const sessionId = this.parent.sessionId;
        const slectedDate = checkForPastDate(new Date(value), sessionId);
        return !slectedDate;
      }
    })
    .typeError(TYPE_DATE)
    .nullable()
    .default(null),
  note: Yup.string()
    .max(250, 'Only 250 characters are allowed')
});

export const RequestCancelSessionSchema = Yup.object().shape({
  email: EmailSchema,
  phone: Yup.array().of(
    Yup.object().shape({
      isoCode: Yup.string(),
      phoneNumber: Yup.string()
        .required(PHONE_NUMBER_REQUIRED)
        .min(MIN_PHONE_LENGTH, PHONE_MIN_LENTH)
    })
  ),
  note: Yup.string()
    .required('Reason is required.')
    .max(MAX_NOTES_LENGTH, NOTES_MAX_LENGTH)
});

export const OneOfPaymentDialogSchema = Yup.object().shape({
  subscriptionId: Yup.string()
    .required('Subscription is required.'),
  price: Yup.string()
    .required('Amount is required.'),
  chargeDate: Yup.mixed()
    .test('is-valid-date', 'Date is required.', function (value) {
      if (!value) {
        return false;
      }
      return true;
    })
    .typeError(TYPE_DATE)
    .nullable()
    .default(null),
  note: Yup.string()
});
