import { Box, Container, Typography } from '@mui/material';
import React from 'react';
import SvgIconWrapper from '../common/SvgIcon';

const cardData = [
  {
    icon: 'CircleCheck',
    title: 'Real Time Insights',
    description: 'Accurate & useful performance information '
  },
  {
    icon: 'CircleCheck',
    title: 'Exportable Information',
    description: 'Easily download session and payment history'
  }
];

interface CardProps {
  icon: string
  title: string
  description: string
}
export const Card = (props: CardProps): JSX.Element => {
  const { icon, title, description } = props;
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      gap: { xs: 1, md: 2 },
      p: { xs: 1, md: 2 }
    }}>
      <Box sx={{ mt: { xs: 1, md: 1.5 } }}>
        <SvgIconWrapper
          name={icon}
          size={20}
        />
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: { xs: 1, md: 1 }
      }}>
        <Typography color="black" sx={{ typography: { xs: 'h5', md: 'h4' }, fontFamily: 'Nunito, Sans-Serif !important' }}>
          {title}
        </Typography>
        <Typography color="text.secondary" variant="h5" sx={{ fontWeight: 400, fontFamily: 'Nunito, Sans-Serif !important' }}>
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

const SectionFour = (): JSX.Element => {
  return (
    <Box sx={{
      // minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#F7FBFE',
      py: { xs: 2, md: 4 },
      scrollSnapAlign: 'start'
      // background: 'linear-gradient(#CDE7FC, #FFFFFF)',
      // position: 'relative'
    }}>

      <Container sx={{
        // minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Box sx={{
          display: 'flex',
          flexDirection: { xs: 'column-reverse', md: 'row' },
          flexGrow: 1,
          gap: { xs: 3, md: 6, lg: 9 },
          py: { xs: 2, md: 4 }
        }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            flexGrow: 1,
            gap: { xs: 0.5, md: 2 },
            maxWidth: { xs: '100%', md: '50%' }
          }}>
            <Typography sx={{ typography: { xs: 'h2', md: 'h1' }, py: { xs: 2, md: 0, lineHeight: 'unset !important' }, fontFamily: 'Nunito, Sans-Serif !important', textAlign: 'left' }}>
              Data & Analytics
            </Typography>
            <Typography sx={{ typography: { xs: 'h6', md: 'h4' }, fontFamily: 'Nunito, Sans-Serif !important' }} color="text.secondary" >
              Our valuable data and analytics provides business performance insights, allowing you to make informed decisions for growth and success
            </Typography>
            {cardData.map((item) => (
              <Card
                key={item.title}
                icon={item.icon}
                title={item.title}
                description={item.description}
              />
            ))}
          </Box>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            flexGrow: 1,
            gap: 2,
            maxWidth: { xs: '100%', md: '50%' }
          }}>
            <Box component="img" src="/images/image-04.png" sx={{ maxWidth: { xs: 300, sm: 450, md: '100%' } }} />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default SectionFour;
