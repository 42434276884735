import { Box, Container, Typography } from '@mui/material';
import React from 'react';
import SvgIconWrapper from '../common/SvgIcon';

const cardData = [
  {
    title: 'Add, update, and edit your client list'
  },
  {
    title: 'Edit & view current subscriptions'
  },
  {
    title: 'Record notes about each clients'
  },
  {
    title: 'View all scheduled sessions with each client'
  }
];

interface CardProps {
  title: string
}
export const Card = (props: CardProps): JSX.Element => {
  const { title } = props;
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      gap: { xs: 1 },
      alignItems: 'center'
    }}>
      <Box sx={{ mt: { xs: 1, md: 1.5 } }}>
        <SvgIconWrapper
          name="CircleCheck"
          size={20}
        />
      </Box>
      <Typography color="text.secondary" variant="h5" sx={{ fontWeight: 400, fontFamily: 'Nunito, Sans-Serif !important' }}>
        {title}
      </Typography>
    </Box>
  );
};

const SectionFive = (): JSX.Element => {
  return (
    <Box sx={{
      // minHeight: '100vh',
      py: { xs: 2, md: 4 },
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#F7FBFE',
      // background: 'linear-gradient(#CDE7FC, #FFFFFF)',
      position: 'relative',
      scrollSnapAlign: 'start'
    }}>
      {/* <Box sx={{
        position: 'absolute',
        bgcolor: 'white.main',
        borderRadius: '50%',
        width: '100%',
        height: 60,
        top: -30
      }}
      /> */}
      <Container sx={{
        // minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Box sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          flexGrow: 1,
          gap: { xs: 3, md: 6, lg: 10 },
          py: { xs: 2, md: 4 }
        }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            flexGrow: 1,
            gap: 2,
            maxWidth: { xs: '100%', md: '50%' }
          }}>
            <Box component="img" src="/images/image-05.png" sx={{ maxWidth: { xs: 300, sm: 450, md: '100%' } }} />
          </Box>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            flexGrow: 1,
            gap: { xs: 0.5, md: 2 },
            maxWidth: { xs: '100%', md: '50%' }
          }}>
            <Typography sx={{ typography: { xs: 'h2', md: 'h1' }, py: { xs: 2, md: 0, lineHeight: 'unset !important' }, fontFamily: 'Nunito, Sans-Serif !important', textAlign: 'left' }}>
              Easily Manage Clients
            </Typography>
            <Typography sx={{ typography: { xs: 'h6', md: 'h4' }, fontFamily: 'Nunito, Sans-Serif !important' }} color="text.secondary" >
              Our distinct system enables trainers to keep their client list current. Freelancers have the ability to include, modify, and delete clients, while also conveniently assessing the payment arrangement for each individual client.            </Typography>
            <Box>
              {cardData.map((item, id) => (
                <Card
                  key={`item-${'id'}`}
                  title={item.title}
                />
              ))}
            </Box>
          </Box>
        </Box>
      </Container>
      <Box sx={{
        position: 'absolute',
        bgcolor: '#F7FBFE',
        borderRadius: '50%',
        width: '100%',
        height: 60,
        bottom: -30
      }}
      />
    </Box>
  );
};

export default SectionFive;
