import { SwipeableDrawer, type SwipeableDrawerProps } from '@mui/material';
import React from 'react';

interface DrawerProps extends SwipeableDrawerProps {
  children: React.ReactNode
}

const Drawer: React.FC<DrawerProps> = (props): JSX.Element => {
  const {
    open,
    anchor,
    onOpen,
    onClose,
    children
  } = props;
  return (
    <SwipeableDrawer
      open={open}
      anchor={anchor}
      onClose={onClose}
      onOpen={onOpen}
    >
      {children}
    </SwipeableDrawer>
  );
};

export default Drawer;
